import { makeStyles } from "@material-ui/styles";

import chat_image from './img/modal.jpg';
const drawerWidth = 600;

export default makeStyles(theme => ({
    listItem: {
        // border: "1px solid blue",
        display: "flex",
        justifyContent: 'end',
        width: "30%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
        // "&:hover ~ $listItemSecondaryAction": {
        //     "&:hover $listItemSecondaryAction": {
        //         border: "1px solid green",
        //         visibility: "inherit"
        //     }
        // }
    },
    table: {
        minWidth: 650,
    },
   
    headBG: {
        backgroundColor: '#e0e0e0'
    },
    borderRight500: {
        borderRight: '1px solid #e0e0e0'
    },
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: drawerWidth,
    },



    chatLeft: {
        border: "1px solid #eee",
        borderRadius: "20px",
        padding: "20px",
    },


    hide: {
        display: 'none',
    },
    drawer: { 
        width: "1300px",
        maxWidth: "100%",
        margin: "0 auto",
        [theme.breakpoints.down("sm")]: {
            left: "5%",
            right: "5%", 
            width: "90%",
        }
    },
    drawerPaper: {
        width: drawerWidth,
        [theme.breakpoints.down("sm")]: {
          
            width: '75%',
        }
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        minHeight: "40px",
        justifyContent: 'flex-start',
        background: '#113358',
        color: "#fff",
        "& svg": {
            color: "#fff",
        }
    },
    drawerClose: {
        display: 'flex',
        justifyContent: 'end',
        gap: "10px"
      },
    profiledrawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    searchdrawerPaper: {
        width: '50%',
        [theme.breakpoints.down("md")]: {
            width: '75%',
        },
    },

    profiledrawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-start',
        background: '#f0f2f5',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginRight: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),

        "& > div": {
            border: "1px solid #eee",
            marginLeft: "25px",
            borderRadius: "20px",
        }
    },

    input: {
        display: 'none',
    },

 
    download: {
        padding: "3px",
        stroke: "#949696",
        strokeWidth: "1px",
        color: "#949696",
        border: "2px solid #949696",
        borderRadius: "50px",
        fontWeight: "bold"
    },

    userName: {
     textAlign: "left",
     textTransform: "capitalize",
     fontWeight: "bold",
     fontSize: "14px",
    },

    contactName: {
        textAlign: "left"
    },

    contactNumber: {
        textTransform: "capitalize",
        fontWeight: "bold"
    },

    time: { 
        textAlign: "end",
        whiteSpace: "nowrap",
        "& p":{
          fontSize: "11px"
        }
    },

  
    descriptionIcon: {
        color: "blue"
    },

    chatListBackGround: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        backgroundImage: 'url(' + chat_image + ')',
    },

    sendIcon: {
        fontSize: "18px",
        margin: "5px"
    },
    tertiary:{
       // backgroundColor:"#064be2",
        background:"#2196f3",
        color:"white",
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#053db7",
      },
    },
    sendIconBtn: {
        margin: "30px 15px",
        color:"white",
       // backgroundColor:"#064be2",
       background:"#2196f3",
       "&:hover":{
        color:"white !important",
        // backgroundColor:"#064be2",
        background:"#2196f3 !important",
       }
    },

    userCaption: {
        textTransform: "capitalize",
        alignItems: "center",
         display: "flex",
          gap: "2px",
    "& svg":{
     width: "17px",
    }
    },

    userCaptionContainer: {
        textAlign: "left"
    },

    paperContainer: {
        padding: '10px'
    },
    searchOutLine: {
        padding: '10px 7px 7px',
        color: "#aaa",
    },
    chatSearchIcon: {
        padding: '10px 7px 7px',
        pointerEvents: "none",
        color: "#aaa",
    },
    listItemIcon: {
        alignItems: 'center',
        cursor: "pointer"
    },
    avatarTitle: {
        paddingLeft: '10px',
        color: "black",
        textTransform: "capitalize",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: '5px',
        },
    },

   
    center: {
        textAlign: "center"
    },

    blueTick: {
        textAlign: "end",
        color: "#aab8c2",
        fontSize: "11px"
    },

    // messageStatus: {
    //     alignItems: "center"
    // },

    messageAreaSingle: {
        height: "100vh",
        overflowY: "scroll",
        backgroundImage: 'url(' + chat_image + ')',
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            overflowX: "hidden"
        },
    },
    chatListMessage: {
        display: 'flex ',
        alignItems: 'center ',
        padding: '15px',
        color: ' white',
        background: '#00800085',
        borderRadius: '50px',
        height: '10px',
    },
    mainContent: {
        height: '15%',
    },
    leftSide: {
        height: '65vh',
    },
    chatListFullWidth: {
        "& > button": {
            maxWidth: "100%",
            "&:hover": {
                backgroundColor: "#f1f1f1",
            },
        },
    },
    list: {
        borderBottom: "1px solid #eee",
        display: 'flex ',
        padding: '0px 5px ',
    },
    foot: {
        borderTop: "1px solid #eee",
        padding: "10px",
    },
    modalSize: {
            "& div":{
                "& div":{
                    boxShadow: "none !important",
                    background: "none !important",
                }
            }
        
    },
    messageRight: {
        padding: "0 58px 50px 0",
        [theme.breakpoints.down("sm")]: {
            padding: "0 40px 50px 0",

        },

        "&:hover": { 
          "& $expandMoreLeft": { 
                display:"block"
          },  
      },

    },
    messageRightBubble: {
        background: '#fff4f6',
        fontSize: '14px', 
        padding: '12px 13px',
        borderRadius: '15px 0px 15px 15px',
        color: 'black',
        position: 'relative',
        float: 'right',
        width: '30%',
        lineBreak: "anywhere",
        [theme.breakpoints.down("md")]: {
            padding: "12px 13px",
            float: "right",
            borderRadius: "15px 0px 15px 15px",
            width: "85%"
        },
    },

    replyMessage:{
        
    },
    messageRightBubbles: {
        padding: '12px 13px',
        float: 'right',
        borderRadius: '15px 0px 15px 15px',
        background: '#fff4f6',
        color: 'black',
        width: '50%',
        lineBreak: "anywhere",
        [theme.breakpoints.down("md")]: {
            padding: "12px 13px",
            float: "right",
            borderRadius: "15px 0px 15px 15px",
            width: "90% "
        },
        "& $replyMessage":{
            padding: "10px",
           borderRight: "2px solid blue",
           background: '#fff4f6',
           borderRadius: "10px 1px 1px 10px"
               }
    },
  
    messageRightImage :{
        background: '#d9fdd3',
        fontSize: '14px',
        fontWeight: '600',
        padding: '12px 13px',
        borderRadius: '15px 0px 15px 15px',
        color: 'black',
        position: 'relative',
        float: 'right',
        width: "53%",
        [theme.breakpoints.down("md")]: {
            padding: "12px 13px",
            float: "right",
            borderRadius: "15px 0px 15px 15px",
            background: "#d9fdd3",
            width: "100%",
        },
        "& $replyMessage":{
            padding: "10px",
           borderRight: "2px solid blue",
           background: "#d9fdd3",
           borderRadius: "10px 1px 1px 10px"
               }
    
        }, 
        messageRightFile  :{
            background: '#d9fdd3',
            fontSize: '14px',
            fontWeight: '600',
            padding: '12px 13px',
            borderRadius: '15px 0px 15px 15px',
            color: 'black',
            position: 'relative',
            float: 'right', 
            width: "60%",
            [theme.breakpoints.down("md")]: {
                padding: "12px 13px",
                float: "right",
                borderRadius: "15px 0px 15px 15px",
                background: "#d9fdd3",
                width: "100%",
            }, 
        }, 
    photoLibrary: {
        cursor: "pointer",
        padding: "10px"
    },

    nothing: {
        display: 'none'
    },
    backdrop: {
        zIndex: 1500,
        color: '#fff',
    },
    top: {
        background: "#f0f2f5",
        padding: "7px",
        borderRight: "1px solid #e0e0e0"
    },
    topSvg: {
        color: "#54656f"
    },
    chatList: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        padding: "5px 0px",
        "& $space":{
            padding: "0px 10px",
            gap: "10px"
        }
    },
    ChatListName: {
        justifyContent: "space-between",
        display: "flex",
        width: "100% ",
        // textAlign: "left"
    },
   
    ChatListContent: {
        display: "flex",
        justifyContent: "space-between",
        fontSize: "14px",
        color: "#969b9f",
        textTransform: "initial"
    }, 
    fullWidth: {
        width: "100%"
    },
  
    a: {
        textDecoration: "none",
        cursor: "pointer"
    },
    tablist: {
        padding: "10px",
        display: "flex"
    },
    main: {
        top: "0",
        maxWidth: "1600px",
        position: "relative",
        margin: "0 auto",
        [theme.breakpoints.down("md")]: {
            top: "20px !important",
            width: "calc(100%) !important",
        }
    },
    messagearea: {
        height: "57vh",
        overflowY: "scroll",
        //backgroundImage: 'url(' + chat_image + ')',
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            overflowX: "hidden"
        },
    },
    title: {
        color: "black",
        textTransform: "capitalize "
    },
    modalImage: {
        width: "500px",
         
    },
    chatSearch: {
        "& input": {
            padding: "12px 10px !important",
            fontSize: "16px"
        }
    },
    paper: {
        display: "flex ",
        width: "100%",
        background: "#f0f2f5 ",
        boxShadow: "none ",
        borderRadius: "8px",
        // border: "2px solid #f0f2f5"
    },
    message: {
        width: "50%",
        padding: "0 0 50px 58px",
        clear: "both",
        marginBottom: "45px",
        "&:after": {
            background: "#00a884",
        },
        [theme.breakpoints.down("md")]: {
            width: "100%",
        }, 
        "&:hover": { 
            "& $expandMoreRight": { 
                  display:"block"
            },  
        },

    },
    chatListAvatar: {
        marginRight: "10px",
        width: "50px !important",
        height: "50px !important"
    },

     
    messageAvatar: {
        float: "left",
        marginLeft: "-38px",
        borderRadius: "50%",
        width: "30px",
        marginTop: "0px",
        height: "30px",
        color: "#ccc"
    },
    messageRightAvatar: {
        float: "right",
        marginLeft: "0",
        marginRight: "-38px",
        height: "30px",
        color: "#999"
    },
    
    expandMoreLeft: { 
       
        position: "absolute",
        right: 65,
        padding: "10px 10px 0px 10px",
        //background: "linear-gradient(45deg, #d9fdd3, #d9fdd3)",
        zIndex: 1,
        cursor: "pointer",
        display: "none",

       
       
    },
    expandMoreRight: {  
        position: "absolute",
        right: 10,
      //  padding: "10px 10px 0px 10px", 
      //background: "linear-gradient(45deg, #ffff, #ffff)",
        zIndex: 1,
        cursor: "pointer",
        display: "none"
    },

    // messageRightChecbox: {
    //     float: "right",
    //     height: "30px",
    //     marginRight: "-45px",
      
    // },
    messageRightSend: {
        float: "right",
        marginTop: "200px",
        height: "30px",
        color: "white"
    },
  

    bubbleSpan: { 
        color: "#aab8c2", 
        fontSize: "11px", 
        marginTop: "10px"
     },

    "::-webkit-scrollbar": {
         width: "6px"
         },
    "::-webkit-scrollbar-thumb": { 
        backgroundColor: "#bab4b4"
     },
    contactprofile: {
        display: "flex",
        justifyContent: "center"
    },
    contactprofileImg: {
        height: "200px",
        width: "200px",
        cursor: "pointer",
        borderRadius: "200px"
    },
    contactDetails: {
        display: "contents",
        textAlign: "center",
        paddingBottom: "10px",
        "& $chip":{
            display: "flex", 
            width: "100%",   
            gap: "5px",
            padding: "10px 50px", 
            textAlign: "center",
           justifyContent: "center",
            [theme.breakpoints.down("sm")]: {
                display:  "flex !important",
                flexWrap: "wrap",
                 padding: 10,

              }, 
        }
    },
    editProfile: {
        display: "flex",
        justifyContent: "center"
    },
    editProfileImg: {
        height: "200px",
        width: "200px",
        cursor: "pointer",
        borderRadius: "200px"
    },
    Editdetails: {
        marginBottom: "25px ",
        backgroundColor: "white",
        color: "black",
        fontWeight: 400,
        fontSize: "21px",
        paddingLeft: "20px "
    },
    body: { background: "#f0f2f5" },
   
    login: {
        padding: "10% ",
        textAlign: "-webkit-center",
        placeSelf: "center"
    },
    sendHere: {
        [theme.breakpoints.down("md")]: {
            fontSize: "21px", padding: "4px"
        }
    },
    space: {
        display: "flex",
    //   whiteSpace: "nowrap",
     //  gap: "10px",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between", 
       
    },
        button:{

            minWidth: "10px !important",
            boxShadow: "none !important"
        },
    borderNone: {
        border: "none",
    },
    blue: {
        margin: "4px 0px 0px 4px",
        backgroundColor: "white",
        color: "#00a884",
        fontSize: "14px",

    },
    blueIcon: {
        // backgroundColor: "white",
        color: "#00a884",
        fontSize: "14px",
        // "&:hover": {
        //   backgroundColor: "#00a884",
        //   color: "white",
        // }
    },
    lgButton:{
        display: "none !important",
        [theme.breakpoints.up("sm")]: {
          display: "block !important",
        },
       
      },
      smButton:{ 
        display: "block !important",
        [theme.breakpoints.up("sm")]: {
          display: "none !important",
        }, 
        
      },
      customTextField: {
        "& input:placeholder": {
          fontSize: "13px"
        },
        marginTop: "5px !important",
        border: "1px solid #edecec",
      },


      messagebubble: {
        background: "#f5f5f5",
        fontSize: "14px",
        fontWeight: 400,
        padding: "12px 13px",
        borderRadius: "0px 15px 15px 15px",
        color: "black",
        position: "relative",
        float: "left",
        width: "70%", //53%
        lineBreak: "anywhere",
        [theme.breakpoints.down("md")]: { 
            width: "100% "
        },

        "& $replyMessage":{
     padding: "10px",
    borderRight: "2px solid blue",
    background: "#d9fdd3",
    borderRadius: "10px 1px 1px 10px"
        }
    },
    messagebubbles: {
        background: "white",
        fontSize: "14px",
        fontWeight: 400,
        padding: "12px 13px",
        borderRadius: "0px 15px 15px 15px",
        color: "black",
        position: "relative",
        float: "left",
        width: "100%",
        lineBreak: "anywhere",
        "& $replyMessage":{
            padding: "10px",
           borderRight: "2px solid blue",
           background: "#f5f5f5",
           borderRadius: "10px 1px 1px 10px"
               }
    },
   gap10:{
    display:"flex",
    gap:"10px"
   },

   tagdrawerPaper: {
    width: '30%', 
    [theme.breakpoints.down("md")]: { 
        width: '50% !important', 
      }, 
},

   drawerContent:{
    padding: "10px"
   },
   drawerFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "30%",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
    [theme.breakpoints.down("md")]: { 
        width: '50% !important', 
      }, 
   
    [theme.breakpoints.only("xs")]: {
      
      position: "fixed  !important",
    }, 
  },

  drawerSearchFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "50%",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
    [theme.breakpoints.only("sm")]: {
        width: "60% !important",
      },
      [theme.breakpoints.only("xs")]: {
       
        width: "80% !important",
        position: "fixed  !important",
      }, 
  },

 
  auto:{
    "& fieldset":{
        border: "none !important",
    }
  },
  listGridItem:{
    display: "flex",
    gap: "10px",
    alignItems: "center",
    width: "70%",
    
      [theme.breakpoints.only("xs")]: { 
        gap: "0px",
        width: "100%",
      }, 
       
  },
  chip:{
    display: "flex", 
  //  width: "100%",  
    overflow: "scroll",
    gap: "5px",
    padding: "10px 0px", 
    [theme.breakpoints.only("sm")]: {
        display:  "none !important",
      },
      [theme.breakpoints.only("xs")]: { 
        display:  "none !important",
         
      }, 
  },

failedMessage:{ 
    marginTop: "6px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center"
},

 
dropdown:{
    outline: 0,
    position: "absolute", 
    overflowX: "hidden",
    overflowY: "auto", 
    right: "2%",
    top:"80%",
    minWidth: "10rem", 
    zIndex:"999",
    backgroundColor:"white",
    boxShadow: "0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)", 
    [theme.breakpoints.down("sm")]: {
      minWidth: "9rem", 
      position:"fixed",
      top:'unset',
      right:'20px'
    },
  },
  profileMenuIcon: {
    cursor: "pointer",
    margin: "15px 0px",
    color: theme.palette.text.primary,
    paddingLeft: 10,
    paddingRight: 5,
    "&:hover": { 
        backgroundColor: "#f1f1f1",
    }
  },

}))
