import React, { useState, useEffect } from "react";
import { Grid, Typography, Backdrop, CircularProgress,  Avatar,  Button } from "@material-ui/core";
import { withRouter, Link } from "react-router-dom";
 
import useStyles from "./styles"; 
 import { ToastContainer, toast } from 'react-toastify';
  import CloseIcon from '@material-ui/icons/Close'; 
import { verifyEmail } from '../../services/user.api';
 import Notification from "../../components/Notification/Notification";
//import useGlobalStyles from "../../themes/style";
import { FcBusinessman } from "react-icons/fc";
import logo from '../../images/logo.png';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';


import VerifyImg from '../../images/VerifyImg.png';

function Forget(props) { 
  var classes = useStyles();
   const [loader, setLoader] = useState(false);  
   const [view, setView] = useState("");  

  // var globalClasses= useGlobalStyles(); 
  
    
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

  useEffect(() => {
 
    setLoader(true);
     
    verifyEmail({token: props.match.params.token}).then((response) => {    
     
        
         if (response.data.status === true) { 
            handleNotificationCall("success", response.data.message);  
            setView("success");

         } else{ 
            handleNotificationCall("error", response.data.message);    
            setView("error");
           
         }

         setLoader(false);
         
       }) 
       .catch(function (error) {
        console.log(error);
      })    
     // eslint-disable-next-line react-hooks/exhaustive-deps
   }, []);

  
 

  return (
    <Grid container className={classes.container}>
     
        <div className={classes.loginInner}>

            <div className={classes.loginLeft}>
              <img src={VerifyImg} />
            </div>


            <div className={classes.loginRight}>

                <div className={classes.loginForm}>


                    <div className={classes.loginHead}>
                        <img src={logo} alt="kadigaa"   />

                        <br />

                        <Link to="/home">Back to Home</Link>
                        <br />
                        <br />
                    </div>
        
                    <div className={classes.verifyMessage}>

                      <React.Fragment>

                          <Typography variant="h5"> Thank You for Registering with Kadigaa. </Typography> 


                          { view === "success"? 
                          <> 
                              <Typography  className={classes.loginText} > 
                                <CheckCircleIcon  style={{ fontSize: 45, color:"green", verticalAlign: "middle" }} />
                                {"Verified"}  
                                </Typography> 
                          </>
                          :
                          <>  
                              <Typography  className={classes.loginText} >
                                <CancelIcon  style={{ fontSize: 45, color:"red", verticalAlign: "middle" }} />
                                {"Not Verified"}   
                              </Typography> 
                          </>
                          }
                          

                          <div className={classes.formButtons}>

                              <Button className={classes.submitButton}
                                  variant="contained"
                                  color="primary"
                                  component={Link}
                                  to="/login"
                                >
                                  Back to Login
                              </Button>
                          </div>    

                      </React.Fragment>

                    </div>  
        
                </div>
        
            </div>


        </div>     

  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Forget);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 