import React, { useState, useEffect } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import PropTypes from "prop-types";
import Authentication from "./Authentication";
import Template from "./Template";

import Settings from "./Settings";

import Reply from "./Reply";

import ApiData from "./ApiData";

import { viewAuthentication } from "../../services/instance.api";
import CloseIcon from "@material-ui/icons/Close";

import { FcPrivacy, FcNews, FcSettings } from "react-icons/fc";

import PageTitle from "../../components/PageTitle";

import useStyles from "./style";
import globalStyles from "../../themes/style";
import {
  Button,
  Grid,
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";

function TabPanel(props) {
  const globalClasses = globalStyles();
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3} className={index === 3 ? globalClasses.tabpanel3 : ""}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}

// const StyledBadge = withStyles((theme) => ({
// badge: {
// backgroundColor: '#44b700',
// color: '#44b700',
// boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
// '&::after': {
// position: 'absolute',
// top: 0,
// left: 0,
// width: '100%',
// height: '100%',
// borderRadius: '50%',
// animation: '$ripple 1.2s infinite ease-in-out',
// border: '1px solid currentColor',
// content: '""',
// },
// },
// '@keyframes ripple': {
// '0%': {
// transform: 'scale(.8)',
// opacity: 1,
// },
// '100%': {
// transform: 'scale(2.4)',
// opacity: 0,
// },
// },
// }))(Badge);

export default function Home() {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const [loader] = useState(false);

  var moment = require("moment-timezone");
  const [reset, setReset] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  const [instanceId, setInstanceId] = React.useState();

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    if (user?.role_code !== "SUPERADMIN") {
      const changeInstanceId = () => {
        getInstanceId(user?.userId);
      };

      getInstanceId(user?.userId);
      window.addEventListener("storage", changeInstanceId);
      return () => {
        window.removeEventListener("storage", changeInstanceId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInstanceId(id) {
    viewAuthentication({ userId: id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));

          if (parseInt(response?.data?.data?.istrash) === 1) {
            setReset(true);
          }
        } else {
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>
      <PageTitle title="Manage Settings" />

      <Grid container className={globalClasses.pageHeader}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={globalClasses.pageHeaderLeft}
        >
          <Typography variant="h3">Settings</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={globalClasses.pageHeaderRight}
        ></Grid>
      </Grid>

      <div className={globalClasses.contentInner}>
        <AppBar position="static" className={globalClasses.marginTop15}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            className={classes.tabList}
            TabIndicatorProps={{
              style: {
                display: "none",
              },
            }}
          >
            <Tab
              label="Authentication"
              icon={<FcPrivacy style={{ fontSize: 30 }} />}
              iconPosition="start"
              {...a11yProps(0)}
              disabled={
                moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >
                moment(instanceId?.validity_end_date)
                  .tz(moment.tz.guess())
                  .format("YYYY-MM-DD")
                  ? true
                  : false
              }
            />
            {reset === false && parseInt(instanceId?.is_valid) === 1 && (
              <Tab
                label="Template"
                icon={<FcNews style={{ fontSize: 30 }} />}
                {...a11yProps(1)}
                disabled={
                  moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >
                  moment(instanceId?.validity_end_date)
                    .tz(moment.tz.guess())
                    .format("YYYY-MM-DD")
                    ? true
                    : false
                }
              />
            )}
            {reset === false && parseInt(instanceId?.is_valid) === 1 && (
              <Tab
                label="Reply Message"
                icon={<FcSettings style={{ fontSize: 30 }} />}
                {...a11yProps(2)}
                disabled={
                  moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >
                  moment(instanceId?.validity_end_date)
                    .tz(moment.tz.guess())
                    .format("YYYY-MM-DD")
                    ? true
                    : false
                }
              />
            )}

            <Tab label="Api Docs" {...a11yProps(3)} />

            {/* {reset === false && parseInt(instanceId?.is_valid) === 1 &&   <Tab label="Send Message" icon={<FcVoicePresentation style={{ fontSize: 30}} />} {...a11yProps(0)}  disabled={moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")? true: false} />}
          {reset === false && parseInt(instanceId?.is_valid) === 1 &&   <Tab label="Message" icon={<WhatsAppIcon style={{ color: "green"}} />} {...a11yProps(1)} disabled={moment().tz(moment.tz.guess()).format("YYYY-MM-DD") >  moment(instanceId?.validity_end_date).tz(moment.tz.guess()).format("YYYY-MM-DD")? true: false} />}
          */}
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Authentication
            setInstanceId={setInstanceId}
            instanceId={instanceId}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Template instanceId={instanceId} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Settings instanceId={instanceId} setInstanceId={setInstanceId} />
          <br />
          <Reply />
        </TabPanel>

        <TabPanel value={value} index={3}>
          <ApiData />
        </TabPanel>

        {/* 
      <TabPanel value={value} index={0}>
     
        <SendMessage instanceId={instanceId} />
       
      </TabPanel>

      <TabPanel value={value} index={1} className={globalClasses.padding10}>
        <Message setValue={setValue}   />
      </TabPanel>

    */}
      </div>

      <Grid
        container
        direction="row"
        spacing={2}
        className={classes.drawerFooter}
      ></Grid>

      <div id={loader === true ? "spinner" : ""}></div>
      <div className={loader === true ? "main" : ""}></div>
    </>
  );
}
