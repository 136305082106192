import { makeStyles } from "@material-ui/styles";


export default makeStyles(theme => ({

  siteContainer: {
    width: "1300px",
    margin: "0 auto",
    position: "relative",
    maxWidth: '100%',

    [theme.breakpoints.down("xs")]: {
      padding: "0 10px",
    },

  },





  ChatBotMainInner: {
    display: 'flex',
    padding: '60px 0',
  },
  ChatBotMainLeft: {
    width: '50%',
    paddingRight: '10%',
    "& h1": {
      fontSize: '52px',
      fontWeight: '700',
      lineHeight: '1.2',
    },
    "& p": {
      color: '#7e6c6c',
      fontSize: '20px',
      lineHeight: '1.6',
      padding: '30px 0',
    },
    "& .ChatBotMainRegis": {
      padding: '14px 25px',
      display: 'inline-block',
      backgroundColor: '#ed1a3b',
      color: '#fff',
      fontWeight: '500',
      borderRadius: '3px',
      fontSize: '18px',
    },
    "& .ChatBotMainLogin": {
      padding: '14px 25px',
      display: 'inline-block',
      color: '#ed1a3b',
      fontWeight: '500',
      margin: '0 10px',
      fontSize: '18px',
      "& svg": {
        fontSize: '38px',
        verticalAlign: 'middle',
      },
    },
  },
  ChatBotMainRight: {
    width: '50%',
    textAlign: 'center',
    position: 'relative',
    "& img": {
      borderRadius: '16px',
      boxShadow: '0 5px 10px 0 rgba(0,0,0,0.3)',
    },
  },

  ChatBotMainRightFirst: {
    position: 'absolute',
    top: '30px',
    backgroundColor: '#ed1a3b',
    color: '#fff',
    minWidth: '112px',
    textAlign: 'left',
    padding: '10px 15px',
    borderRadius: '10px',
  },
  ChatBotMainRightSecond: {
    position: 'absolute',
    top: '115px',
    backgroundColor: '#ed1a3b',
    color: '#fff',
    minWidth: '112px',
    textAlign: 'left',
    padding: '10px 15px',
    borderRadius: '10px',
  },


  ChatBotInfoHead: {
    "& h1": {
      fontSize: '38px',
      textAlign: 'center',
      padding: '20px 0',
      fontWeight: '700',
    },
  },
  ChatBotInfoList: {
    display: 'flex',
    padding: '30px 0 80px',
  },
  ChatBotInfoListLeft: {
    width: '50%',
    "& img": {
      borderRadius: '20px',
    },
  },
  ChatBotInfoListRight: {
    width: '50%',
    padding: '5% 8% 0',
    "& h1": {
      fontWeight: '700',
      fontSize: '34px',
      lineHeight: '1.4',
      padding: '0 0 20px',
    },
    "& p": {
      color: '#5c4e4e',
      fontSize: '20px',
    },
  },
  


  ChatBotQuickInner: {
    display: 'flex',
    backgroundColor: '#364058',
    borderRadius: '15px',
    padding: '8%',
  },
  ChatBotQuickLeft: {
    width: '60%',
    padding: '0 20% 0 0',
    "& h1": {
      fontSize: '38px',
      color: '#fff',
    },
    "& p": {
      color: '#c9c2c3',
      fontSize: '18px',
      lineHeight: '1.7',
      padding: '20px 0 0',
    },
  },
  ChatBotQuickRight: {
    width: '40%',
    textAlign: 'right',
    "& a": {
      padding: '14px 25px',
      display: 'inline-block',
      fontWeight: '600',
      color: '#fff',
      borderRadius: '10px',
      fontSize: '18px',
      minWidth: '208px',
      border: '2px solid #ed1a3b',
      textAlign: 'center',
      margin: '10px 0',
    },
    "& .ChatBotQuickRegis": {
      backgroundColor: '#ed1a3b',
    },
    "& .ChatBotQuickLogin": {
      padding: '10px 25px',
      borderColor: '#fff',
      "& svg": {
        fontSize: '34px',
        verticalAlign: 'middle',
        height: '34px',
      },
    },
  },





  ChatBotPricing: {
    padding: '30px 0 70px',
    background: 'radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(251,243,244,1) 50%, rgba(250,233,235,1) 100%)',
    margin: '0 0 60px',
  },
  ChatBotPricingHead: {
    padding: '20px 0',
    fontSize: '38px',
    textAlign: 'center',
    fontWeight: '700',
  },
  ChatBotPricingList: {
    padding: '20px',
    borderRadius: '15px',
    backgroundColor: '#ffffff',
    margin: '40px 0 0',
    border: '2px solid #ffffff',
    "&:hover": {
      border: '2px solid #ed1a3b',
    },
  },
  ChatBotPricingTitle: {
    display: 'inline-block',
    padding: '4px 35px 4px 7px',
    borderRadius: '5px',
    margin: '0 0 16px',
    fontSize: '20px',
    backgroundColor: '#ed1a3b',
    color: '#fff',
  },
  ChatBotPricingLink: {
    color: '#ed1a3b',
    minWidth: '200px',
    display: 'inline-block',
    textAlign: 'center',
    fontWeight: '500',
    padding: '10px 0',
    borderRadius: '10px',
    fontSize: '18px',
    margin: '16px 0',
    border: '2px solid #ed1a3b',
    "&:hover": {
      backgroundColor: '#ed1a3b',
      color: '#ffffff',
    }
  },
  ChatBotPricingCheckBox: {
    fontSize: '18px',
    color: '#ffffff',
    backgroundColor: '#959595',
    borderRadius: '15px',
    verticalAlign: 'middle',
    padding: '2px',
    margin: '0 8px 0 0',
  },









  siteHeader: {
    backgroundColor: "white  !important",
    width: "100%", 
   // width: `calc(100% - ${theme.spacing(7) + 14}px)`, 
    boxShadow: "0px 5px 10px 0px #2f3c4a14 !important",
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down("sm")]: {
     
      width: "100%",
      
    },
    "& $MuiAppBar-positionFixed":{
      left: `${theme.spacing(7) + 14}px !important`,
    }
  },
  toolBar: {
    justifyContent: "space-between",
    padding: "15px 0",
  },
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
 logo: {
    flexGrow: "1", 
    cursor: "pointer",
  },
  link: {
    textDecoration: "none",
    color: "#000",
    padding: "10px 25px",
    marginLeft: theme.spacing(2),
    borderRadius: "8px",
    fontWeight: "500",
    fontSize: "16px",
    "&:hover": {
      borderBottom: "1px solid white",
    },
    
  },

  loginLink: { 
    color: "#ed1a3b",
  },

  registerLink: {
    backgroundColor: "#ed1a3b",
    color: "#fff",
  },


  otherLinks: {
    "& a": {
      color: "#000000",
      fontSize: "16px",
      padding: "5px 12px",
      borderRadius: "4px",
      margin: "0 6px",
    },
    "& a:hover": {
      color: "#ed1a3b",
    },
    "& .is-active": {
      color: "#fff !important",
      backgroundColor: "#ed1a3b",
    }
  },




  contentPage: {
    minHeight: "450px",
    "& p": {
      padding: "10px 0 0",
    }
  },
  



  homeBanner: {
    backgroundColor: "#f3dfd1",
    "& .homeBannerInner": {
      display: "flex",
      alignItems: "center",
      padding: "30px 0",
    },
    "& .homeBannerLeft": {
      width: "48%",
      "& h1": {
        fontSize: "32px",
        lineHeight: "1.5",
      },
      "& p": {
        fontSize: "16px",
        padding: "20px 0",
        lineHeight: "1.8",
      },
      "& a": {
        color: "#fff",
        padding: "10px 20px",
        marginRight: theme.spacing(2),
        borderRadius: "8px",
        fontWeight: "500",
        fontSize: "15px",
        display: "inline-block",
        backgroundColor: "#ed1a3b",
        border: "1px solid #ed1a3b",
      },
      "& .homeBannerRegi": {
        backgroundColor: "#000",
        color: "#ffffff",
        borderColor: "#000",
      },
    },
    "& .homeBannerRight": {
      width: "52%",
      textAlign: "center",
    }
  }, 


  homeInfo: {
    padding: "100px 0",
    "& .homeInfoHead": {
      textAlign: "center",
      width: "650px",
      margin: "0 auto",
      maxWidth: "100%",
      "& h1": {
        fontSize: "28px",
      },
      "& p": {
        fontSize: "18px",
        padding: "15px 0",
      }
    },
    "& .homeInfoInner": {
      display: "flex",
      flexWrap: "wrap",
      gap: "4.5%",
      padding: "50px 0",
      "& .homeInfoList": {
        width: "100%",
        display: "flex",
        padding: "30px 0",
        "& .homeInfoListLeft": {
          width: "40%",
          "& h5": {
            fontSize: "16px",
            padding: "20px 0",
            color: "#ed1a3b",
          },
          "& h3": {
            padding: "20px 0 25px",
            fontSize: "24px",
            lineHeight: "1.4",
          },
          "& p": {
            fontSize: "16px",
            lineHeight: "1.8",
          }
        },
        "& .homeInfoListRight": {
          width: "60%",
          textAlign: "center"
        },
      }
    }
  },



  homePricing: {
    backgroundColor: "#0f172a",
    color: "#fff",
    padding: "120px 0",
    "& .homePricingHead": {
      textAlign: "center",
      width: "600px",
      margin: "0 auto",
      maxWidth: "100%",
      "& h1": {
        fontSize: "28px",
      },
      "& p": {
        color: "#fff",
        padding: "18px 0",
        fontSize: "18px",
      }
    },

    "& .homePricingInner": {
      display: "flex",
      justifyContent: "center",
      gap: "30px",
      padding: "50px 0",
      "& .homePricingList": {
        width: "380px",
        maxWidth: "100%",
        "& .homePricingListInner": {
          backgroundColor: "#2563eb",
          borderRadius: "25px",
          padding: "30px",
        },
          "&.first .homePricingListInner": {
            backgroundColor: "#364058",
            color: "#fff"
          },

        "& .homePricingListHead": {
          "& h2": {
            fontWeight: "600",
            fontSize: "32px",
          },
          "& h5": {
            padding: "15px 0 25px 0",
          },
          "& p": {
            color: "#fff",
          },
          "& a ": {
            backgroundColor: "#fff",
            color: "#000",
            display: "block",
            borderRadius: "50px",
            textAlign: "center",
            padding: "10px 0",
            fontWeight: "500",
            margin: "30px 0",
          }
        },
        "& ul": {
          listStyle: "none",
          margin: "0",
          padding: "0",
          "& li": {
            padding: "14px 0",
            fontSize: "15px",
           "& svg": {
              fontSize: "20px",
              verticalAlign: "middle",
              margin: "0 7px 0 0",
            }
          },
        }
      }
    },
  },



  homePricingPage: {
    backgroundColor: "transparent",
    padding: "0px 0px",
    "& .homePricingHead ": {
      "& h1": {
        color: "#000",
      },
      "& p": {
        color: "#000",
      },
    }
  },

  


  homePricingInstance: {
    color: "#000",
    "& .homePricingInner": {
      display: "flex",
      gap: "20px", 
    },
    "& .homePricingListHead": {
      "& h2": {
        fontSize: "32px",
      },
      "& h5": {
        padding: "20px 0",
        color: "#ed1a3b",
      },
    },
    "& .homePricingListInner": {
      "& ul": {
        color: "#777",
        listStyle: "none",
        padding: "0px",
        "& li": {
          padding: "0 0 19px 20px",
          position: "relative",
          fontSize: "15px",
          "& svg": {
            position: "absolute",
            left: "0",
            top: "4px",
          }
        }
      }
    }
  },





  footerLinks: {
    textAlign: "center",
    margin: "0 0 50px", 
    "& a": {
      margin: "0 10px",
      padding: "10px 5px",
      display: "inline-block",
      color: "#7e7e7e",
    },
    "& a:hover": {
      color: "#ff7a8f",
    },
    "& .is-active": {
      color: "#ed1a3b",
    }
  },





  contactUs: {
    display: "flex",
    width: "1100px",
    maxWidth: "100%",
    margin: "0 auto",
    alignItems: "center",
    "& .contactUsLeft": {
      width: "60%",
      textAlign: "center",
    },
    "& .contactUsRight": {
      width: "40%",
      "& h1": {
        fontSize: "52px",
        fontWeight: "700",
      },
      "& h2": {
        fontSize: "38px",
        fontWeight: "300",
      },
      "& .contactUsLink": {
        margin: "30px 0",
        "& a": {
          color: "#000",
          fontSize: "24px",
        },
        "& svg": {
          backgroundColor: "#ed1a3b",
          color: "#fff",
          borderRadius: "10px",
          fontSize: "46px",
          padding: "5px",
          verticalAlign: "middle",
        }
      }
    },
  },




  viewGroupInfo: {
    "& > div": {
      padding: "10px 14px",
    },
    "& > div:nth-child(odd)": {
      backgroundColor: "#f5f5f5",
    }
  },


  dialogTitle: {
    borderBottom: "1px solid #eee"
  },
  dialogCloseBtn: {
      cursor: "pointer",
      position: "absolute",
      top: "9px",
      right: "20px",
      lineHeight: "0",
      borderRadius: "50px",
      padding: "6px",
      "&:hover": {
        backgroundColor: "rgb(237 26 59 / 20%)",
      }
  },




  filter: {
    borderRadius: "20px",
    padding: "10px 25px",
    border: "1px solid #ededed",
    margin: "0 0 20px",

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },

    "& h5": {
      padding: "0 0 20px",
      position: "relative",
      "& span": {
        position: "absolute",
        bottom: "17px",
        left: "0",
        background: "#2196f3",
        width: "35px",
        height: "2px",
        textAlign: "center",
        verticalAlign: "middle",
      },
    },
  },
  filterForm: {
    display: "flex",
    gap: "15px",
    alignItems: "baseline",
  },
  filterTextField: {
    width: "240px !important",
    "& input": {
      padding: "12px 12px",
    },
    "& label": {
      top: "-3px",
    },
  },
  filterTextFieldSelect: {
    "& > div": {
      padding: "11px 12px"
    }
  },
  filterButtons: {
    display: "flex",
    gap: "15px",
  },



  noBorder: {
    border: "none !important",
  },




  contentInner: {
    backgroundColor: "#ffffff",
    borderRadius: "30px",
    padding: "20px",
    minHeight:"70vh",

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },

  },

  contentInnerInner: {
    borderRadius: "20px",
    padding: "25px",
    border: "1px solid #dddddd",

    [theme.breakpoints.down("xs")]: {
      padding: "10px",
    },
    
  },



  pageHeader: {
    color: "#ffffff",
    padding: "30px 0",
    alignItems: "center",

    [theme.breakpoints.down("xs")]: {
      padding: "15px 0",
    },

    "& p": {
      color: "#ffffff"
    },
  },
  pageHeaderList: {
    textAlign: "right",
    "& li": {
      display: "inline-block",
      width: "auto",
      padding: "0 16px",
      verticalAlign: "bottom",
      "& button": {
        color: "#000",
        backgroundColor: "#ffffff !important",
        padding: "2px 8px",
        marginTop: "5px",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#ed1a3b !important",
        },
      },
    },
    "& li:last-child": {
      paddingRight: "0px",
    },
    "& h6": {
      display: "block",
      color: "#ffffff",
      fontSize: "15px",
    }
  },




    lgButton:{
        display: "none !important",
        [theme.breakpoints.up("sm")]: {
          display: "block !important",
        },
       
      },
      smButton:{ 
        display: "block !important",
        [theme.breakpoints.up("sm")]: {
          display: "none !important",
        }, 
      },


      
drawerHeader: {
    width: "50% !important",
    position: "fixed",
    display: "flex",
    padding: "25px 15px 20px",
    marginLeft: "0",
    alignItems: "center",
    borderBottom: "1px solid rgb(0 0 0 / 10%)",
    justifyContent: "space-between",
    top: "0px",
    background: "#113358",
    zIndex:1,
    color: "#fff",
  },
  
  drawerSmHeader: {
    width: "30% !important",
    position: "fixed",
    display: "flex",
    padding: "10px 0px !important",
    marginLeft: "0",
    alignItems: "center",
    borderBottom: "1px solid rgb(0 0 0 / 10%)",
    justifyContent: "space-between",
    top: "0px",
    background: "#fff",
    zIndex:1, 
  },
  drawerContent:{
    marginTop:"58px",  
   // marginBottom: "70px",
    //height: "100vh",
    height: "100%",
    padding: "16px 16px 35px",
  }, 
  
  drawerClose: {
    position: "absolute",
    right:"20px",
    zIndex: "10",
    cursor: "pointer",
    lineHeight: "0",
    borderRadius: "50px",
    padding: "8px",
    "&:hover": {
      backgroundColor: "rgb(255 255 255 / 40%)",
    },
  },

  drawerFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "48% !important",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
  
    [theme.breakpoints.only("sm")]: {
      width: "58% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "79% !important",
      position: "fixed  !important",
    }, 
  },

  
  drawerSmFooter: {
    borderTop: "1px solid rgba(0, 0, 0, 0.1) !important",
    position: "fixed !important",
    bottom: "0 !important",
    width: "28% !important",
    justifyContent: "center !important",
    padding: "10px",
    background: "white",
    zIndex: "120",
    margin: "0px !important",
    gap: "10px",
  
    [theme.breakpoints.only("sm")]: {
      width: "48% !important",
    },
    [theme.breakpoints.only("xs")]: {
     
      width: "59% !important",
      position: "fixed  !important",
    }, 
  },

  
  backdrop: {
    zIndex: 3500,
    color: '#00f',
  },

   drawer: {
    width: "50%",
    padding: "0px",
    [theme.breakpoints.only("xs")]: {
      width: "85%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "60%",
    }
  },

  smallDrawer:{
    width: "30%",
    padding: "10px",
    [theme.breakpoints.only("xs")]: {
      width: "65%",
    },
    [theme.breakpoints.only("sm")]: {
      width: "50%",
    }
  },

  customSelectField:{
    paddingLeft: "10px !important",
    "& input:placeholder": {
      fontSize: "13px"
    },
   // padding: "10px 12px !important",
   // border: "1px solid #ced4da",

   "& input":{
    padding: "10px 12px !important",
   
   },
   "& textarea":{
    padding: "10px 12px !important",
   },
   "& MuiInputBase-formControl":{
    padding: "10px 12px !important",
    border: "1px solid #ced4da",
   }
  },
  customTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
   // padding: "10px 12px !important",
   // border: "1px solid #ced4da",

   "& input":{
    padding: "13px 12px !important",
   
   },
   "& textarea":{
    padding: "13px 12px !important",
   },
   "& MuiInputBase-formControl":{
    padding: "10px 12px !important",
    border: "1px solid #ced4da",
   }
  },

  regCustomTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
   "& input":{
    padding: "15px 12px !important",
    // borderLeft: "1px solid #ced4da !important",
   },
    
  },

  fields: {
    display: "flex", 
    alignItems: "center",
    maxWidth: "33.3333% !important",
    flexBasis: "33.333333% !important",
    padding: "10px"    
  },
 
  gap10:{
    gap: "10px",
    display: "flex",
  },

  displayBreak:{
    "& $gap10":{
     
        [theme.breakpoints.only("xs")]: {
        display: "block",
        }
    }
},

  button: {
    display: "flex", 
    gap: "10px",
    //  padding: "0px 10px",
     
    whiteSpace: "nowrap",
       [theme.breakpoints.down("xs")]: { 
        display: "inline-flex",
        flexDirection:"column"
    },
  },

  alignItemsEnd:{
    alignItems: "self-end"
  
  },
 
  marginTop15: {
    marginTop: "15px",
  },
  toolAlign: {
    display: "flex",
    gap: "3px"
  },

  marginTop:{
    marginTop: "5px",
  },
    
  toolIcon: {
    cursor: "pointer",
    width: "25px",
    height: "25px",
    margin: "0 7px",
    padding: "3px",
    borderRadius: "3px",
  },

  editIcon: {
    backgroundColor: "rgb(76 175 80 / 30%)",
    color: "#4CAF50",
    "&:hover": {
      backgroundColor: "#4CAF50",
      color: "#fff",
    },
  },
  viewIcon: {
    backgroundColor: "rgb(33 150 243 / 30%)",
    color: "#2196F3",
    "&:hover": {
      backgroundColor: "#2196F3",
      color: "#fff",
    },
  },
  addIcon: {
    backgroundColor: "rgb(156 39 176 / 30%)",
    color: "#9C27B0",
    "&:hover": {
      backgroundColor: "#9C27B0",
      color: "#fff",
    },
  },
  sendIcon: {
    backgroundColor: "rgb(255 87 34 / 30%)",
    color: "#ff5722",
    "&:hover": {
      backgroundColor: "#ff5722",
      color: "#fff",
    },
  },

  toolIconDelete: {
    cursor: "pointer",
    width: "25px",
    height: "25px",
    padding: "5px",
    backgroundColor: "#f50057",
    color: "white",
    borderRadius: "100%",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)"
  },


  scrollCard: {
    padding: "40px 10px !important"
  },

 center: {
    display:"flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },


  formContainer:{
    paddingTop: "15px",
    display: "flex",
    gap: "10px",
    margin: "10px"
  },

  displayEnd: {
    display: 'flex',
    justifyContent: 'end',
    gap: "10px", 
  },

 
  customOutlinedTextField: {
    "& input:placeholder": {
      fontSize: "13px"
    },
    marginTop: "5px !important",
      
   "& MuiInputBase-formControl":{
     border: "1px solid #ced4da",
   }
  },

spaceBetween:{
  justifyContent: "space-between",
  display: "flex"
},

tab:{
  cursor: "pointer", padding: "9px 12px 1px",  gap:"10px", margin: "12px 0", borderRadius: "10px",
  "& div":{
    minWidth: "26px !important",
    marginTop: "0px",
    marginBottom: "0px",
  },
  "& span":{
    color: "#000",
    fontSize: "14px",
    fontWeight: "500",
    marginTop: "-5px",
  },
  "& svg":{
    color: "#958b8d",
    fontSize: "25px",
  },

  "&:hover": {
    backgroundColor: "#f1f1f1",
    "& span":{
      color: "#999999",
    },
    "& svg":{
      color: "#999999",
    },
  }

},

p24x8:{
  padding: "24px 8px",
  [theme.breakpoints.down("md")]: { 
    padding: "24px",
},
}, 
 
hide:{
  display: "none"
},
 
block:{
  display:"block"
},
 

active:{
  background: "#f1f1f1",
  "& svg":{
    color: "#ed1a3b",
  },
  "& span":{
    color: "#ed1a3b",
  }
},

 
chip: {
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  '& > *': {
    margin: theme.spacing(0.5),
  },
},

HeaderElements: {
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  gap: '10px',
 },

 Blink:{
  animation: "$blinker 1s linear infinite",
  color:"red",  
 },

 '@keyframes blinker': {
  '50%': {
   opacity: 0,
  }, 
  }, 

  a:{
    textDecoration:"none",
    color:"white"
  },
  pagination: {
    padding: "10px",
    paddingBottom: "0px",
    justifyContent: 'end',
  },

  weightPB10: {
    fontWeight:"bold", 
    paddingBottom: "10px"
  },
 border:{
  borderRadius: "25px !important",
  padding: "0px 10px !important", 
  boxShadow: "0px 2px 13px 5px #2f3c4a14 !important",
  "& input":{
    borderLeft: "1px solid #2f3c4a36 !important", 
  }  
 },
 

 
filterGap: {
  display: "flex",
  justifyContent: "center",
  alignItems: "center", 
  columnGap: "10px",
  rowGap: "10px",
  paddingTop: "10px",
  
},
filterFullWidth:{ 
  width: "240px",
  [theme.breakpoints.only("xs")]: {
    width: "170px", 
  },
  [theme.breakpoints.only("sm")]: {
    width: "150px;", 
  },
  "& input":{
    padding: "10px 12px !important",
   
   }
},

buttons: {
  marginTop: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  gap: "10px",
  whiteSpace: "nowrap",
  [theme.breakpoints.only("xs")]: { 
      width: "100%" // additionals
  }
},
pointerEventsNone:{
  pointerEvents: "none"
},

tabpanel3:{   
  [theme.breakpoints.only("xs")]: { 
  padding: "24px 0px !important",  
}
},

downloadInvoiceButton: {
  color: "#0063cf",
  fontSize: "14.5px",
  "& svg": {
    color: "#ed1a3b",
    fontSize: "24px",
    verticalAlign: "middle",
    margin: "0 0 0 5px",
  },
}, 


hiddenTextField: {
    height: "0px",
    "& > div": {
      height: "0px",
      border: "0px",
    },
    "& input": {
      height: "0px",
      padding: "0px",
    }
},




}));
