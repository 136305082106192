import React from "react";

import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

import useStyles from "../../themes/style.js";

import { RiFacebookCircleFill, RiTwitterFill } from "react-icons/ri";

function HomePage() {
  const classes = useStyles();

  return (
    <>
      <footer>
        <div className={classes.siteContainer}>
          <div className={classes.footerLinks}>
            <NavLink to="/aboutus" activeClassName="is-active">
              About Us
            </NavLink>
            <NavLink to="/pricingdetails" activeClassName="is-active">
              Pricing
            </NavLink>
            <NavLink to="/contactus" activeClassName="is-active">
              Contact Us
            </NavLink>
            <NavLink to="/termsconditions" activeClassName="is-active">
              Terms and Conditions
            </NavLink>
            <NavLink to="/privacypolicy" activeClassName="is-active">
              Privacy Policy
            </NavLink>
            <NavLink to="/cancellationrefundpolicy" activeClassName="is-active">
              Cancellation / Refund policy
            </NavLink>
          </div>

          <div className="footerInner">
            <div className="footerLeft">
              Copyright © 2023 Kadigaa. All rights reserved.
            </div>
            <div className="footerRight">
              <ul>
                <li>
                  <Link to="/">
                    <RiFacebookCircleFill />
                  </Link>
                </li>
                <li>
                  <Link to="/">
                    <RiTwitterFill />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default withRouter(HomePage);
