import React from "react";

import { Typography } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

import HomeBanner from "../images/homeBanner.png";
import HomeInfo1 from "../images/homeInfo1.png";
import HomeInfo2 from "../images/homeInfo2.png";
import HomeInfo3 from "../images/homeInfo3.png";

import useStyles from "../themes/style.js";

import LoginHeader from "../components/Login/LoginHeader.js";
import LoginFooter from "../components/Login/LoginFooter.js";

import { AiOutlineCheckCircle } from "react-icons/ai";

function HomePage() {
  const classes = useStyles();

  return (
    <>
      <LoginHeader />

      <div className={classes.homeBanner}>
        <div className={classes.siteContainer}>
          <div className="homeBannerInner">
            <div className="homeBannerLeft">
              <Typography variant="h1">
                Secure and Reliable WhatsApp Messaging for your business
              </Typography>
              <Typography variant="body1">
                Quickly send WhatsApp messages right from our platform to your
                Customers. Bill details, Promotional videos, Offer Images,
                Links, anything which helps to enhance your business.
              </Typography>

              <Link to="/login" className="homeBannerLogin">
                Welcome Back
              </Link>
              <Link to="/register" className="homeBannerRegi">
                Get started today
              </Link>
            </div>
            <div className="homeBannerRight">
              <img src={HomeBanner} />
            </div>
          </div>
        </div>
      </div>

      <div className={classes.homeInfo}>
        <div className={classes.siteContainer}>
          <div className="homeInfoHead">
            <Typography variant="h1">
              Simplified WhatsApp messaging platform for your business
            </Typography>
            <Typography variant="body1">
              WhatsApp messaging is not complex anymore. All your Customers
              contacts, messages at one place right at your Desktop.
            </Typography>
          </div>

          <div className="homeInfoInner">
            <div className="homeInfoList">
              <div className="homeInfoListLeft">
                <Typography variant="h5">Individual messaging</Typography>
                <Typography variant="h3">
                  Stay in touch with your Customers by WhatsApp Individual
                  messaging.
                </Typography>
                <Typography variant="body1">
                  In today's business market interacting and informing Customer
                  is very important. Send all kinds of content needed for your
                  message to individual Customer with ease.
                </Typography>
              </div>
              <div className="homeInfoListRight">
                <img src={HomeInfo1} />
              </div>
            </div>
            <div className="homeInfoList">
              <div className="homeInfoListLeft">
                <Typography variant="h5">Group WhatsApp messaging</Typography>
                <Typography variant="h3">
                  Never lose any customers. Send your promotional contents in
                  Group messaging
                </Typography>
                <Typography variant="body1">
                  Keeping Customers is another challenge. Send your promotional
                  contents like Videos, Posters to all your Customers in a
                  single click.
                </Typography>
              </div>
              <div className="homeInfoListRight">
                <img src={HomeInfo2} />
              </div>
            </div>
            <div className="homeInfoList">
              <div className="homeInfoListLeft">
                <Typography variant="h5">Contacts</Typography>
                <Typography variant="h3">
                  Organize all of your contacts in one place.
                </Typography>
                <Typography variant="body1">
                  Keep all your Customer contacts saved safely in Kadigaa.
                </Typography>
              </div>
              <div className="homeInfoListRight">
                <img src={HomeInfo3} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.homePricing}>
        <div className={classes.siteContainer}>
          <div className="homePricingHead">
            <Typography variant="h1">Simple pricing, for everyone.</Typography>
            <Typography variant="body1">
              It doesn't matter what size your business is, our software will
              work well for you.
            </Typography>
          </div>

          <div className="homePricingInner">
            <div className="homePricingList first">
              <div className="homePricingListInner">
                <div className="homePricingListHead">
                  <Typography variant="h2">&#8377;299/month</Typography>
                  <Typography variant="h5">Starter</Typography>
                  <Typography variant="body1">
                    Good for anyone who is just getting started.
                  </Typography>
                  <Link to="/register">Get Started</Link>
                </div>

                <ul>
                  <li>
                    <AiOutlineCheckCircle />
                    Send Unlimited WhatsApp messages
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Save Unlimited Contacts
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Stored in shared Server
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Safe and Secure
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Limited Support
                  </li>
                </ul>
              </div>
            </div>

            <div className="homePricingList">
              <div className="homePricingListInner">
                <div className="homePricingListHead">
                  <Typography variant="h2">&#8377;2999 / Year</Typography>
                  <Typography variant="h5">Enterprise</Typography>
                  <Typography variant="body1">
                    Good for anyone who is just getting started.
                  </Typography>
                  <Link to="/register">Get Started</Link>
                </div>

                <ul>
                  <li>
                    <AiOutlineCheckCircle />
                    Send Unlimited WhatsApp messages
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Save Unlimited Contacts
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Stored in shared Server
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Safe and Secure
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Full Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </>
  );
}

export default withRouter(HomePage);
