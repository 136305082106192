import React from "react";

import { withRouter } from "react-router-dom";

import useStyles from "../../themes/style.js";
import { Link } from "react-router-dom";

import { Typography } from "@material-ui/core";

import PageTitle from "../../components/PageTitle/LoginPageTitle.js";
import LoginHeader from "../../components/Login/LoginHeader.js";
import LoginFooter from "../../components/Login/LoginFooter.js";

import { AiOutlineCheckCircle } from "react-icons/ai";

function PricingDetailsPage() {
  const classes = useStyles();

  return (
    <>
      <LoginHeader />

      <div className={classes.siteContainer}>
        <PageTitle title="Pricing" />

        <div className={classes.homePricing + " " + classes.homePricingPage}>
          <div className="homePricingHead">
            <Typography variant="h1">Simple pricing, for everyone.</Typography>
            <Typography variant="body1">
              It doesn't matter what size your business is, our software won't
              work well for you.
            </Typography>
          </div>

          <div className="homePricingInner">
            <div className="homePricingList first">
              <div className="homePricingListInner">
                <div className="homePricingListHead">
                  <Typography variant="h2">&#8377;299/month</Typography>
                  <Typography variant="h5">Starter</Typography>
                  <Typography variant="body1">
                    Good for anyone who is just getting started.
                  </Typography>
                  <Link to="/register">Get Started</Link>
                </div>

                <ul>
                  <li>
                    <AiOutlineCheckCircle />
                    Send Unlimited WhatsApp messages
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Save Unlimited Contacts
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Stored in shared Server
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Safe and Secure
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Limited Support
                  </li>
                </ul>
              </div>
            </div>

            <div className="homePricingList">
              <div className="homePricingListInner">
                <div className="homePricingListHead">
                  <Typography variant="h2">&#8377;2999 one time</Typography>
                  <Typography variant="h5">Enterprise</Typography>
                  <Typography variant="body1">
                    Good for anyone who needs dedicated support and servers.
                  </Typography>
                  <Link to="/register">Get Started</Link>
                </div>

                <ul>
                  <li>
                    <AiOutlineCheckCircle />
                    Send Unlimited WhatsApp messages
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Save Unlimited Contacts
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Stored in dedicated Server
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Safe and Secure
                  </li>
                  <li>
                    <AiOutlineCheckCircle />
                    Full Support
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </>
  );
}

export default withRouter(PricingDetailsPage);
