import React, { useState } from "react";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import {
  Theme,
  Collapse,
  IconButton,
  Typography,
  Box,
  Grid,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { CiShare1 } from "react-icons/ci";

import {
  FcVoicePresentation,
  FcNews,
  FcGallery,
  FcAudioFile,
  FcVideoFile,
  FcDocument,
  FcBusinessContact,
} from "react-icons/fc";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#2d333b",
    color: "#fff",
    fontWeight: "300",
    fontSize: "14px",
    borderRadius: "10px",
  },
  apiTitle: {
    backgroundColor: "#1e2327",
    borderRadius: "10px 10px 0 0",
    padding: "15px 10px",
    "& svg": {
      fontSize: "30px",
      verticalAlign: "middle",
    },
  },
  preMessage: {
    wordSpacing: "-8px",
    margin: "0",
    padding: "0 25px",
    whiteSpace: "moz-pre-wrap",
    whiteSpace: "o-pre-wrap",
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
  },
  description: {
    lineHeight: 1.35,
  },
  expandIconBox: {
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.up("sm")]: {
      marginRight: theme.spacing(2),
    },
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  expanded: {
  },
  pointer: {
    
  },
}));

const ApiExpandedItem = ({ row }) => {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      className={clsx(classes.root, {
        [classes.pointer]: !expanded,
      })}
      onClick={handleToggle}
    >
      <Grid container display="flex" alignItems="center" justifyContent="space-around" flexDirection="row" className={classes.apiTitle}>
          <Grid item xs={1}>
            <Box 
                display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <CiShare1 />
            </Box>
          </Grid>
          <Grid item xs={11}>
            <Box flex={1}>
              <Typography variant="h6" style={{ wordWrap: "break-word" }}>
                {row.title}
              </Typography>
            </Box>
          </Grid>
      </Grid>

        <Grid
          container
          className={classes.expanded}
          onClick={(event) => event.stopPropagation()}
        >
          <Grid item xs={12}>
            <pre
              className={classes.preMessage}
              dangerouslySetInnerHTML={{
                __html: row.message,
              }}
            ></pre>
          </Grid>
        </Grid>

    </Box>
  );
};

export default ApiExpandedItem;
