import React, { useState, useEffect, useReducer } from "react";
import {
  Grid,
  Button,
  InputLabel,
  List,
  Box,
  SwipeableDrawer,
  FormControl,
  Typography,
  Backdrop,
  Tooltip,
  CircularProgress,
  TextField,
  Switch,
  Avatar,
  Select,
  MenuItem,
  Paper,
  IconButton,
  TableRow,
  TableCell,
  Collapse,
  Table,
  TableBody,
  TableContainer,
  TableHead,
} from "@material-ui/core";
import clsx from "clsx";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {
  FcVoicePresentation,
  FcNews,
  FcGallery,
  FcAudioFile,
  FcVideoFile,
  FcDocument,
  FcBusinessContact,
} from "react-icons/fc";
import useStyles from "./style";
import globalStyles from "../../themes/style.js";
import { ArrowDropDown } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import PageTitle from "../../components/PageTitle/PageTitle";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import { Visibility as ViewIcon, Close as CloseIcon } from "@material-ui/icons";
import {
  viewAllReplyMessage,
  addReplyMessage,
  viewReplyMessage,
  changeMessageToPrimary,
  editReplyMessage,
  viewAuthentication,
} from "../../services/instance.api";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useFormik } from "formik";
import { replySchema } from "../../components/Yup/Yup";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import "react-toastify/dist/ReactToastify.css";
import { viewUser } from "../../services/user.api.js";
import ApiExpandedItem from "./ApiExpandedItem.js";

function ApiData(props) {
  const mobileQuery = useMediaQuery("(max-width:600px)");
  const classes = useStyles();
  const globalClasses = globalStyles();

  var moment = require("moment-timezone");
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const [loader, setLoader] = useState(false);
  const [instanceData, setInstanceData] = useState([]);
  const [replyData, setReplyData] = useState([]);

  const [dataList, setDataList] = useState("ADD");
  const [expanded, setExpanded] = useState(false);

  const [replyView, setReplyView] = useState({
    data: {},
  });

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const data = [
    /*{
      title: `${process.env.REACT_APP_API_SERVER}messages/sendTemplateMessage`,
      message: `
      { 
        &emsp;  "template_name": "test",
        &emsp;  "parameters": "name,email",
        &emsp;  "mobile_number": "91XXXXXXXXXX",
        &emsp;  "language_code": "en_US",
        &emsp;  "headerType": "",
        &emsp;  "fileLink": "",
        &emsp;  "token":  "${instanceData?.token}"
      }
    `,
      backgroundColor: "#A6CF98",
    }, */

    {
      title: `${process.env.REACT_APP_API_SERVER}messages/saveContact`,
      message: `
      { 
        &emsp;  "name": "Test",
        &emsp;  "email": "example@example.com",
        &emsp;  "mobile": "9876543210",
        &emsp;  "countryCode": "91",
        &emsp;  "token":  "${instanceData?.token}"
      }
    `,
      backgroundColor: "#A6CF98",
    },

    // Add more data as needed
  ];

  function ExpandableRow({ row }) {
    const [expanded, setExpanded] = useState(false);

    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    return (
      <>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow style={{ backgroundColor: row.backgroundColor }}>
                <TableCell>
                  <Grid
                    container
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={2}>
                      <IconButton>{row.customIcon}</IconButton>
                    </Grid>
                    <Grid item xs={8} style={{ flex: 1 }}>
                      {/* <p style={{textWrap:'wrap'}}>
                      {row.title}
                      </p> */}
                      <Typography
                        variant="body1"
                        component="div"
                        style={{ textWrap: "wrap", wordWrap: "break-word" }}
                      >
                        {row.title}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={2}
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={handleExpandClick}
                      >
                        <ExpandMoreIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Collapse in={expanded}>
                    <Paper>
                      <Grid container>
                        <div
                          className="article-body"
                          dangerouslySetInnerHTML={{
                            __html: row.message,
                          }}
                        ></div>
                      </Grid>
                    </Paper>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  function MyApiData({ row }) {
    const [expand, setExpand] = useState();

    return (
      <>
        <Paper style={{ backgroundColor: "black" }}>
          <Card>
            <CardHeader
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              <Avatar aria-label="recipe" style={{ backgroundColor: "red" }}>
                R
              </Avatar>
              <Typography variant="body2" component="p">
                {row.title}
              </Typography>
              <IconButton onClick={() => setExpand(!expand)}>
                <ArrowDropDown />
              </IconButton>
            </CardHeader>
            <Collapse in={expand}>
              <Typography>
                <p
                  dangerouslySetInnerHTML={{
                    __html: row.message,
                  }}
                ></p>
              </Typography>
            </Collapse>
          </Card>
        </Paper>
      </>
    );
  }

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      viewAllReplyMessage({ token: token })
        .then((response) => {
          if (response.data.status === true) {
            setReplyData(response.data.data);
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      viewAuthentication({ userId: user?.userId, token: token })
        .then((response) => {
          if (response.data.status === true) {
            setInstanceData(response.data.data, "opopopop");
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  return (
    <>
      {/* <Grid container spacing={2}>
        {data.map((row, index) => (
          <Grid item xs={12} lg={6}>
            <ExpandableRow key={index} row={row} />
          </Grid>
        ))}
      </Grid> */}

      <Grid container spacing={2}>
        {data.map((row, index) => (
          <Grid item xs={12} lg={6}>
            <ApiExpandedItem key={index} row={row} />
          </Grid>
        ))}
      </Grid>

      {/* <Grid container spacing={2}>
        <Grid item xs={12}>
          <MUIDataTable
            options={{
              pagination: true,
              sort: false,
              selectableRows: "none",
              search: false,
              filter: false,
              print: false,
              download: false,
              responsive: mobileQuery === true ? "vertical" : "standard",
              //   customToolbar: () => <HeaderElements />,
              textLabels: {
                body: {
                  noMatch: "Oops! Matching record could not be found",
                },
              },
            }}
            columns={[
              {
                name: "S.No",
              },

              {
                name: "Message",
              },
              {
                name: "Primary",
              },

              {
                name: "Posted Date",
              },
              {
                name: "Actions",
              },
            ]}
            data={replyData?.map((item, index) => {
              return [
                index + 1,

                item.message,
                <Switch
                  checked={parseInt(item.isPrimary) === 1 ? true : false}
                  onChange={(e) => {
                    handleStatus(item.id);
                  }}
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />,
                item?.created
                  ? moment(item?.created)
                      .tz(moment.tz.guess())
                      .format("DD-MM-YYYY")
                  : "",
                <>
                  <Grid container className={globalClasses.space}>
                    <Grid item xs className={globalClasses.toolAlign}>
                      <Tooltip
                        title="Edit Reply"
                        placement="bottom"
                        aria-label="edit"
                      >
                        <EditIcon
                          className={globalClasses.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "EDIT");
                          }}
                        />
                      </Tooltip>
                      <Tooltip
                        title="View Reply"
                        placement="bottom"
                        aria-label="view"
                      >
                        <ViewIcon
                          className={globalClasses.toolIcon}
                          onClick={(e) => {
                            handleShow(item.id, "VIEW");
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </>,
              ];
            })}
          />

          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: globalClasses.drawer }}
            disableSwipeToOpen={false}
          >
            {list("right")}
          </SwipeableDrawer>

        </Grid>
      </Grid> */}

      <Backdrop className={globalClasses.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}

export default ApiData;
