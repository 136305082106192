import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  CircularProgress,
  TextField,
} from "@material-ui/core";
import { withRouter, useHistory, Link } from "react-router-dom";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import useStyles from "./styles";
// import { useUserDispatch } from "../../context/UserContext";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import { createUser } from "../../services/auth.api";
import CloseIcon from "@material-ui/icons/Close";
import {
  registerSchema,
  registerWithoutSchema,
} from "../../components/Yup/Yup";
//  import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
import logo from "../../images/logo.png";
import RegisterImg from "../../images/registerImg.png";
import PhoneInput from "react-phone-input-2";

//import {
// loadCaptchaEnginge,
//LoadCanvasTemplate,
//validateCaptcha,
//} from 'react-simple-captcha';

import Notification from "../../components/Notification/Notification";

import useGlobalStyles from "../../themes/style";
import "react-phone-input-2/lib/bootstrap.css";
import "./index.css";

function Author275(props) {
  const queryParams = new URLSearchParams(props.location.search);
  const referral_code = queryParams.get("referral_code");
  const history = useHistory();

  var classes = useStyles();
  var globalClasses = useGlobalStyles();
  // var userDispatch = useUserDispatch();
  const [loader, setLoader] = useState(false);
  // var [activeTabId, setActiveTabId] = useState(0);

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");

  const handleChange = (values, allDetails) => {
    setCountryCode(allDetails.dialCode);
    setPhoneNumber(values);
  };

  useEffect(() => {
    //  loadCaptchaEnginge(6);
  }, []);

  const onRegister = useFormik({
    initialValues: {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      address: "",
      referral: referral_code,
      mobile: "",
      //captcha:''
    },
    validationSchema:
      phoneNumber === "" ? registerSchema : registerWithoutSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      {
        /*  if (validateCaptcha(values.captcha) === true) { */
      }

      setLoader(true);
      createUser(values, phoneNumber, countryCode)
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            history.push("/register");
            resetForm();
            setPhoneNumber(countryCode);
            onRegister.setValues({
              referral: "",
              // "captcha":''
            });
          } else {
            handleNotificationCall("error", response.data.message);
            // loadCaptchaEnginge(6);
            onRegister.setValues({
              email: onRegister.values.email,
              password: onRegister.values.password,
              firstName: onRegister.values.firstName,
              lastName: onRegister.values.lastName,
              address: onRegister.values.address,
              mobile: onRegister.values.mobile,
              //"captcha":''
            });
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });

      {
        /* } else {
          setSubmitting(false); 
          setLoader(false);
          loadCaptchaEnginge(6);
          handleNotificationCall("error", "Invalid Captcha");
          onRegister.setValues({ 
            "email": onRegister.values.email,
            "password": onRegister.values.password,
            "firstName": onRegister.values.firstName,
            "lastName":onRegister.values.lastName, 
            "address":onRegister.values.address,
            "mobile": onRegister.values.mobile,
            "captcha":''
          })
        } */
      }
    },
  });

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  return (
    <Grid container className={classes.container}>
      <div className={classes.loginInner}>
        <div className={classes.loginLeft}>
          <img src={RegisterImg} />
        </div>

        <div className={classes.loginRight}>
          <div className={classes.loginForm}>
            <div className={classes.loginHead}>
              <img src={logo} alt="kadigaa" />

              <br />

              <Link to="/home">Back to Home</Link>

              <Typography variant="h3" gutterBottom>
                Sign up with your work email.
              </Typography>
            </div>

            <React.Fragment>
              <form onSubmit={onRegister.handleSubmit}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      id="firstName"
                      name="firstName"
                      placeholder="Enter First Name"
                      type="text"
                      classes={{ root: globalClasses.regCustomTextField }}
                      value={onRegister.values.firstName}
                      onChange={onRegister.handleChange}
                      error={
                        onRegister.touched.firstName &&
                        Boolean(onRegister.errors.firstName)
                      }
                      helperText={
                        onRegister.touched.firstName &&
                        onRegister.errors.firstName
                      }
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <TextField
                      id="lastName"
                      name="lastName"
                      placeholder="Enter Last Name"
                      type="text"
                      classes={{ root: globalClasses.regCustomTextField }}
                      value={onRegister.values.lastName}
                      onChange={onRegister.handleChange}
                      error={
                        onRegister.touched.lastName &&
                        Boolean(onRegister.errors.lastName)
                      }
                      helperText={
                        onRegister.touched.lastName &&
                        onRegister.errors.lastName
                      }
                    />
                  </Grid>
                </Grid>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      type="email"
                      classes={{ root: globalClasses.regCustomTextField }}
                      value={onRegister.values.email}
                      onChange={onRegister.handleChange}
                      error={
                        onRegister.touched.email &&
                        Boolean(onRegister.errors.email)
                      }
                      helperText={
                        onRegister.touched.email && onRegister.errors.email
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="password"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      name="password"
                      placeholder="Password"
                      type={values.showPassword ? "text" : "password"}
                      classes={{ root: globalClasses.regCustomTextField }}
                      value={onRegister.values.password}
                      onChange={onRegister.handleChange}
                      error={
                        onRegister.touched.password &&
                        Boolean(onRegister.errors.password)
                      }
                      helperText={
                        onRegister.touched.password &&
                        onRegister.errors.password
                      }
                    />
                  </Grid>{" "}
                </Grid>

                <div className="regCustomTextField">
                  <PhoneInput
                    country={"in"}
                    id="mobile"
                    enableSearch={true}
                    value={phoneNumber}
                    classes={{ root: globalClasses.regCustomTextField }}
                    onChange={(val, allDetails) => {
                      handleChange(val, allDetails);
                    }}
                    name="mobile"
                    variant="outlined"
                  />
                  <Typography variant="inherit" color="error">
                    {phoneNumber === ""
                      ? onRegister.touched.mobile && onRegister.errors.mobile
                      : ""}
                  </Typography>
                </div>

                <TextField
                  id="referral"
                  name="referral"
                  placeholder="Enter referral code"
                  classes={{ root: globalClasses.regCustomTextField }}
                  value={onRegister.values.referral}
                  onChange={onRegister.handleChange}

                  // error={onRegister.touched.referral && Boolean(onRegister.errors.referral)}
                  //  helperText={onRegister.touched.referral && onRegister.errors.referral}
                />

                {/*    <TextField
                          id="address"
                          
                          name="address"
                          placeholder="Enter address"
                          
                          classes={{ root: globalClasses.regCustomTextField }}
                          value={onRegister.values.address}
                          onChange={onRegister.handleChange}
                          
                          error={onRegister.touched.address && Boolean(onRegister.errors.address)}
                          helperText={onRegister.touched.address && onRegister.errors.address}
           
                        />


                     
              <Grid container spacing={1} style={{ display: "none" }}>
                  <Grid item xs={6}>
                
                          <div  style={{ textAlign: "left" }}>
                                    <LoadCanvasTemplate />
                                    </div>

                    
                  </Grid>
                  <Grid item xs={6}>  <TextField
                                      id="captcha"
                                      
                                      name="captcha"
                                      placeholder="Enter captcha"
                                      
                                      classes={{ root: globalClasses.regCustomTextField }}
                                      value={onRegister.values.captcha}
                                      onChange={onRegister.handleChange}
                                      
                                      error={onRegister.touched.captcha && Boolean(onRegister.errors.captcha)}
                                      helperText={onRegister.touched.captcha && onRegister.errors.captcha}
                      
                                    /> </Grid>
              </Grid>

              */}

                <div className={classes.formButtons}>
                  {loader ? (
                    <CircularProgress size={26} />
                  ) : (
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                      disabled={onRegister.isSubmitting}
                    >
                      Create your account
                    </Button>
                  )}

                  <div className={classes.loginAccount}>
                    <p>Already have an Account?</p>
                    <Link to="/login">Go to Login</Link>
                  </div>
                </div>
              </form>
            </React.Fragment>
          </div>
        </div>
      </div>

      <ToastContainer
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        hideProgressBar={true}
      />

      {/* <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </Grid>
  );
}

export default withRouter(Author275);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}
