import React, { useState, useEffect, useReducer } from "react";
import Tooltip from "@material-ui/core/Tooltip";

import { Link, NavLink } from "react-router-dom";
import {
  FcComboChart,
  FcContacts,
  FcTemplate,
  FcAdvertising,
  FcConferenceCall,
  FcMoneyTransfer,
  FcUndo,
  FcManager,
  FcBusinessman,
} from "react-icons/fc";

import { FaUserTag } from "react-icons/fa";

import {
  CiReceipt,
  CiBoxes,
  CiSettings,
  CiLogout,
  CiUnlock,
  CiUser,
} from "react-icons/ci";

import useThemeStyles from "../../themes/style.js";

import {
  Backdrop,
  CircularProgress,
  AppBar,
  Toolbar,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  List,
  InputLabel,
  TextField,
  Button,
  SwipeableDrawer,
  InputAdornment,
  Avatar,
  Box,
  Grid,
  Typography,
  ClickAwayListener,
} from "@material-ui/core";

import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import {
  Menu as MenuIcon,
  Person as AccountIcon,
  AccountCircle as AccountCircleIcon,
  Close as CloseIcon,
} from "@material-ui/icons";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { toast } from "react-toastify";
import Notification from "../../components/Notification/Notification";
import classNames from "classnames";
import useStyles from "./styles";
import useGlobalStyles from "../../themes/style";
import Divider from "@material-ui/core/Divider";
import { useFormik } from "formik";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PhoneInput from "react-phone-input-2";
//import parseMobile from 'libphonenumber-js/mobile'

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { useUserDispatch, signOut } from "../../context/UserContext";
// import { viewAuthentication } from '../../services/instance.api';
import {
  changeMyPassword,
  updateProfile,
  viewUser,
} from "../../services/user.api";
// import { addTag, viewAllTags } from '../../services/tags.api';

import {
  changePasswordSchema,
  updateProfileSchema,
} from "../../components/Yup/Yup";
import { FcKey } from "react-icons/fc";
import { IoIosLogOut } from "react-icons/io";
import { CopyToClipboard } from "react-copy-to-clipboard";

import logo from "../../images/logo.png";

export default function Header(props) {
  var classes = useStyles();
  var classesTheme = useThemeStyles();

  var globalClasses = useGlobalStyles();
  const user = JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("token");

  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();
  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);

  var [profileMenu, setProfileMenu] = useState(null);
  const [loader, setLoader] = useState(false);

  var [view, setView] = useState("");

  const [state, setState] = useState({
    right: false,
    sidebarOpen: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const [values, setValues] = React.useState({
    showOldPassword: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  const handleClickOldPassword = () => {
    setValues({ ...values, showOldPassword: !values.showOldPassword });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.confirm });
  };

  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("");

  const handleChange = (values, allDetails) => {
    setCountryCode(allDetails.dialCode);
    setPhoneNumber(values);
  };

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  // function copyText(text){
  //   copyTextToClipboard(text)
  //   .then(() => {
  //     handleNotificationCall("success", "Copied Successfully");
  //   })

  // }

  // function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }

  const onChangePassword = useFormik({
    initialValues: {
      password: "",
      old: "",
      confirm: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (values.password === values.confirm) {
        setLoader(true);
        changeMyPassword(values, { token: token })
          .then((response) => {
            if (response.data.status === true) {
              handleNotificationCall("success", response.data.message);

              resetForm();
              setState({ ...state, right: false });
            } else {
              handleNotificationCall("error", response.data.message);
            }
            setSubmitting(false);
            setLoader(false);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        handleNotificationCall("error", "Password is mismatch");
      }
    },
  });

  const onUpdateProfile = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      country_code: "",
      mobile: "",
      address: "",
    },
    validationSchema: updateProfileSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);
      updateProfile(values, {
        token: token,
        id: user?.userId,
        mobile:
          phoneNumber === "" ? onUpdateProfile.values.mobile : phoneNumber,
        countryCode:
          countryCode === ""
            ? onUpdateProfile.values.country_code
            : countryCode,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();

            setState({ ...state, right: false });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const superAdmin = [
    // { id: 0, label: "Dashboard", link: "/app/dashboard", view: true, icon: <Tooltip title="Dashboard" placement="right"><IconButton> <img src={Dashboard} alt="dashboard" /> </IconButton></Tooltip> } ,
    {
      id: 1,
      label: "Users",
      link: "/app/users",
      view: true,
      icon: (
        <Tooltip title="Users" placement="right">
          <IconButton>
            {" "}
            <FcManager />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 2,
      label: "Transactions",
      link: "/app/transactions",
      view: true,
      icon: (
        <Tooltip title="Transaction" placement="right">
          <IconButton>
            {" "}
            <FcMoneyTransfer />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  const admin = [
    {
      id: 0,
      label: "Dashboard",
      link: "/app/dashboard",
      view: true,
      icon: (
        <Tooltip title="Dashboard" placement="right">
          <IconButton>
            {" "}
            <FcComboChart />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 1,
      label: "Chat",
      link: "/app/instance",
      view: true,
      icon: (
        <Tooltip title="Chat" placement="right">
          <IconButton>
            {" "}
            <FcTemplate color="#fff" fill="#fff" />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 2,
      label: "Groups",
      link: "/app/group",
      view: true,
      icon: (
        <Tooltip title="Group" placement="right">
          <IconButton>
            {" "}
            <FcConferenceCall color="#fff" fill="#fff" />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 3,
      label: "Contacts",
      link: "/app/contact",
      view: true,
      icon: (
        <Tooltip title="Contact" placement="right">
          <IconButton>
            {" "}
            <FcContacts color="#fff" fill="#fff" />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    {
      id: 4,
      label: "Group Messages",
      link: "/app/campaign",
      view: true,
      icon: (
        <Tooltip title="Campaign" placement="right">
          <IconButton>
            {" "}
            <FcAdvertising color="#fff" fill="#fff" />{" "}
          </IconButton>
        </Tooltip>
      ),
    },
    // {id: 5, label: "Campaign Using Tag", link: "/app/tag_campaign", view: true, icon: <Tooltip title="Campaign" placement="right"><IconButton> <MdCampaign  color="#cfd8dc" fill="#cfd8dc"/> </IconButton></Tooltip>} ,
    // {id: 6, label: "Transaction", link:"/app/transaction",   view: true, icon:<Tooltip title="Transaction" placement="right"><IconButton> < FcMoneyTransfer color="#fff" fill="#fff" /> </IconButton></Tooltip>},
    //{id: 7, label: "Search Tag", link:"/app/search_tag",   view: true, icon:<Tooltip title="Search Tag" placement="right"><IconButton> < FaUserTag  color="#cfd8dc" fill="#cfd8dc"  /> </IconButton></Tooltip>},
    // {id: 8, label: "Reply Messages", link:"/app/reply_messages",   view: true, icon:<Tooltip title="Reply Messages" placement="right"><IconButton> < FcUndo  color="#fff" fill="#fff"  /> </IconButton></Tooltip>},
    // {id: 9, label: "Referral Transaction", link:"/app/referral_transaction",   view: true, icon:<Tooltip title="Transaction" placement="right"><IconButton> < GiTakeMyMoney color="#fff" fill="#fff" /> </IconButton></Tooltip>},
  ];

  useEffect(() => {
    const fetchData = async () => {
      setLoader(true);

      viewUser({ id: user?.userId, token: token })
        .then((response) => {
          if (response.data.status === true) {
            onUpdateProfile.setValues({
              firstName: response.data.data.firstname,
              lastName: response.data.data.lastname,
              country_code: response.data.data.country_code,
              mobile:
                response.data.data.country_code + response.data.data.mobile,
              address: response.data.data.address,
            });
            setPhoneNumber(
              response.data.data.country_code + response.data.data.mobile,
            );
          } else {
            onUpdateProfile.setValues({
              firstName: user?.firstname,
              lastName: user?.lastname,
              country_code: user?.countryCode,
              mobile:
                user?.mobile !== null ? user?.countryCode + user?.mobile : "",
              address: user?.address,
            });
            setPhoneNumber(user?.countryCode + user?.mobile);
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  const changePassword = (anchor) => (
    <Box sx={{ width: "100%" }} role="presentation">
      <List>
        <Card className={classes.root}>
          <CardHeader>
            <Grid
              container
              direction="row"
              spacing={1}
              className={globalClasses.drawerHeader}
            >
              <Typography variant="h5"> Change Password</Typography>

              <Grid className={globalClasses.drawerClose}>
                <CloseIcon
                  className={globalClasses.closeBtn}
                  size="14px"
                  onClick={toggleDrawer(anchor, false)}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <form onSubmit={onChangePassword.handleSubmit}>
            <CardContent className={globalClasses.drawerContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <InputLabel shrink htmlFor="old">
                    {" "}
                    Old Password{" "}
                  </InputLabel>

                  <TextField
                    id="old"
                    //  classes={{ root: globalClasses.customTextField }}
                    type={values.showOldPassword ? "text" : "password"}
                    name="old"
                    // margin="normal"
                    placeholder="Enter Old Password"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickOldPassword}
                          >
                            {values.showOldPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={onChangePassword.values.old}
                    onChange={onChangePassword.handleChange}
                    error={
                      onChangePassword.touched.old &&
                      Boolean(onChangePassword.errors.old)
                    }
                    helperText={
                      onChangePassword.touched.old &&
                      onChangePassword.errors.old
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="password">
                    {" "}
                    New Password{" "}
                  </InputLabel>

                  <TextField
                    id="password"
                    //classes={{ root: globalClasses.customTextField }}
                    type={values.showPassword ? "text" : "password"}
                    name="password"
                    // margin="normal"
                    placeholder="Enter New Password"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={onChangePassword.values.password}
                    onChange={onChangePassword.handleChange}
                    error={
                      onChangePassword.touched.password &&
                      Boolean(onChangePassword.errors.password)
                    }
                    helperText={
                      onChangePassword.touched.password &&
                      onChangePassword.errors.password
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="confirm">
                    {" "}
                    Confirm Password{" "}
                  </InputLabel>

                  <TextField
                    id="confirm"
                    //classes={{ root: globalClasses.customTextField }}
                    type={values.showConfirmPassword ? "text" : "password"}
                    name="confirm"
                    // margin="normal"
                    placeholder="Enter Confirm Password"
                    InputProps={{
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickConfirmPassword}
                          >
                            {values.showConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={onChangePassword.values.confirm}
                    onChange={onChangePassword.handleChange}
                    error={
                      onChangePassword.touched.confirm &&
                      Boolean(onChangePassword.errors.confirm)
                    }
                    helperText={
                      onChangePassword.touched.confirm &&
                      onChangePassword.errors.confirm
                    }
                  />
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                spacing={2}
                className={globalClasses.drawerFooter}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                  disabled={onChangePassword.isSubmitting}
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={toggleDrawer(anchor, false)}
                >
                  Close
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </List>
    </Box>
  );

  const profileUpdate = (anchor) => (
    <Box sx={{ width: "100%" }} role="presentation">
      <List>
        <Card className={classes.root}>
          <CardHeader>
            <Grid
              container
              direction="row"
              spacing={1}
              className={globalClasses.drawerHeader}
            >
              <Typography variant="h5"> Update Profile</Typography>

              <Grid className={globalClasses.drawerClose}>
                <CloseIcon
                  className={globalClasses.closeBtn}
                  size="14px"
                  onClick={toggleDrawer(anchor, false)}
                />
              </Grid>
            </Grid>
          </CardHeader>
          <form onSubmit={onUpdateProfile.handleSubmit}>
            <CardContent className={globalClasses.drawerContent}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="firstName">
                    {" "}
                    First Name{" "}
                  </InputLabel>
                  <TextField
                    id="firstName"
                    name="firstName"
                    //margin="normal"
                    placeholder="Enter First Name"
                    type="text"
                    // classes={{ root: globalClasses.customTextField }}
                    value={onUpdateProfile.values.firstName}
                    onChange={onUpdateProfile.handleChange}
                    error={
                      onUpdateProfile.touched.firstName &&
                      Boolean(onUpdateProfile.errors.firstName)
                    }
                    helperText={
                      onUpdateProfile.touched.firstName &&
                      onUpdateProfile.errors.firstName
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="lastName">
                    {" "}
                    Last Name{" "}
                  </InputLabel>
                  <TextField
                    id="lastName"
                    name="lastName"
                    //margin="normal"
                    placeholder="Enter Last Name"
                    type="text"
                    // classes={{ root: globalClasses.customTextField }}
                    value={onUpdateProfile.values.lastName}
                    onChange={onUpdateProfile.handleChange}
                    error={
                      onUpdateProfile.touched.lastName &&
                      Boolean(onUpdateProfile.errors.lastName)
                    }
                    helperText={
                      onUpdateProfile.touched.lastName &&
                      onUpdateProfile.errors.lastName
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="mobile">
                    {" "}
                    Mobile{" "}
                  </InputLabel>

                  <PhoneInput
                    country={"in"}
                    id="mobile"
                    enableSearch={true}
                    value={phoneNumber}
                    //  classes={{ root: globalClasses.customTextField }}

                    onChange={(val, allDetails) => {
                      handleChange(val, allDetails);
                    }}
                    onBlur={(e) => {
                      onUpdateProfile.setValues({
                        firstName: onUpdateProfile.values.firstName,
                        lastName: onUpdateProfile.values.lastName,
                        country_code: countryCode,
                        mobile: phoneNumber,
                        address: onUpdateProfile.values.address,
                      });
                    }}
                    //margin="normal"
                    name="mobile"
                    // variant="outlined"
                  />
                  <Typography variant="inherit" color="error">
                    {phoneNumber === ""
                      ? onUpdateProfile.touched.mobile &&
                        onUpdateProfile.errors.mobile
                      : ""}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={6} lg={6}>
                  <InputLabel shrink htmlFor="address">
                    {" "}
                    Address{" "}
                  </InputLabel>

                  <TextField
                    multiline
                    id="address"
                    maxRows={2}
                    rows={2}
                    name="address"
                    //margin="normal"
                    placeholder="Enter address"
                    //classes={{ root: globalClasses.customTextField }}
                    value={onUpdateProfile.values.address}
                    onChange={onUpdateProfile.handleChange}
                    error={
                      onUpdateProfile.touched.address &&
                      Boolean(onUpdateProfile.errors.address)
                    }
                    helperText={
                      onUpdateProfile.touched.address &&
                      onUpdateProfile.errors.address
                    }
                  />
                </Grid>
              </Grid>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </CardContent>
            <CardActions>
              <Grid
                container
                direction="row"
                spacing={2}
                className={globalClasses.drawerFooter}
              >
                <Button
                  variant="contained"
                  color="primary"
                  size="small"
                  type="submit"
                >
                  Update
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={toggleDrawer(anchor, false)}
                >
                  Close
                </Button>
              </Grid>
            </CardActions>
          </form>
        </Card>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar
        position="fixed"
        className={
          layoutState.isSidebarOpened ? classes.appBar : classes.appBarOpen
        }
      >
        <div className={classesTheme.siteContainer}>
          <Toolbar className={classes.toolbar}>
            <IconButton
              color="inherit"
              onClick={() => toggleSidebar(layoutDispatch)}
              className={classNames(
                classes.headerMenuButtonSandwich,
                classes.headerMenuButtonCollapse,
              )}
            >
              {layoutState.isSidebarOpened ? (
                <MenuIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              ) : (
                <MenuIcon
                  classes={{
                    root: classNames(
                      classes.headerIcon,
                      classes.headerIconCollapse,
                    ),
                  }}
                />
              )}
            </IconButton>

            <div className={classes.appbarLogo}>
              <Link to="/app/dashboard">
                <img src={logo} alt="kadigaa" />
              </Link>
            </div>

            <div className={classes.headerLinks}>
              {user?.role_code === "SUPERADMIN"
                ? superAdmin.map((link) => (
                    //<Link
                    // key={link.id}
                    // {...link}

                    //  />

                    <NavLink to={link.id} activeClassName="is-active">
                      {link.label}
                    </NavLink>
                  ))
                : admin.map((link) => (
                    //<Link
                    //   key={link.id}

                    //  {...link}
                    // />

                    <NavLink to={link.link} activeClassName="is-active">
                      {link.label}
                    </NavLink>
                  ))}
            </div>

            <div className={classes.headerLinksIcon}>
              <List>
                {/* <ListItem>
                  <Tooltip title="Referral Transaction">
                    <NavLink to="referral_transaction"  activeClassName="is-active">
                      <CiBoxes />
                    </NavLink>
                  </Tooltip>
                </ListItem>
                */}
                <ListItem>
                  <Tooltip title="Transaction">
                    <NavLink to="transaction" activeClassName="is-active">
                      <CiReceipt />
                    </NavLink>
                  </Tooltip>
                </ListItem>
                <ListItem>
                  <Tooltip title="Manage Settings">
                    <NavLink to="managesettings" activeClassName="is-active">
                      <CiSettings />
                    </NavLink>
                  </Tooltip>
                </ListItem>
              </List>
            </div>

            <ClickAwayListener onClickAway={(e) => setProfileMenu(null)}>
              <div className={classes.headerUser}>
                <IconButton
                  aria-haspopup="true"
                  color="inherit"
                  className={classes.headerMenuButton}
                  aria-controls="profile-menu"
                  onClick={(e) => setProfileMenu(e.currentTarget)}
                >
                  <FcBusinessman />
                </IconButton>

                {profileMenu ? (
                  <div className={classes.dropdown}>
                    <List
                      component="nav"
                      className={classes.profile}
                      aria-label="Profile"
                    >
                      <ListItem>
                        {/*<ListItemAvatar>
                            <Avatar>
                              <AccountIcon />
                            </Avatar>
                      </ListItemAvatar> */}

                        {user?.role_code !== "SUPERADMIN" ? (
                          <ListItemText
                            primary={
                              <>
                                {onUpdateProfile.values?.firstName +
                                  " " +
                                  onUpdateProfile.values?.lastName}{" "}
                                <br /> {onUpdateProfile.values?.mobile}
                              </>
                            }
                            secondary={
                              <div>
                                {" "}
                                {"Your referral Code: " + user?.referral_code}
                                <CopyToClipboard
                                  text={
                                    `${process.env.REACT_APP_SITE}#/register?referral_code=` +
                                    user?.referral_code
                                  }
                                >
                                  <FileCopyIcon
                                    style={{
                                      fontSize: "14px",
                                      color: "blue",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleNotificationCall(
                                        "success",
                                        "Copied Successfully",
                                      )
                                    }
                                  />
                                </CopyToClipboard>
                              </div>
                            }
                          />
                        ) : (
                          <ListItemText
                            primary={
                              onUpdateProfile.values?.firstname +
                              " " +
                              onUpdateProfile.values?.lastname
                            }
                            secondary={user?.role_code}
                          />
                        )}
                      </ListItem>
                    </List>

                    <Divider />

                    {user?.role_code !== "SUPERADMIN" ? (
                      <ListItem
                        className={
                          classes.profileMenuIcon + " " + globalClasses.gap10
                        }
                        onClick={(e) => {
                          setView("UPDATE");
                          forceUpdate();
                          setState({ ...state, right: true });
                        }}
                      >
                        <CiUser style={{ fontSize: 25 }} /> Update Profile
                      </ListItem>
                    ) : (
                      ""
                    )}
                    <ListItem
                      className={
                        classes.profileMenuIcon + " " + globalClasses.gap10
                      }
                      onClick={(e) => {
                        setView("CHANGE");
                        setState({ ...state, right: true });
                      }}
                    >
                      <CiUnlock style={{ fontSize: 25 }} /> Change Password
                    </ListItem>

                    <ListItem
                      className={
                        classes.profileMenuIcon + " " + globalClasses.gap10
                      }
                      onClick={() => signOut(userDispatch, props.history)}
                    >
                      <CiLogout style={{ fontSize: 25 }} /> Logout
                    </ListItem>
                  </div>
                ) : null}
              </div>
            </ClickAwayListener>
          </Toolbar>
        </div>

        <SwipeableDrawer
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
          onOpen={toggleDrawer("right", true)}
          classes={{ paper: globalClasses.drawer }}
          disableSwipeToOpen={false}
        >
          {view === "CHANGE" ? changePassword("right") : profileUpdate("right")}
        </SwipeableDrawer>
      </AppBar>

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
