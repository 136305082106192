import React, { useCallback, useEffect } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";

import useStyles from "../../themes/style.js";


import { Grid, Typography, Button, CardContent } from "@material-ui/core";

import LoginHeader from "../../components/Login/LoginHeader.js";
import LoginFooter from "../../components/Login/LoginFooter.js";


import chatBotMain from "../../images/chatbot/chatBotMain.jpg";
import chatBotInfo1 from "../../images/chatbot/chatBotInfo1.jpg";
import chatBotInfo2 from "../../images/chatbot/chatBotInfo2.jpg";
import chatBotInfo3 from "../../images/chatbot/chatBotInfo3.jpg";
import { BsArrowRightShort } from "react-icons/bs";

import CheckIcon from '@material-ui/icons/Check';


import { viewAllPlans } from '../../services/payment.api';
 
  
function AboutUsPage() {  

    const classes = useStyles();
  

  // const AntSwitch = styled(Switch)(({ theme }) => ({
  //   width: 40,
  //   height: 24,
  //   padding: 0,
  //   display: 'flex',
  //   '&:active': {
  //     '& .MuiSwitch-thumb': {
  //       width: 15,
  //     },
  //     '& .MuiSwitch-switchBase.Mui-checked': {

  //       transform: 'translateX(9px)',
  //     },
  //   },
  //   '& .MuiSwitch-switchBase': {
  //     padding:2,
  //     paddingLeft: 1,
  //     '&.Mui-checked': { 
  //       paddingLeft: 8,
  //       transform: 'translateX(12px)',
  //       color: '#fff',
  //       '& + .MuiSwitch-track': {
  //         opacity: 1,
  //         backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
  //       },
  //     },
  //   },
  //   '& .MuiSwitch-thumb': {
  //     boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
  //     width: 19,
  //     height: 19,
  //     borderRadius: 15,
  //     transition: theme.transitions.create(['width'], {
  //       duration: 200,
  //     }),
  //   },
  //   '& .MuiSwitch-track': {
  //     borderRadius: 12,
  //     opacity: 1,
  //     backgroundColor:
  //       theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
  //     boxSizing: 'border-box',
  //   },
  // }));

  //   const ecoCard = [

  //     {
  //         title: 'Basic',
  //         subheader: 'Phasellus id nulla interdum, molestie magna sed, volutpat leo.',  
  //         price: 50,

  //     },
  //     {
  //         title: 'Growth',
  //         subheader: 'Phasellus id nulla interdum, molestie magna sed, volutpat leo.',  
  //         price: 100,

  //     },
  //     {
  //       title: 'High',
  //       subheader: 'Phasellus id nulla interdum, molestie magna sed, volutpat leo.',  
  //       price: 200,

  //   },

  // ];

  var token = localStorage.getItem("token");

  const [billingData, setBillingData] = React.useState([]);
  const [loader, setLoader] = React.useState(false);






  const billingList = useCallback(() => {
    setLoader(true);

    viewAllPlans({ token: token }).then((response) => {
      if (response.data.status === true) {
        setBillingData(response.data.data);
      }
      setLoader(false);
    })
      .catch(function (error) {
        console.log(error);
      })

  }, []);

  useEffect(() => {

    billingList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingList]);  


    return (
        <>
            <LoginHeader />

                <div className={classes.ChatBotMain}>
                    <div className={classes.siteContainer}>
                        <div className={classes.ChatBotMainInner}>
                            <div className={classes.ChatBotMainLeft}>
                                <Typography variant="h1">
                                    Accelerate your growth with custom automations
                                </Typography>
                                <Typography variant="body1">
                                    Optimize your business performance with plug-and-play chatbots. Automate your sales, support, 
                                    and customer service so you can achieve your growth goals faster.
                                </Typography>
                                <Link to="/" className="ChatBotMainRegis">
                                    Get Started Today
                                </Link>
                                <Link to="/" className="ChatBotMainLogin">
                                    Go to Login <BsArrowRightShort />
                                </Link>
                            </div>
                            <div className={classes.ChatBotMainRight}>
                                <img src={chatBotMain} alt="KadigaaAI" />

                                <Typography variant="h1" className={classes.ChatBotMainRightFirst}>
                                    Hi!
                                </Typography>
                                <Typography variant="h1" className={classes.ChatBotMainRightSecond}>
                                    May i help you?
                                </Typography>

                            </div>
                        </div>    
                    </div>
                </div>




                <div className={classes.ChatBotInfo}>
                    <div className={classes.siteContainer}>
                        

                        <div className={classes.ChatBotInfoHead}>
                            <Typography variant="h1">
                                Accelerate growth with chatbots
                            </Typography>
                        </div>


                        <div className={classes.ChatBotInfoList}>
                            <div className={classes.ChatBotInfoListLeft}>
                                <img src={chatBotInfo1} alt="KadigaaAI" />
                            </div>
                            <div className={classes.ChatBotInfoListRight}>
                                <Typography variant="h1">
                                    Answer customers in their preferred language
                                </Typography>
                                <Typography variant="body1">
                                    Optimize your business performance with plug-and-play chatbots. Automate your sales, support, 
                                    and customer service so you can achieve your growth goals faster.
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.ChatBotInfoList}>
                            <div className={classes.ChatBotInfoListLeft}>
                                <img src={chatBotInfo2} alt="KadigaaAI" />
                            </div>
                            <div className={classes.ChatBotInfoListRight}>
                                <Typography variant="h1">
                                    Answer customers in their preferred language
                                </Typography>
                                <Typography variant="body1">
                                    Optimize your business performance with plug-and-play chatbots. Automate your sales, support, 
                                    and customer service so you can achieve your growth goals faster.
                                </Typography>
                            </div>
                        </div>

                        <div className={classes.ChatBotInfoList}>
                            <div className={classes.ChatBotInfoListLeft}>
                                <img src={chatBotInfo3} alt="KadigaaAI" />
                            </div>
                            <div className={classes.ChatBotInfoListRight}>
                                <Typography variant="h1">
                                    Answer customers in their preferred language
                                </Typography>
                                <Typography variant="body1">
                                    Optimize your business performance with plug-and-play chatbots. Automate your sales, support, 
                                    and customer service so you can achieve your growth goals faster.
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>



                <div className={classes.ChatBotPricing}>
                    <div className={classes.siteContainer}>


                    <Typography variant="h1" className={classes.ChatBotPricingHead}>
                        We have got a pricing plan that's perfect for you
                    </Typography>



                    <Grid container spacing={2} justifyContent="center" pt={5}  >
                        {billingData.map((item, index) => (
                        <Grid item sm={12} md={3} key={index}>
                            <Grid item={item} > 
                                <Grid direction="column" alignItems="center" gap={2}  className={classes.ChatBotPricingList}>
                                <Typography variant="h6" pt={2} pb={2} className={classes.ChatBotPricingTitle}>{item.title}</Typography>

                                <Typography variant="body" className={classes.ChatBotPricingDesc}> 
                                <div dangerouslySetInnerHTML={{
                                    __html:
                                    item.about
                                }}></div></Typography>

 
                                {item.price !== 0 ?
                                    <>
                                    <Typography variant="body" p={1}  > <span style={{ fontSize: "1.875rem",fontWeight: 900  }}> {`${item.currency +" "+ item.price}`}</span>   {"/month"}</Typography>
                                        
                                        <br />

                                        <a href="https://ai.kadigaa.com/" target="_blank" className={classes.ChatBotPricingLink}>
                                            Get Started
                                        </a> 

                                    </>
                                    : <>

                                    <Typography variant="body" p={1}  > <span style={{ fontSize: "1.875rem", fontWeight: 900 }}> {`${item.currency+"  "+ item.price}`}</span>   {`${"/"+item.title}`}</Typography> 
                                        
                                        <br />

                                        <a href="https://ai.kadigaa.com/" target="_blank" className={classes.ChatBotPricingLink}>
                                            Get Started
                                        </a> 

                                    </>

                                }


                                <Grid container spacing={2} >


                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />


                                    <Typography variant="body"  >{item.questions} questions per month</Typography>
                                    </Grid>

                                    <Grid item sm={12} display={"flex"} gap="10px" >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >Maximum {item.token_limit} characters of input for training</Typography>
                                    </Grid>
                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >  Install the chatbot on your website</Typography>
                                    </Grid>


                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >  Customize the look & feel of the chatbot</Typography>
                                    </Grid>


                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >  Whatsapp Integration</Typography>
                                    </Grid>

                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >  Messenger Integration </Typography>
                                    </Grid>



                                    <Grid item sm={12} display={"flex"} gap="10px"  >
                                    <CheckIcon className={classes.ChatBotPricingCheckBox} />

                                    <Typography variant="body"  >  Support 1 languange</Typography>
                                    </Grid>

                                </Grid>



                                </Grid>
                            </Grid>
                        </Grid>
                        ))}
                    </Grid>



                    </div>
                </div>    


                <div className={classes.ChatBotQuick}>
                    <div className={classes.siteContainer}>
                        <div className={classes.ChatBotQuickInner}>
                            <div className={classes.ChatBotQuickLeft}>
                                <Typography variant="h1">
                                    Accelerate growth with chatbots
                                </Typography>
                                <Typography variant="body1">
                                    Improve your business with sales, support, and customer service automation
                                </Typography>
                            </div> 
                            <div className={classes.ChatBotQuickRight}>
                                <Link to="/" className="ChatBotQuickRegis">
                                    Get Started Today
                                </Link>
                                <br />
                                <Link to="/" className="ChatBotQuickLogin">
                                    Go to Login <BsArrowRightShort />
                                </Link>
                            </div> 
                        </div>    
                    </div>
                </div>


                            




            <LoginFooter />


        </>    
    );
}


export default withRouter(AboutUsPage);