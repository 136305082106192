import React, { useState, useEffect } from "react";
import Tooltip from '@material-ui/core/Tooltip';

import { Drawer, IconButton, List } from "@material-ui/core";
import { 
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
 
import useStyles from "./styles";
import globalStyles from "../../themes/style";

import SidebarLink from "./components/SidebarLink/SidebarLink";
 
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";

import { FcContacts, FcTemplate, FcAdvertising, FcConferenceCall, FcMoneyTransfer, FcUndo, FcManager, FcComboChart } from "react-icons/fc";
 
import { FaUserTag } from "react-icons/fa"; 

import logo from '../../images/logo.png';
import rlogo from '../../images/logo.png';

function Sidebar({ location }) {
  var classes = useStyles();
  var globalClasses = globalStyles();
  var theme = useTheme(); 
  const user = JSON.parse(localStorage.getItem("user")); 
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch(); 
  // local
  var [isPermanent, setPermanent] = useState(true);
 


  const superAdmin = [
   // { id: 0, label: "Dashboard", link: "/app/dashboard", view: true, icon: <Tooltip title="Dashboard" placement="right"><IconButton> <img src={Dashboard} alt="dashboard" /> </IconButton></Tooltip> } ,
    { id: 1, label: "Users", link: "/app/users", view: true, icon: <Tooltip title="Users" placement="right"><IconButton> <FcManager /> </IconButton></Tooltip>} ,
    { id: 2, label: "Transactions", link: "/app/transactions", view: true, icon:  <Tooltip title="Transaction" placement="right"><IconButton> < FcMoneyTransfer/> </IconButton></Tooltip>} ,

  ];
  
   const admin = [
    { id: 0, label: "Dashboard", link: "/app/dashboard", view: true, icon: <Tooltip title="Dashboard" placement="right"><IconButton> < FcComboChart /> </IconButton></Tooltip> } ,
     {id: 1, label: "Instance", link: "/app/instance", view: true, icon:  <Tooltip title="Instance" placement="right"><IconButton> < FcTemplate color="#fff" fill="#fff"  /> </IconButton></Tooltip>} ,
    {id: 2, label: "Group", link: "/app/group", view:  true, icon:<Tooltip title="Group" placement="right"><IconButton> < FcConferenceCall  color="#fff" fill="#fff"/> </IconButton></Tooltip>},
    {id: 3, label: "Contact", link: "/app/contact", view: true, icon: <Tooltip title="Contact" placement="right"><IconButton> < FcContacts  color="#fff" fill="#fff"/> </IconButton></Tooltip>},
      {id: 4, label: "Campaign", link: "/app/campaign", view: true, icon: <Tooltip title="Campaign" placement="right"><IconButton> < FcAdvertising  color="#fff" fill="#fff"/> </IconButton></Tooltip>} ,
     // {id: 5, label: "Campaign Using Tag", link: "/app/tag_campaign", view: true, icon: <Tooltip title="Campaign" placement="right"><IconButton> <MdCampaign  color="#cfd8dc" fill="#cfd8dc"/> </IconButton></Tooltip>} ,
      {id: 6, label: "Transaction", link:"/app/transaction",   view: true, icon:<Tooltip title="Transaction" placement="right"><IconButton> < FcMoneyTransfer color="#fff" fill="#fff" /> </IconButton></Tooltip>},
     {id: 7, label: "Search Tag", link:"/app/search_tag",   view: true, icon:<Tooltip title="Search Tag" placement="right"><IconButton> < FaUserTag  color="#cfd8dc" fill="#cfd8dc"  /> </IconButton></Tooltip>},
     {id: 8, label: "Reply Messages", link:"/app/reply_messages",   view: true, icon:<Tooltip title="Reply Messages" placement="right"><IconButton> < FcUndo  color="#fff" fill="#fff"  /> </IconButton></Tooltip>},
 //  {id: 9, label: "Referral Transaction", link:"/app/referral_transaction",   view: true, icon:<Tooltip title="Transaction" placement="right"><IconButton> < GiTakeMyMoney color="#fff" fill="#fff" /> </IconButton></Tooltip>},

  ];
  
  useEffect(function() {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
      onClose={() => toggleSidebar(layoutDispatch)}
    >
      <div className={classes.toolbar +" "+ globalClasses.center}  >
      
          {isSidebarOpened ? (
           <img src={logo} alt="logo" style={{ width: '150px'}}  /> 
          ) : (
            <img src={rlogo} alt="icon" style={{ width: '50%'}}   /> 
          )}
         
        </div>
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        
     
      {user?.role_code === "SUPERADMIN" ? superAdmin.map(link => (
                          <SidebarLink
                            key={link.id}
                            location={location}
                            isSidebarOpened={isSidebarOpened}
                            {...link}
                           
                          />
                        )) 
                       
                        : admin.map(link => (
                          <SidebarLink
                            key={link.id}
                            location={location}
                            isSidebarOpened={isSidebarOpened}
                            {...link}
                          />
                        
                        ))}

   
      </List>
    </Drawer>
    
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
