import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  CircularProgress,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
} from "@material-ui/core";
import useStyles from "./styles";
import globalStyles from "../../themes/style";
import Notification from "../../components/Notification/Notification";

import PageTitle from "../../components/PageTitle";
//import { Typography } from "../../components/Wrappers";
import {
  message_rates,
  total_messages,
  message_statistics,
} from "../../services/analytics.api";
import { viewAuthentication } from "../../services/instance.api";

import red from "@material-ui/core/colors/red";
//import { useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";

import {
  // BarChart,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  Bar,
  ResponsiveContainer,
  ComposedChart,
  Line,
  YAxis,
  XAxis,
} from "recharts";
export default function Dashboard(props) {
  var classes = useStyles();
  var globalClasses = globalStyles();
  const filterRef = useRef(null);
  var moment = require("moment-timezone");
  //  var theme = useTheme();
  const token = localStorage.getItem("token");

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [periodState, setPeriodState] = useState("week");
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();

  const [totalLoader, setTotalLoader] = useState(false);
  const [ratesLoader, setRatesLoader] = useState(false);
  const [staticLoader, setStaticLoader] = useState(false);

  const user = JSON.parse(localStorage.getItem("user"));
  const [total_message, setTotal_message] = useState([
    {
      Sent: 0,
      Received: 0,
      Failed: 0,
    },
  ]);
  const [rates, setRates] = useState([
    {
      Delivered: 0,
      Read: 0,
      Failed: 0,
    },
  ]);

  const [statics, setStatics] = useState([]);

  const [instanceId, setInstanceId] = React.useState([]);

  useEffect(() => {
    setFromDate(moment().subtract(7, "d").format("YYYY-MM-DD"));
    setToDate(moment().subtract(0, "d").format("YYYY-MM-DD"));

    viewAuthentication({ userId: user?.userId, token: token }).then(
      (response) => {
        if (response.data.status === true) {
          setInstanceId(response?.data?.data);

          setTotalLoader(true);
          setRatesLoader(true);
          setStaticLoader(true);

          total_messages({
            instance_id: response?.data?.data?.id,
            fromDate: filterRef.current.fromDate.value,
            toDate: filterRef.current.toDate.value,
            token: token,
          }).then((result) => {
            if (result.data.status === true) {
              setTotalLoader(false);
              setTotal_message([
                {
                  Sent: result?.data?.sent_count,
                  Received: result?.data?.recived_count,
                  Failed: result?.data?.failed_count,
                },
              ]);
            } else {
              setTotalLoader(false);
            }
          });

          message_rates({
            instance_id: response?.data?.data?.id,
            fromDate: filterRef.current.fromDate.value,
            toDate: filterRef.current.toDate.value,
            token: token,
          }).then((result) => {
            if (result.data.status === true) {
              setRatesLoader(false);
              setRates([
                {
                  Delivered: result?.data?.delivered_count,
                  Read: result?.data?.read_count,
                  Failed: result?.data?.failed_count,
                },
              ]);
            } else {
              setRatesLoader(false);
            }
          });

          /*   message_statistics({
            instance_id: response?.data?.data?.id,
            fromDate: filterRef.current.fromDate.value,
            toDate: filterRef.current.toDate.value,
            token: token,
          }).then((result) => {
            if (result.data.status === true) {
              setStaticLoader(false);

              setStatics(result.data.data);
            } else {
              setStaticLoader(false);
            }
          }); */
        }
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFromDateChange = (event) => {
    setFromDate(filterRef.current.fromDate.value);

    if (filterRef.current.fromDate.value !== "") {
      getFilterData("custom");
      setPeriodState("custom");
    } else {
      handleNotificationCall("error", "From date is required");
    }
  };

  const handleToDateChange = (event) => {
    setToDate(filterRef.current.toDate.value);
    if (filterRef.current.toDate.value !== "") {
      getFilterData("custom");
      setPeriodState("custom");
    } else {
      handleNotificationCall("error", "To date is required");
    }
  };

  function getFilterData(e) {
    setTotalLoader(true);
    setRatesLoader(true);
    setStaticLoader(true);

    if (e === "month") {
      setFromDate(moment().subtract(30, "d").format("YYYY-MM-DD"));
      setToDate(moment().subtract(0, "d").format("YYYY-MM-DD"));
      api(
        moment().subtract(30, "d").format("YYYY-MM-DD"),
        moment().subtract(0, "d").format("YYYY-MM-DD"),
      );
    } else if (e === "week") {
      setFromDate(moment().subtract(7, "d").format("YYYY-MM-DD"));
      setToDate(moment().subtract(0, "d").format("YYYY-MM-DD"));
      api(
        moment().subtract(7, "d").format("YYYY-MM-DD"),
        moment().subtract(0, "d").format("YYYY-MM-DD"),
      );
    } else {
      setFromDate(filterRef.current.fromDate.value);
      setToDate(filterRef.current.toDate.value);
      api(filterRef.current.fromDate.value, filterRef.current.toDate.value);
    }
  }

  function api(fromDates, toDates) {
    total_messages({
      instance_id: instanceId?.id,
      fromDate: fromDates,
      toDate: toDates,
      token: token,
    }).then((result) => {
      if (result.data.status === true) {
        setTotalLoader(false);
        setTotal_message([
          {
            Sent: result?.data?.sent_count,
            Received: result?.data?.recived_count,
            Failed: result?.data?.failed_count,
          },
        ]);
      } else {
        setTotalLoader(false);
      }
    });

    message_rates({
      instance_id: instanceId?.id,
      fromDate: fromDates,
      toDate: toDates,
      token: token,
    }).then((result) => {
      if (result.data.status === true) {
        setRatesLoader(false);
        setRates([
          {
            Delivered: result?.data?.delivered_count,
            Read: result?.data?.read_count,
            Failed: result?.data?.failed_count,
          },
        ]);
      } else {
        setRatesLoader(false);
      }
    });

    /* message_statistics({
      instance_id: instanceId?.id,
      fromDate: fromDates,
      toDate: toDates,
      token: token,
    }).then((result) => {
      if (result.data.status === true) {
        setStaticLoader(false);

        setStatics(result.data.data);
      } else {
        setStaticLoader(false);
      }
    }); */
  }

  const formatXAxis = (tickItem) => {
    return moment(tickItem).format("DD-MM-YYYY");
  };

  return (
    <>
      <PageTitle title="Dashboard" />

      <Grid container className={globalClasses.pageHeader}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={globalClasses.pageHeaderLeft}
        >
          <Typography variant="h3"> Dashboard</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={globalClasses.pageHeaderRight}
        ></Grid>
      </Grid>

      <div className={globalClasses.contentInner}>
        <div className={globalClasses.filter}>
          <Typography variant="h5">
            {" "}
            <span></span> Filter Option
          </Typography>

          {/* {totalLoader && ratesLoader && staticLoader? "" :  */}
          <form
            ref={filterRef}
            onSubmit={(e) => {
              e.preventDefault();
              //getFilterData();
            }}
          >
            <Grid container className={globalClasses.filterForm}>
              <TextField
                name="fromDate"
                label="From"
                InputLabelProps={{ shrink: true }}
                classes={{ root: globalClasses.filterTextField }}
                type="date"
                value={fromDate}
                onChange={handleFromDateChange}
                required
                disabled={
                  totalLoader && ratesLoader && staticLoader ? true : false
                }
              />

              <TextField
                name="toDate"
                label="To"
                InputLabelProps={{ shrink: true }}
                classes={{ root: globalClasses.filterTextField }}
                type="date"
                value={toDate}
                onChange={handleToDateChange}
                required
                disabled={
                  totalLoader && ratesLoader && staticLoader ? true : false
                }
              />

              <FormControl
                className={globalClasses.filterTextField}
                required
                disabled={
                  totalLoader && ratesLoader && staticLoader ? true : false
                }
              >
                <InputLabel shrink id="period">
                  {" "}
                  Period{" "}
                </InputLabel>
                <Select
                  value={periodState}
                  onChange={(e) => {
                    setPeriodState(e.target.value);
                    getFilterData(e.target.value);
                  }}
                  className={[globalClasses.filterTextFieldSelect]}
                  name="period"
                >
                  <MenuItem value="week">Last 7 days</MenuItem>
                  <MenuItem value="month">Last 30 days</MenuItem>
                  <MenuItem value="custom">Custom range</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </form>
          {/* } */}
        </div>

        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.boxPadding}>
              <Typography variant="h5">Message Reports</Typography>

              {totalLoader ? (
                <div className={classes.centerP10}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={250}>
                  <ComposedChart
                    width={530}
                    height={250}
                    margin={{ top: 30, right: 10, left: -35, bottom: 5 }}
                    data={total_message}
                  >
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      axisLine={{ stroke: "#f5f5f5" }}
                    />
                    <YAxis tickLine={false} axisLine={{ stroke: "#f5f5f5" }}>
                      {/*
                      {" "}
                      <Label
                        angle={270}
                        position="left"
                        style={{ textAnchor: "middle" }}
                      >
                        {" "}
                        Count{" "}
                         
                      </Label>
                      */}
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="Sent"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill="#35baf6"
                    />
                    <Bar
                      dataKey="Received"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill="#6fbf73"
                    />
                    <Bar
                      dataKey="Failed"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill={red[500]}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className={classes.boxPadding}>
              <Typography variant="h5"> Delivered / Read Reports</Typography>
              {ratesLoader ? (
                <div className={classes.centerP10}>
                  <CircularProgress color="primary" />
                </div>
              ) : (
                <ResponsiveContainer width="100%" height={250}>
                  <ComposedChart
                    width={530}
                    height={250}
                    margin={{ top: 30, right: 10, left: -35, bottom: 5 }}
                    data={rates}
                  >
                    <CartesianGrid stroke="#f5f5f5" vertical={false} />
                    <XAxis
                      dataKey="name"
                      tickLine={false}
                      axisLine={{ stroke: "#f5f5f5" }}
                    />
                    <YAxis tickLine={false} axisLine={{ stroke: "#f5f5f5" }}>
                      {/*
                      {" "}
                      <Label
                        angle={270}
                        position="left"
                        style={{ textAnchor: "middle" }}
                      >
                        {" "}
                        Count{" "}
                         
                      </Label>
                      */}
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="Delivered"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill="#6573c3"
                    />
                    <Bar
                      dataKey="Read"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill="#33ab9f"
                    />
                    <Bar
                      dataKey="Failed"
                      barSize={10}
                      radius={[10, 10, 0, 0]}
                      fill={red[500]}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              )}
            </div>
          </Grid>

          {/*  
                <Grid item xs={12}>  
 

                <div  className={classes.boxPadding}>

                <Typography variant="h5"> Sent/received messages statistic </Typography>
                {staticLoader?
                <div className={classes.centerP10}>
                <CircularProgress color="primary"  />
                </div>
                : 
                <ResponsiveContainer width="100%"   height={350}>
                              <ComposedChart
                            margin={{ top: 30, right: 10, left: 10, bottom: 5}}  
                                data={statics}
                              >
                                <CartesianGrid stroke="#f5f5f5" />
                                <YAxis tickLine={false} axisLine={{ stroke: "#f5f5f5" }}   > <Label angle={270} position="left"  style={{ textAnchor: 'middle' }}>   Count </Label></YAxis>
                                <XAxis    dataKey="date_value"  tickFormatter={(tick) => formatXAxis(tick)}  />
                                
                                <Line
                                  type="monotone"
                                  dataKey="Recevied message"
                                  stroke="#ff7300"
                                  strokeWidth={2}
                                  legendType="rect"
                                  dot={{
                                    stroke: "#ff7300",
                                    strokeWidth: 5,
                                    fill:  "#ff7300",
                                  }}
                                />
                                <Line
                                  type="monotone"
                                  dataKey="Sent message"
                                  stroke="#8884d8"
                                  strokeWidth={2} 
                                  legendType="rect"
                                  dot={{
                                    stroke: "#8884d8",
                                    strokeWidth: 5,
                                    fill:  "#8884d8",
                                  }}
                                />
                  <Tooltip labelFormatter={t => moment(t).format('DD-MM-YYYY')}  />
                <Legend />
                  
                              </ComposedChart>
                            </ResponsiveContainer>
                            }
                            </div>

                </Grid>
                */}
        </Grid>
      </div>
    </>
  );
}

// #######################################################################
