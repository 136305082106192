import React, { useEffect, useState } from "react";


import useThemeStyles from "../../themes/style.js";

import {
  Route,
  Switch,
  Redirect,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";

import { ToastContainer } from "react-toastify"; 
import { useTheme } from "@material-ui/styles";

// components
import Header from "../Header";
import Sidebar from "../Sidebar";
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
//import red from '@material-ui/core/colors/red';
import CloseIcon from "@material-ui/icons/Close";
 
 import Dashboard from "../../pages/dashboard"; 
 import Users from "../../pages/superAdmin/Users"; 
 import Transactions from "../../pages/superAdmin/Transaction";
 

 import Transaction from "../../pages/admin/Transaction";
import Instance from "../../pages/admin/Instance";
import Campaign from "../../pages/admin/Campaign"; 
 

import GroupContact from "../../pages/admin/GroupContact"; 
import Group from "../../pages/admin/Group";
import Contact from "../../pages/admin/Contact";
import SearchTag from "../../pages/admin/SearchTag";

import Referral from "../../pages/admin/Referral";

import ReplyMessages from "../../pages/admin/Reply"; 

import ManageSettings from "../../pages/admin/ManageSettings";

// context
import { useLayoutState } from "../../context/LayoutContext";
import useStyles from "./styles";

function Layout(props) {
  var classes = useStyles();
  var classesTheme = useThemeStyles();
  var theme = useTheme();

  // global
  var layoutState = useLayoutState();
  const user = JSON.parse(localStorage.getItem("user"));

 
 
  var [isPermanent, setPermanent] = useState(true);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
      handleWindowWidthChange();
      return function cleanup() {
        window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    // var breakpointWidth = theme.breakpoints.values.md
    var breakpointWidth = theme.breakpoints.values.sm 
    var isSmallScreen = windowWidth < breakpointWidth
     if (isSmallScreen && isPermanent) {
    setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
    setPermanent(true);
    }
    }

  const getMuiTheme = () => createTheme({

    overrides: {


      MuiCheckbox: {
        colorPrimary: {
          color: '#ddd',
          '&$checked': {
            color: '#ed1a3b',
          },
        },
      },
      
      MuiBox:{
        root:{
         padding: "0px",
        }
      },
      MuiFormControl: {
        root: {
          width: "100%",
        },
      },
      MuiAvatar:{
        root:{
          fontFamily: "'Spline Sans'"
        }
      },
      MuiMenuItem:{
        root:{
          fontFamily: "'Spline Sans'",
          fontSize: "15px",
        }
      },
      MuiTable:{
        root:{
          "& caption":{ fontFamily: "'Spline Sans' !important",
          }
        }
      },
      MuiInput:{
        root:{
          backgroundColor: "#ffffff",
          border: "1px solid #bec8d0",
          borderRadius: "8px !important",
          margin: "10px 0",
          "&:hover, &:focus": {
            borderColor: "#ed1a3b !important",
          },
        },
      
        underline:{
          "&:before":{
            borderBottom: "none !important"
          },
          "&:after":{
            borderBottom: "none !important"
          },
        }
      },
      MuiInputBase: {
        input: {
          width: "100%",
         // border: "none",
          fontSize: "15px",
          display: "block",
          padding: "15px 12px",
          borderRadius: "4px",
        },
        multiline: {
          padding: "15px 12px",
        },
      },
      MuiPaper: {
        elevation4: {
          boxShadow: "none", 
        },
        elevation1: {
          boxShadow: "none", 
        },
      },
      

      MuiTableSortLabel: {
        root: {
          position: "absolute",
          top: "21px",
        },
        icon: {
          color: "#ed1a3b !important"
        },
      },

      MUIDataTableSelectCell: {
        headerCell: {
          backgroundColor: "#113358",
        },
      },
      MUIDataTableHeadCell: {
        fixedHeader: {
          backgroundColor: "#113358",
        },
        sortActive: {
          color: "#ffffff",
        },
      },

      MuiTableCell: {
        head: {
          color: "#ffffff",
          fontSize: "14px",
          fontWeight: "500",
          letterSpacing: "0.02em",
          borderBottomColor: "#efefef",
          textTransform: "uppercase",
        },
        body: {
          color: "#121224",
          fontFamily: "'Spline Sans' !important",
          fontSize: "14.5px !important",
          borderBottomColor: "#efefef",
          "&:last-child": { whiteSpace: "nowrap",
          },
          root: {
            padding: "14px",
            fontFamily: "'Spline Sans' !important",
           
          },
        },
      },
      MUIDataTable:{
        responsiveBase:{
          //boxShadow: "0px 20px 20px 5px #2f3c4a14 !important"
        },
      },
      MuiAppBar:{
        colorPrimary:{
         backgroundColor: "transparent", 
         // backgroundColor: "#2196f3 !important", 
          
        },
         
      },
      MuiTab:{
        textColorPrimary:{
          "&$selected": {       
            color: "#006494", 
          },
        },
      },
      MuiTreeItem:{
        iconContainer:{
          width: "5px",
        }
      },
      MuiListItemAvatar:{
        root:{
         minWidth: "50px",
         
        }
      },
      MuiDialogTitle: {
        root: {
          
        }
      },
      MuiDialogActions:{
        root:{
          justifyContent: "center !important"
        }
      },

      MuiSvgIcon:{
      root:{
        width: "1em",
       height: "1em"
      }
      },


      MUIDataTableSearch: {
        main:{
          position: "relative",
          width: "260px",
          display: "inherit",
        },
        searchIcon: {
          position: "absolute",
          top: "12px",
          left: "2px",
          zIndex: "1",
          color: "rgb(0 0 0 / 30%)",
        },
        clearIcon: {
          display: "none",
        },
      },
     
      MUIDataTableToolbar: {
        root: {
          padding: "0px",
        },
        actions: {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
        },
        icon: {
         // color: "#064be2",
          color: "#2196f3",
          
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
            //backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },

        iconActive: {
          display: "none",
         // color: "#064be2",
          color: "#2196f3",
          "& svg": {
            color: "white",
            width: "25px",
            cursor: "pointer",
            height: "25px",
            padding: "5px",
            boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
            borderRadius: "100%",
           // backgroundColor: "#064be2",
            backgroundColor: "#2196f3",
          },
        },
      },
     
      MuiTypography: {
        subtitle1: {
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
        },
        body1: {
          fontWeight: "400",
          color: "#000000",
          fontFamily: "'Spline Sans' !important",
          fontSize: "15px",
        },
        body2: {
          color: "#121224",
          fontFamily: "'Spline Sans' !important",
          fontSize: "13px",
        }, 
        text: {
          fontFamily: "'Spline Sans' !important",
        },
        h1: {
          fontSize: "24px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        },
        h2: {
          fontSize: "22px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        },
        h3: { 
          fontSize: "20px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        },
        h4: {
          fontSize: "18px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        },
        h5: {
          fontSize: "16px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        }, 
        h6: {
          fontSize: "15px",
          fontWeight: "500",
          fontFamily: "'Spline Sans' !important",
          letterSpacing: "inherit",
        }, 
      },
      MuiAutocomplete: {
        popper: {
          boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
          backgroundColor: "#fff",
          borderRadius: "0 0 5px 5px",
        },
        input: {
          width: "100% !important",
          padding: "15px 12px !important",
        },
      },  
      MuiFilledInput: {
          root: {
            width: "100%",
            display: "block",
            padding: "0px 25px 0px 0px !important",
            position: "relative",
            fontSize: "13px",
            marginTop: "24px",
           
            backgroundColor: "white",
            "&:hover": { backgroundColor: "unset !important",
            },
            "&.Mui-focused": { backgroundColor: "unset !important",
            },
          },

           
        },
        MuiSelect:{
          select:{
           // padding: "10px !important",
          }
        },
        MuiButton: { 
          startIcon:{
            marginRight: "4px !important"
          },
          root:{ 
              textTransform: "initial !important",
              fontFamily: "'Spline Sans' !important",
              fontSize: "15px",
              fontWeight: 500,
              "@media (max-width:959.95px)": {
                fontSize: "10px !important",
              }, 
          },
          label: {
           // fontSize: "16px",
            "@media (max-width:959.95px)": {
             // fontSize: "10px !important",
            },
          },
          containedPrimary:{ 
            padding:  "12px 20px",
            backgroundColor: "#ed1a3b !important",   
            width: "100%",
            borderRadius: "8px",
            '&:active':{
              backgroundColor: "#c11732", 
            }, 
            '&:hover':{
              backgroundColor: "#c11732 !important", 
            },
            '&:disabled':{
             // backgroundColor: "#064be2c7 !important",
              backgroundColor: "#2196f3 !important", 
              color:"#e4e4e4 !important",
            }
        },
        contained:{ 
         
              '&:disabled':{
              
                color:"#e4e4e4 !important",
              }
            },
            containedSizeSmall:{
              padding:  "7px 15px",
              fontSize: "15px",
              fontWeight: "400",
              borderRadius: "8px",
              width: "auto",
            },
            containedSecondary:{ 
              color: "#000000",
              backgroundColor: "#f1f1f1",
              border: "1px solid #eeeeee",
              '&:active':{
                backgroundColor: "#e5e5e5",
              }, 
              '&:hover':{
                backgroundColor: "#e5e5e5",
              },
              '&:disabled':{ 
                color:"#e4e4e4 !important",
            }
            }, 
        },
      MuiFormLabel: {
        root: {
         fontFamily: "'Spline Sans' !important",
          fontSize: "19px",
          fontWeight: "500",
          color: "#000000",
          marginBottom: "-8px",
          "@media (max-width:959.95px)": { 
            fontSize: "15px !important",
          },
        },

      }, 
      MuiTooltip:{
        tooltip:{
          fontFamily: "'Spline Sans' !important",
        },
        // popper:{
        //   top: "-34px !important",
        //   left: '-45px !important'
        // }
      },
      MuiOutlined: {
        MuiChip: {
          avatar: { margin: "0px",
          },
        },
      },
      MuiListItemText:{
        root:{
          textWrap: "balance"
        }
      },
      MuiTabScrollButton:{
      root:{
        background: "white",
        color: "black",
        borderRadius: "30px",
        height: "40px",
        alignItems: "center",
        placeSelf: "center"
      }
      },
      PrivateSwipeArea:{
        root:{
          display: "none"
        }
      },
    },
  
  });

  return (

    <MuiThemeProvider theme={getMuiTheme()}>


    <div className={classes.root}>
        <>
        
        <div className={classes.fakeToolbar}> <Header history={props.history} />
            </div>
          <Sidebar />
          <div
            className={classnames(classes.content,  {
              [classes.contentShift]: isPermanent&&!layoutState.isSidebarOpened,
              [classes.contentShift1]: layoutState.isSidebarOpened,
            })}
          >
                
                <div className={classes.contentBefore}></div>
            
            
            <div className={classesTheme.siteContainer}>
            <Switch>
            {/* <Route path="/app/dashboard" role={user.role_code} roles={["SUPERADMIN", "ADMIN"]} component={Dashboard} /> */}
              <AuthRoute path="/app/users" role={user?.role_code} roles={["SUPERADMIN"]} component={Users} />  
              <AuthRoute path="/app/transactions" role={user?.role_code} roles={["SUPERADMIN"]} component={Transactions} /> 
              
              <Route path="/app/dashboard" role={user?.role_code} roles={["ADMIN"]} component={Dashboard} />  

              <AuthRoute path="/app/transaction" role={user?.role_code} roles={["ADMIN"]} component={Transaction} />  
             <AuthRoute path="/app/instance" role={user?.role_code} roles={["ADMIN"]} component={Instance} />  
              <AuthRoute path="/app/group" role={user?.role_code} roles={["ADMIN"]} component={Group} />  
              <AuthRoute path="/app/contact" role={user?.role_code} roles={["ADMIN"]} component={Contact} />  
              <AuthRoute path="/app/groupContact" role={user?.role_code} roles={["ADMIN"]} component={GroupContact} />    
              <AuthRoute path="/app/campaign" role={user?.role_code} roles={["ADMIN"]} component={Campaign} />  
             {/* <AuthRoute path="/app/tag_campaign" role={user?.role_code} roles={["ADMIN"]} component={CampaignTag} />   */}

              <AuthRoute path="/app/referral_transaction" role={user?.role_code} roles={["ADMIN"]} component={Referral} />  
              <AuthRoute path="/app/search_tag" role={user?.role_code} roles={["ADMIN"]} component={SearchTag} />   
              <AuthRoute path="/app/reply_messages" role={user?.role_code} roles={["ADMIN"]} component={ReplyMessages} />  
              <AuthRoute path="/app/managesettings" role={user?.role_code} roles={["ADMIN"]} component={ManageSettings} />

            </Switch>
             
          </div>
          </div>
        </>
    </div>

    <ToastContainer
        closeButton={
          <CloseButton className={classes.notificationCloseButton} />
        }
        closeOnClick={false}
        hideProgressBar={true}
      />

    </MuiThemeProvider>

  );
}

export default withRouter(Layout);

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
}

function AuthRoute({ component, role, roles = [], ...rest }) {

  return (
    <Route
      {...rest}
      render={(props) =>
        roles.includes(role) ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location, },
            }}
          />
        )
      }
    />
  );
}
