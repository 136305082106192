import React, { useState, useEffect } from "react";
import {
  Grid, Typography, Button, IconButton, InputAdornment, SwipeableDrawer, Box, List, 
   CircularProgress, TextField, Backdrop
} from "@material-ui/core";



import { withRouter, useHistory, Link } from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useStyles from "./styles"; 
import { useUserDispatch, authUser, superUser } from "../../context/UserContext";
import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
import { userLogin, forgetPassword } from '../../services/auth.api';
import CloseIcon from '@material-ui/icons/Close';
import { loginSchema, forgetSchema } from "../../components/Yup/Yup";
//  import { isPossiblePhoneNumber, parsePhoneNumber } from 'libphonenumber-js';
 import logo from '../../images/logo.png';


 import LoginImg from '../../images/loginImg.png';
  

import Notification from "../../components/Notification/Notification";
import jwt_decode from "jwt-decode";

//import {
//  loadCaptchaEnginge,
//  LoadCanvasTemplate,
 // validateCaptcha,
//} from 'react-simple-captcha';

import useGlobalStyles from "../../themes/style";

function Login(props) { 
  var globalClasses= useGlobalStyles(); 
  var classes = useStyles();
  var userDispatch = useUserDispatch();
  const [loader, setLoader] = useState(false);  
  const history = useHistory(); 
  // var [activeTabId, setActiveTabId] = useState(0); 
 
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress, 
          className: classes.notification,
        },
    );
  }
  // const [valid, setValid] = React.useState(false);


  useEffect(() => { 
   // loadCaptchaEnginge(6);
  }, []);
  
  // const [phoneNumber, setPhoneNumber] = React.useState('');
  // const [countryCode, setCountryCode] = React.useState('');

  // const handleChange = (values, allDetails) => { 
 
  //   var phone = values.replaceAll(/[- )(]/g,'');  
  //    setCountryCode("+"+allDetails.dialCode);
  //    setPhoneNumber(phone.replaceAll("+"+allDetails.dialCode,""));
  // };

  const [state, setState] = useState({ 
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const onLogin = useFormik({
    initialValues: { 
      email: '',
      password: '', 
     // captcha: '', 
    },
     validationSchema: loginSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
    {/* if (validateCaptcha(values.captcha) === true) { */}  
 
   //   setLoader(true);
      userLogin(values).then((response) => {    
        if (response.data.status === true) { 

      // localStorage.setItem('token', response.data.token);

       const decode = jwt_decode(response.data.token); 
       

 
       if(decode.role_code === "SUPERADMIN") {

        
          
        superUser(response.data.token, userDispatch, history);
        resetForm(); 
        setSubmitting(false); 
      //  setLoader(false);
        history.push('/app/users');    
        
      } else{
      
     
        authUser(response.data.token, userDispatch, history);
              resetForm(); 
              setSubmitting(false); 
            //  setLoader(false);  
        history.push('/app/dashboard');    

      }

      
      } else {  
        handleNotificationCall("error", response.data.message);  
        //loadCaptchaEnginge(6);
        onLogin.setValues({ 
          "email":   onLogin.values.email,
          "password":  onLogin.values.password, 
         // "captcha": ""
        })
       // setLoader(false);
        setSubmitting(false); 
       }
      
     }) 
     .catch(function (error) {
      console.log(error);
    })

    {/* } else {
          setSubmitting(false); 
        //  setLoader(false);
          loadCaptchaEnginge(6);
          handleNotificationCall("error", "Invalid Captcha");
          onLogin.setValues({ 
            "email":   onLogin.values.email,
            "password":  onLogin.values.password, 
            "captcha": ""
          })
        } */}

    },
    
  });  

 
  const onForget = useFormik({
    initialValues: { 
      email: '', 
    },
     validationSchema: forgetSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  
      
      setLoader(true);
      forgetPassword(values).then((response) => {    
      if (response.data.status === true) { 
        setState({ ...state, "right": false});  
        handleNotificationCall("success", response.data.message);  
        resetForm();
       } else {  
        handleNotificationCall("error", response.data.message);  
        
       }
       setSubmitting(false); 
       setLoader(false);
     }) 
     .catch(function (error) {
      console.log(error);
    }) 

    },
    
  }); 

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
   
  
  const list = (anchor) => (

     
       <Box sx={{ width: '100%' }} role="presentation"  >
            <List>

              <Card className={classes.root} >

                <CardHeader >


                  <Grid container direction="row" spacing={1} className={globalClasses.drawerSmHeader}>

                    <Grid item xs={6}  >   <Typography variant="subtitle1">  Forgot Password  </Typography></Grid>

                    <Grid item xs={6} className={globalClasses.drawerClose}>
                      <CloseIcon className={globalClasses.closeBtn} size="14px" onClick={toggleDrawer(anchor, false)} />

                    </Grid>
                  </Grid>
                </CardHeader >
                <form onSubmit={onForget.handleSubmit}  > 
                <CardContent className={globalClasses.drawerContent}> 
                    <Grid container direction="row" spacing={2}>
                      <Grid item xs={12}   >
                      <TextField
                    id="email"
                    InputProps={{
                      classes: {
                        underline: classes.textFieldUnderline,
                        input: classes.textField,
                      },
                    }}
                    name="email"
                    margin="normal"
                    placeholder="Enter Email"
                    type="email"
                    fullWidth
                    value={onForget.values.email}
                    onChange={onForget.handleChange}
                    classes={{ root: globalClasses.loginTextField }}
                    error={onForget.touched.email && Boolean(onForget.errors.email)}
                    helperText={onForget.touched.email && onForget.errors.email}
    
                  />

                      </Grid>

                    </Grid>

                  </CardContent>
                  <CardActions>


                    <Grid container direction="row" spacing={2} className={globalClasses.drawerSmFooter}>

                      
                      <Button variant="contained" size="small"   color="secondary" onClick={toggleDrawer(anchor, false)}   >  Close  </Button>
                      <Button   variant="contained" color="primary" type="submit" size="small" >    Send   </Button>
                    
                    </Grid>

                  </CardActions>
                </form>
              </Card>

            </List>

        </Box> 
     
  );

  return (
    <Grid container className={classes.container}>
         

    <div className={classes.loginInner}>

        <div className={classes.loginLeft}>
          <img src={LoginImg} />
        </div>
      
        <div className={classes.loginRight}>

          
          <div className={classes.loginForm}>
            

            <div className={classes.loginHead}>
                <img src={logo} alt="kadigaa"   />

                <br />

                <Link to="/home">Back to Home</Link>

                <Typography variant="h3" gutterBottom>
                  Login with your email
                </Typography>
            </div>    

          {/* <Tabs
              value={activeTabId}
              onChange={(e, id) => setActiveTabId(id)}
              indicatorColor="primary"
              textColor="primary"
              centered
              style={{ paddingBottom: "20px"}}
            >
              <Tab label="Login" classes={{ root: classes.tab }} />
              <Tab label="New User" classes={{ root: classes.tab }} />
            </Tabs> */}
            {/* {activeTabId === 0 && (
            <React.Fragment>
  
        <form onSubmit={onLogin.handleSubmit}  >
                <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      name="email"
                      margin="normal"
                      placeholder="Enter Email"
                      type="email"
                      fullWidth
                      value={onLogin.values.email}
                      onChange={onLogin.handleChange}
                      
                      error={onLogin.touched.email && Boolean(onLogin.errors.email)}
                      helperText={onLogin.touched.email && onLogin.errors.email}
      
                    />
          
      
                    <TextField
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        }, 
                        endAdornment: <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      }}
                      name="password"
                      margin="normal"
                      placeholder="Password"
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
      
                      value={onLogin.values.password}
                      onChange={onLogin.handleChange}
                      
                      error={onLogin.touched.password && Boolean(onLogin.errors.password)}
                      helperText={onLogin.touched.password && onLogin.errors.password}
                    />
      
      
                    <div className={classes.formButtons}>
                      {loader ? (
                        <CircularProgress size={26} className={classes.loginLoader} />
                      ) : (
                        <Button
                        type="submit"
                        variant="contained"
                          color="primary"
                          size="small"
                          disabled={onLogin.isSubmitting}
                          
                        >
                          Login
                        </Button>
                      )}
                      <Button
                        color="primary"
                        size="large"
                        className={classes.forgetButton}
                      >
                        Forget Password
                      </Button>

                      </div>
      
                      </form>
                  </React.Fragment>
            )}
            {activeTabId === 1 && (
              <React.Fragment>
                    <form onSubmit={onRegister.handleSubmit}  >

                <Grid container spacing={3}>
          <Grid item xs={6}>
        
          <TextField
                      id="firstName"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      name="firstName"
                      margin="normal"
                      placeholder="Enter First Name"
                      type="text"
                      fullWidth
                      value={onRegister.values.firstName}
                      onChange={onRegister.handleChange}
                      
                      error={onRegister.touched.firstName && Boolean(onRegister.errors.firstName)}
                      helperText={onRegister.touched.firstName && onRegister.errors.firstName}
      
                    />

          </Grid>

          <Grid item xs={6}>
          <TextField
                      id="lastName"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      name="lastName"
                      margin="normal"
                      placeholder="Enter Last Name"
                      type="text"
                      fullWidth
                      value={onRegister.values.lastName}
                      onChange={onRegister.handleChange}
                      
                      error={onRegister.touched.lastName && Boolean(onRegister.errors.lastName)}
                      helperText={onRegister.touched.lastName && onRegister.errors.lastName}
      
                    />
          </Grid>


              </Grid>

            
              <TextField
                      id="email"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      name="email"
                      margin="normal"
                      placeholder="Enter Email"
                      type="email"
                      fullWidth
                      value={onRegister.values.email}
                      onChange={onRegister.handleChange}
                      
                      error={onRegister.touched.email && Boolean(onRegister.errors.email)}
                      helperText={onRegister.touched.email && onRegister.errors.email}
      
                    />
          
      
                    <TextField
                      id="password"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                        endAdornment: <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                          {values.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                      }}
                      name="password"
                      margin="normal"
                      placeholder="Password"
                      type={values.showPassword ? 'text' : 'password'}
                      fullWidth
      
                      value={onRegister.values.password}
                      onChange={onRegister.handleChange}
                      
                      error={onRegister.touched.password && Boolean(onRegister.errors.password)}
                      helperText={onRegister.touched.password && onRegister.errors.password}
                    />

                        
                      
                      <MuiPhoneNumber
                        
                        value={countryCode+""+phoneNumber}
                        id="mobile"
                        defaultCountry={"in"}
                        style={{ width: "100%" }}
                      onChange={(val, allDetails) => { 
                          handleChange(val, allDetails); 
                      }}
                      onBlur={(e) => { 
                        isValidPhoneNumberWithCountryCode(countryCode+" "+phoneNumber)
                      
                      }}
                        margin="normal"
                        name="mobile"
                        error={phoneNumber ===""? onRegister.touched.mobile && Boolean(onRegister.errors.mobile):!valid? "Not a valid Mobile Number":""}
                        helperText={phoneNumber ===""?  onRegister.touched.mobile && onRegister.errors.mobile:!valid? "Not a valid Mobile Number":""}
                      
                      />

  <TextField
                      id="address"
                      InputProps={{
                        classes: {
                          underline: classes.textFieldUnderline,
                          input: classes.textField,
                        },
                      }}
                      name="address"
                      margin="normal"
                      placeholder="Enter address"
                      
                      fullWidth
                      value={onRegister.values.address}
                      onChange={onRegister.handleChange}
                      
                      error={onRegister.touched.address && Boolean(onRegister.errors.address)}
                      helperText={onRegister.touched.address && onRegister.errors.address}
      
                    />
          
      
                <div className={classes.creatingButtonContainer}>
                  {loader ? (
                    <CircularProgress size={26} />
                  ) : (
                    <Button
                    type="submit"
                    
                      size="small"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.createAccountButton}
                      disabled={!valid? !valid : onRegister.isSubmitting}
                    >
                      Create your account
                    </Button>
                  )}
                </div>
                </form>
          
              </React.Fragment>
            )} */}


  <React.Fragment>
  
  <form onSubmit={onLogin.handleSubmit}  >
          <TextField
                id="email"
                  
                name="email"
                placeholder="Enter Email"
                type="email"
                value={onLogin.values.email}
                onChange={onLogin.handleChange}
                
                error={onLogin.touched.email && Boolean(onLogin.errors.email)}
                helperText={onLogin.touched.email && onLogin.errors.email}

              />
    

              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  }, 
                  endAdornment: <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                }}
                name="password"
                placeholder="Password"
                type={values.showPassword ? 'text' : 'password'}
                fullWidth
                value={onLogin.values.password}
                onChange={onLogin.handleChange}
                
                error={onLogin.touched.password && Boolean(onLogin.errors.password)}
                helperText={onLogin.touched.password && onLogin.errors.password}
              />


                {/*
                  <div  style={{ textAlign: "left", }}>
                    <LoadCanvasTemplate />
                    </div> 
                  
                  <TextField style={{  }}
                id="captcha"
                InputProps={{ 
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                name="captcha"
                margin="normal"
                placeholder="Enter captcha"
                type="captcha"
                fullWidth
                value={onLogin.values.captcha}
                onChange={onLogin.handleChange}
                
                error={onLogin.touched.captcha && Boolean(onLogin.errors.captcha)}
                helperText={onLogin.touched.captcha && onLogin.errors.captcha}

              />

               */}
  

              <div className={classes.formButtons}>


                  <div className={classes.forgetButton}>
                      <Typography
                        onClick={(e) => {
                          setState({ ...state, "right": true});  
                          onForget.resetForm();
                        }} > 
                        Forgot Password
                      </Typography>
                  </div>

                  {onLogin.isSubmitting ? (
                    <CircularProgress size={26} className={classes.loginLoader} />
                  ) : (
                    <Button className={classes.submitButton}
                    type="submit"
                    variant="contained"
                      color="primary"
                        disabled={onLogin.isSubmitting}
                        
                    >
                      Login
                    </Button>
                  )}




                  <div className={classes.loginAccount}>
                    <p>Don't have an Account?</p>
                    <Link to="/register">
                      Create Account
                    </Link>
                  </div>
                    
                  

                  <SwipeableDrawer
                    anchor="right"
                    open={state["right"]}
                    onClose={toggleDrawer("right", false)}
                    onOpen={toggleDrawer("right", true)}
                    classes={{ paper: globalClasses.smallDrawer }}
                  >
                    {list("right")}

                  </SwipeableDrawer>

                </div>

                </form>
            </React.Fragment>
          </div>
          
        </div>
    
    
    </div>


  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Login);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 