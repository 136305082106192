import { makeStyles } from "@material-ui/styles";
 export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
        width: '100%',
        border: "1px solid #f5f5f5"
      },
    verticalAlign:{
        display:"flex",
        alignItems:"center",
    },
    userDetails:{
        color: "#fff",
        display:"flex",
        flexDirection:"column",
        "& p":{
            color: "#fff",
        }
    },
    bannerBtn:{
        display:"flex",
        justifyContent:"flex-end",
        gap:"20px",
        [theme.breakpoints.down("md")]: {
            justifyContent:"center",
        },
    },
    tabList:{
        backgroundColor: "#f1f1f1",
        padding: "20px 20px",
        marginBottom: "15px",
        borderRadius: "15px",
        "& .Mui-selected":{
            backgroundColor: "#ed1a3b",
            color: "#ffffff",
        },
        "& button":{
            color: "#000000",
            fontFamily: "'Spline Sans' !important",
            textTransform:"uppercase",
            minHeight: "38px",
            minWidth: "auto",
            borderRadius: "8px",
            padding: "11px 20px",
            opacity: "1",
            fontSize: "14px",
            "& span":{
                flexDirection:"row-reverse !important",
                columnGap:"5px",
                alignItems:"center" 
            }
        },
        "& svg": {
            display: "none",
        },

       
        [theme.breakpoints.down("md")]:{
            marginTop:"15px"
        },

       
    },
    tempTabList:{
        "& button":{
            textTransform:"capitalize",
            minHeight:"0px",
            "& span":{
                flexDirection:"row-reverse !important",
                columnGap:"5px",
                alignItems:"center"
            }
        },
        
    },
    tabsTitle:{
       // background:"#064be2",
        background:"#2196f3",
        color:"White",
        padding:"12px",
        borderRadius:"16px 16px 0px 0px",
    },
    p25:{
        padding:"20px"
    },
    tabsFooter:{
        padding:"10px 0px",
        display:"flex",
        justifyContent:"center",
        "& button":{
            width: "auto",
            minWidth: "100px",
            //color:"#fff",
            //backgroundColor:"#4caf50",
            "&:hover":{
               // color:"#fff",
               // backgroundColor:"#4caf50", 
            }
        },
        [theme.breakpoints.only("xs")]: {
            padding:"10px !important",
          },

    },
    tabsBody:{
        display:'flex',
        flexDirection:"column",
        rowGap:'50px',
    },
    tabsContainer:{
        border: "1px solid #ededed",
        borderRadius:"16px",
       [theme.breakpoints.down("xs")]: { 
        marginTop: "10px"
    },
    },
    listItemCenter:{
        "& span":{
            display:"flex",
            alignItems:"center",
        }
    },
    listContainer:{
        padding:0,
        "& :nth-child(even)":{
            backgroundColor: "#cbe8ff !important", //new change f5f5f5
        },
        "& li":{
            borderBottom: "1px solid #d1d9fd",
        }
    },
    tempListContainer:{
        padding:0,
        "& li":{
            borderBottom: "1px solid #d1d9fd",
            "&:hover":{
                backgroundColor: "#cbe8ff !important", //new change f5f5f5
                cursor:'default',
            }
        }
    },
    TabPanelList:{
        "& div":{
            padding:"0px",
        }
    },
    countCircle:{
        borderRadius:"50px",
        padding:"0px 8px",
    },
   
    gray:{
        backgroundColor:"rgb(121 85 72 / 30%)",
        color:"#795548",
    },
    blue:{
        backgroundColor:"rgb(33 150 243 / 30%)",
        color:"#0d93fd",
    },
    green:{
        backgroundColor:"rgb(76 175 80 / 30%)",
        color:"#4caf50",
    },
    orange:{
        backgroundColor:"rgb(255 87 34 / 30%)",
        color:"#ff5722",
    }, 
    marginBorder:{
        borderLeft:"2px solid #dfe1e5", 
        margin: "10px"
    },
    mapContainer: {
        height: "100%",
        
      },

      formFooter: {
        display: 'flex',
        justifyContent: 'end',
        gap: "10px", 
        borderTop: "1px solid #aeaa",
        marginTop: "50px",
      },


      card:{ 
        boxShadow: "0 .125rem .25rem rgba(0,0,0,.075)!important",
    backgroundColor: "#fff",
    backgroundClip: "border-box",
    border: "1px solid rgba(0,0,0,.125)",
    borderRadius: "0.25rem"
      },
      cardHeader:{ 
         
        alignItems: "center",
        padding: "0.75rem 1.25rem",
        marginBottom: "0",
        backgroundColor: "rgba(0,0,0,.03)",
        borderBottom: "1px solid rgba(0,0,0,.125)",
        fontSize: "14px",

      },

     heroContent:{
        borderBottom: "1px solid rgba(0,0,0,.125)",
     },

      cardContent:{
        height: "150px",
      },

  a:{
    textDecoration:"none",
    color:"white"
  },

  profileMain: {
    padding: "30px 0px",
  },

profileHead:{
    
    margin: "0px",
    width: "100%",
    height: "100px",
    
    [theme.breakpoints.only("xs")]: {
        height: "120px !important"
      },
},
  
profileBody:{
    alignItems: "center", 
    display: "flex",
    gap: "10px",
    [theme.breakpoints.only("xs")]: {
        marginLeft: "8px",
        gap: "25px",
    },
},

profileBodys:{
    alignItems: "center", 
    display: "flex",
    gap: "10px",
    [theme.breakpoints.only("xs")]: {
        marginLeft: "8px",
        gap: "25px",
    },
},

iframe:{
"& iframe":{
    width: "100%",
    height: "500px"
}
},

textWrap:{
    textWrap: "wrap"
},

 



}));
