 
import { api } from "./api";
 
 
export const message_rates = async (data) => {
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
      const response = await api.post("analytics/message_rates", {
        instance_id: data.instance_id,
        fromDate: data.fromDate,
        toDate: data.toDate,
      });
      return response;
    };
  

    export const total_messages = async (data) => {
        api.defaults.headers.common['Authorization'] = data.token;
        api.defaults.headers.common['Content-Type']= 'application/json'
          const response = await api.post("analytics/total_messages", {
            instance_id: data.instance_id,
            fromDate: data.fromDate,
            toDate: data.toDate,
          });
          return response;
        };
      

        
        
          export const message_statistics = async (data) => {
        api.defaults.headers.common['Authorization'] = data.token;
        api.defaults.headers.common['Content-Type']= 'application/json'
          const response = await api.post("analytics/message_statistics", {
            instance_id: data.instance_id,
            fromDate: data.fromDate,
            toDate: data.toDate,
          });
          return response;
        };
      