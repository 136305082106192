import { api } from "./api";

 
export const addCampaign = async (data) => {
   
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'

    const response = await api.post("campaign/addCampaign", {
      instance_id :  data.instance_id, 
      campaign_name: data.campaign_name, 
      template_id: data.template_id,
      group_id: data.group_id, 
      message: data.message, 
      vars: data.vars,
      headerType: data.headerType,
      fileLink: data.header,
      schedule: data.schedule,
      type: data.type,
    });
    return response;
  };
 

export const viewCampaign = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/viewCampaign", {
      id: data.id,
    });
    return response;
  };

  export const viewAllCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    
     try { 
      const response = await api.post('campaign/viewAllCampaigns', {}); 
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };

  export const rerunCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/rerunCampaign", { 
      campaign_id: data.campaign_id,  
    });
    return response;
  };


  export const campaignByTag = async (data) => {
   
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
  
      const response = await api.post("campaign/campaignByTag", {
        instance_id :  data.instance_id, 
        campaign_name: data.campaign_name, 
        template_id: data.template_id,
        tag_id: data.tag_id, 
        message: data.message, 
        vars: data.vars,
        headerType: data.headerType,
        fileLink: data.header,
        schedule: data.schedule,
        type: data.type,
      });
      return response;
    };
    
export const viewTagCampaign = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/viewTagCampaign", {
        id: data.id,
    });
    return response;
  };

  export const viewAllTagCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    
     try { 
      const response = await api.post('campaign/viewAllTagCampaign', {}); 
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };

  export const rerunTagCampaign = async (data) => { 
    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post("campaign/rerunTagCampaign", { 
      campaign_id: data.campaign_id,  
    });
    return response;
  };



  
 export const viewCampaignMessageStatus = async (data) => {
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  try { 
   const response = await api.post('campaign/viewCampaignMessageStatus', {
    id: data.id,
    page: data.page
    });
   return response;  
 } catch (err) {
    console.error(err.message);
 } 
};






export const removeGroupCampaignNumber = async (data, value) => { 
   api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("campaign/removeGroupCampaignNumber", { delete_data: data});
  return response;
};


export const removeTagCampaignNumber = async (data, value) => { 
  api.defaults.headers.common['Authorization'] = value.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("campaign/removeTagCampaignNumber", { delete_data: data });
  return response;
};



export const editScheduledCampaignTime = async (data) => { 
  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
  const response = await api.post("campaign/editScheduledCampaignTime", { 
    id: data.id,
    schedule:data.schedule });
  return response;
}; 