import { otherapi } from "./otherapi";

export const viewAllPlans = async (data) => {
  otherapi.defaults.headers.common['Authorization'] = data.token;
  otherapi.defaults.headers.common['Content-Type']= 'application/json'
     try { 
       
      const response = await otherapi.post('payment/viewAllPlans', { 
        
       });    
      return response;

    } catch (err) {
       console.error(err.message);
    }
   
  };