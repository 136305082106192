import React from "react";

import { withRouter } from "react-router-dom";

import useStyles from "../../themes/style.js";

import { Typography, Link } from "@material-ui/core";

import PageTitle from "../../components/PageTitle/LoginPageTitle.js";
import LoginHeader from "../../components/Login/LoginHeader.js";
import LoginFooter from "../../components/Login/LoginFooter.js";

import ContactUsImg from "../../images/contactUsImg.png";

import { CiHeadphones, CiMail } from "react-icons/ci";

function ContactUsPage() {
  const classes = useStyles();

  return (
    <>
      <LoginHeader />

      <div className={classes.siteContainer}>
        <PageTitle title="Contact Us" />

        <div className={classes.contentPage}>
          <div className={classes.contactUs}>
            <div className="contactUsLeft">
              <img src={ContactUsImg} alt="Contact Us Kadigaa" />
            </div>
            <div className="contactUsRight">
              <Typography variant="h1">Contact Us</Typography>
              <Typography variant="h2">for any problem</Typography>

              {/*<div className='contactUsLink'>
                                    <Link href="tel:+91 9876543210"> <CiHeadphones /> +91 9876543210</Link>
                                </div> */}
              <div className="contactUsLink">
                <Link href="mailto:info@kadigaa.com">
                  {" "}
                  <CiMail /> info@kadigaa.com
                </Link>

                <br />
                <br />


                <Link href="mailto:reachkadigaa@gmail.com">
                  {" "}
                  <CiMail /> reachkadigaa@gmail.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <LoginFooter />
    </>
  );
}

export default withRouter(ContactUsPage);
