import { api } from "./api";
 
export const saveTemplates = async (data) => {
  var url= "templates/saveTemplates"; 

  api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
        instance_id: data.instance_id, 
    });
    return response;
  }; 
  
  export const viewAllTemplates = async (data) => {
    var url= "templates/viewAllTemplates"; 

    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
        instance_id: data.instance_id, 
    });
    return response;
  };


  export const viewApprovedTemplates = async (data) => {
    var url= "templates/viewApprovedTemplates"; 

    api.defaults.headers.common['Authorization'] = data.token;
    api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
        instance_id: data.instance_id, 
    });
    return response;
  };
 
  export const viewTemplate = async (data) => {
    var url= "templates/viewTemplate"; 

    api.defaults.headers.common['Authorization'] = data.token;
  api.defaults.headers.common['Content-Type']= 'application/json'
    const response = await api.post(url, {
      id: data.id, 
        instance_id: data.instance_id, 
    });
    return response;
  };


 