import React from "react";
import { Grid, Paper, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
 import Lottie from "lottie-react";
import animation from '../../images/404.json';

// styles
import useStyles from "./styles";
 
export default function Error() {
  var classes = useStyles();

  return (
    <Grid container className={classes.container}>
       
      <Paper classes={{ root: classes.paperRoot }}>
      <Lottie animationData={animation} loop={true} style={{ width: "140%"}}  />
        <Button
          
          variant="contained"
          color="primary"
          component={Link}
          to="/"
          size="small"
         
        >
          Back to Home
        </Button>
      </Paper>
    </Grid>
  );
}
