import React, { useState, useRef   } from "react";
 import {
  Grid,
  Button,
  FormControl,
  InputAdornment,
  Typography, 
  Backdrop,
  CircularProgress, 
  TextField,
  IconButton, 
} from "@material-ui/core";
 
import useStyles from "../../themes/style.js";
 
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Notification from "../../components/Notification/Notification.js"; 
import { ToastContainer, toast } from "react-toastify";
import QRCode from 'qrcode.react';
import { linkGenerateSchema } from "../../components/Yup/Yup.js";
import { useFormik } from 'formik';
import PhoneInput from "react-phone-input-2";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import CloseIcon from '@material-ui/icons/Close';

import "react-toastify/dist/ReactToastify.css";

export default function Links(props) {
 
  const classes = useStyles();
   const [loader, setLoader] = useState(false);
   const qrCodeRef = useRef(null);
   const [text, setText] = useState('');

   const handleDownload = () => {
    const canvas = qrCodeRef.current.querySelector('canvas');
    const downloadLink = document.createElement('a');
    downloadLink.href = canvas.toDataURL('image/png');
    downloadLink.download = 'qrcode.png';
    downloadLink.click();
  };

   
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }

 
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [countryCode, setCountryCode] = React.useState('');

  const handleChange = (values, allDetails) => { 
    setCountryCode(allDetails.dialCode);
     setPhoneNumber(values);
  };


  const onLinkGenerate = useFormik({
    initialValues: { 
      to:'',
      message: '', 
    },
     validationSchema: linkGenerateSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {   
      setLoader(true);

     setText("https://api.whatsapp.com/send?phone="+values.to+"&text="+values.message); 
     setSubmitting(false);
     setLoader(false);
    // resetForm();

    onLinkGenerate.setValues({     
      "to": countryCode,  
      "message": " ",  
    })

    },
  });  


  //  function copyText(text){
  //   copyTextToClipboard(text)
  //   .then(() => { 
  //     handleNotificationCall("success", "Copied Successfully");
  //   })
  
  // }  
 
  // function copyTextToClipboard(text) {
  //   if ('clipboard' in navigator) {
  //     return navigator.clipboard.writeText(text);
  //   } else {
  //     return document.execCommand('copy', true, text);
  //   }
  // }

   return (
    <>
      <Grid container spacing={2}  style={{ marginTop: "50px"}} >
      <Grid item xs={2}> </Grid>
      <Grid item xs={8}> 

      <Grid container spacing={2} className={classes.center} >
      <Grid item xs={12}>
        <Typography variant="h4"> Free Whatsapp Link Generator </Typography>
        <Typography variant="body"> Create WhatsApp link with your number and message, and share it with your audience on your Social Networks!</Typography>
     </Grid>  
     </Grid> 
      <Grid container spacing={2}   style={{ marginTop: "50px", gap: "10px", justifyContent:"center"}}   >
       
     
<Grid item xs={5}>
     


<Grid container direction="row" spacing={2}>

<form   onSubmit={onLinkGenerate.handleSubmit} style={{ width: "100%",   padding: "20px", border: "1px solid #2b374412"}}     >
 <Grid item xs={12} > 

 <PhoneInput
      country={"in"}
      id="to"
      enableSearch={true}
      value={phoneNumber}
      classes={{ root: classes.customTextField }}
       
    onChange={(val, allDetails) => {
      handleChange(val, allDetails);
  }}
  onBlur={(e) => { 
    onLinkGenerate.setValues({     
      "to": countryCode+phoneNumber,  
      "message": onLinkGenerate.values.message,  
    })
     
   }}
   margin="normal"
   name="to"
   variant="outlined"
 
    />
    <Typography variant="inherit" color="error">{ phoneNumber === ""?  onLinkGenerate.touched.to && onLinkGenerate.errors.to :""}</Typography>

{/* 
<TextField

label="To" 
name="to"
placeholder="Enter to"
type="number"
fullWidth
margin="normal"
InputLabelProps={{
  shrink: true,
  required:  true,
  }}
  InputProps={{
    classes: {
      underline: classes.textFieldUnderline,
      input: classes.textField,
    },
  }}
  classes={{ root: classes.loginTextField }}
  value={onLinkGenerate.values.to}
   onChange={onLinkGenerate.handleChange}
  error={onLinkGenerate.touched.to && Boolean(onLinkGenerate.errors.to)}
  helperText={onLinkGenerate.touched.to && onLinkGenerate.errors.to} 
  /> */}


</Grid>



<Grid item xs={12}  > 

<FormControl className={classes.margin}>
<TextField 
multiline
maxRows={2}
rows={2} 
label="Message"
name="message"
placeholder="Enter message"
fullWidth
margin="normal"
InputLabelProps={{
  shrink: true,
  required:  true,
  }}
  InputProps={{
    classes: {
      underline: classes.textFieldUnderline,
      input: classes.textField,
    },
  }}
  classes={{ root: classes.loginTextField }}
  value={onLinkGenerate.values.message}
   onChange={onLinkGenerate.handleChange}
  error={onLinkGenerate.touched.message && Boolean(onLinkGenerate.errors.message)}
  helperText={onLinkGenerate.touched.message && onLinkGenerate.errors.message} 
/> 

</FormControl>
</Grid>

<Grid item xs={12} > 

<Button
variant="contained"
color="primary"
size="small"
 type="submit"
 
  >
Generate WhatsApp Link
</Button>

</Grid>
</form>
</Grid>

  

</Grid> 

<Grid item xs={5}>

<Grid container direction="row" spacing={2}>

<form   style={{ width: "100%",  padding: "20px", border: "1px solid #2b374412"}}     >

<Grid item xs={12} > 

 <TextField 
label="Your Whatsapp Link" 
name="link"
// placeholder="Enter to"
fullWidth
margin="normal"
InputLabelProps={{
shrink: true,
}}
value={text}
InputProps={{
  classes: {
    underline: classes.textFieldUnderline,
    input: classes.textField,
  },
  endAdornment: <InputAdornment position="end">
    {text && (
  <IconButton aria-label="toggle password visibility"   >
  <CopyToClipboard text={text}   >
    <FileCopyIcon  style={{ color: "blue", fontSize: "20px",  cursor: "pointer"}}  onClick={() =>  handleNotificationCall("success", "Copied Successfully")} />
  </CopyToClipboard>
  </IconButton>)}
</InputAdornment>
}}
 

classes={{ root: classes.loginTextField }}
  />

  

</Grid>



 

<Grid item xs={12} className={classes.center} style={{     display: "grid"}}> 

{text && (
        <div ref={qrCodeRef}>
          <QRCode value={text} />
        </div>
      )}
      {text && (
       
        <Button
variant="contained"
color="primary"
size="small" 
onClick={handleDownload}
  >
Download QR
</Button>
      )}





</Grid>

</form>
</Grid>
 


</Grid> 
   </Grid>
      
      </Grid>
      <Grid item xs={2}> </Grid>

      </Grid>
     
     
    

 
      

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />

    </>
  );
}

function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 