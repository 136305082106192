import React, { useState } from "react";
import {
  Grid, Typography, Button, IconButton, InputAdornment,
  Backdrop, CircularProgress, TextField,   InputLabel,  Avatar, 
} from "@material-ui/core";
import { withRouter, useHistory, Link } from "react-router-dom";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import useStyles from "./styles"; 
 import { ToastContainer, toast } from 'react-toastify';
import { useFormik } from 'formik';
 import CloseIcon from '@material-ui/icons/Close'; 
import { changePassword } from '../../services/auth.api';  
import { forgetPasswordSchema } from "../../components/Yup/Yup";
 import Notification from "../../components/Notification/Notification";
import useGlobalStyles from "../../themes/style";
import { FcBusinessman } from "react-icons/fc";
import logo from '../../images/logo.png';


import ForgetImg from '../../images/ForgetImg.png';

function Forget(props) { 
  var classes = useStyles();
   const [loader, setLoader] = useState(false);  
   var globalClasses= useGlobalStyles(); 
   const history = useHistory(); 
  const [values, setValues] = React.useState({ 
    showPassword: false,
    showConfirmPassword: false
  });
 
  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };
  const handleClickConfirmPassword = () => {
    setValues({ ...values, showConfirmPassword: !values.confirm });
  }; 
  
  function handleNotificationCall(type, message){
    return toast(
      <Notification
          {...{
            type: type,
            message: message,
            variant: "contained",
            color: type,
          }}
          className={classes.notificationComponent}
        />,
        {
          type: type,
          position: toast.POSITION.TOP_RIGHT,
          progressClassName: classes.progress,
          className: classes.notification,
        },
    );
  }


  const onChangePassword = useFormik({
    initialValues: {   
      password:  '',  
      confirm: '',  
    },
     validationSchema:  forgetPasswordSchema,
     onSubmit: (values, { setSubmitting, resetForm }) => {  

      if (values.password === values.confirm) {

      setLoader(true);
      changePassword({ verify_id: props.match.params.id, password: values.password }).then((response) => {    
        if (response.data.status === true) {
        handleNotificationCall("success", response.data.message);  
         
        resetForm();
        setTimeout(() => {
          history.push('/login');
       }, 2000);
      
       } else {  
        handleNotificationCall("error", response.data.message);  
       }
       setSubmitting(false);
       setLoader(false);
     })
     .catch(function (error) {
      console.log(error);
    })
  } else{
    handleNotificationCall("error", "Password is mismatch");
   
  }
    },

   
  });  

     

  return (
    <Grid container className={classes.container}>

        <div className={classes.loginInner}>

            <div className={classes.loginLeft}>
              <img src={ForgetImg} />
            </div>


            <div className={classes.loginRight}>

                <div className={classes.loginForm}>


                    <div className={classes.loginHead}>
                        <img src={logo} alt="kadigaa"   />

                        <br />

                        <Link to="/home">Back to Home</Link>

                        <Typography variant="h3" gutterBottom>
                          Forget Password
                        </Typography>
                    </div>




                    <React.Fragment>
    
                    <form onSubmit={onChangePassword.handleSubmit}  >


                        <TextField
                          id="password"
                          InputProps={{
                            classes: {
                              underline: globalClasses.textFieldUnderline,
                              input: globalClasses.textField,
                            }, 
                            endAdornment: <InputAdornment position="end">
                            <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}   >
                              {values.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                          }}
                          name="password"
                          placeholder="New Password"
                          type={values.showPassword ? 'text' : 'password'}
                          value={onChangePassword.values.password}
                          onChange={onChangePassword.handleChange}
                          
                          error={onChangePassword.touched.password && Boolean(onChangePassword.errors.password)}
                          helperText={onChangePassword.touched.password && onChangePassword.errors.password}
                        />

                        <TextField
                            id="confirm"
                            InputProps={{
                              classes: {
                                underline: globalClasses.textFieldUnderline,
                                input: globalClasses.textField,
                              }, 
                              endAdornment: <InputAdornment position="end">
                              <IconButton aria-label="toggle password visibility" onClick={handleClickConfirmPassword}   >
                                {values.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                            }}
                            name="confirm"
                            placeholder="Confirm New Password"
                            type={values.showConfirmPassword ? 'text' : 'password'}
            
                            value={onChangePassword.values.confirm}
                            onChange={onChangePassword.handleChange}
                            
                            error={onChangePassword.touched.confirm && Boolean(onChangePassword.errors.confirm)}
                            helperText={onChangePassword.touched.confirm && onChangePassword.errors.confirm}
                          />
                        

      
                      <div className={classes.formButtons}>
                        {loader ? (
                          <CircularProgress size={26} className={classes.loginLoader} />
                        ) : (
                        <Button  className={classes.submitButton}
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={onChangePassword.isSubmitting}
                        >
                          Save
                        </Button>
                        )}
                      </div>
    
                    </form>
                    </React.Fragment>
        
                </div>
        
            </div>



        </div>    








  <ToastContainer
  closeButton={
  <CloseButton className={classes.notificationCloseButton} />
  }
  closeOnClick={false}
  hideProgressBar={true}
  />


<Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>

    </Grid> 
  );
}

export default withRouter(Forget);
function CloseButton({ closeToast, className }) {
  return <CloseIcon className={className} onClick={closeToast} />;
} 