import React from "react";

import { withRouter } from "react-router-dom";

import useStyles from "../../themes/style.js";

import { Typography } from "@material-ui/core";

import PageTitle from "../../components/PageTitle/LoginPageTitle.js";
import LoginHeader from "../../components/Login/LoginHeader.js";
import LoginFooter from "../../components/Login/LoginFooter.js";

function CancellationRefundPolicyPage() {
  const classes = useStyles();

  return (
    <>
      <LoginHeader />

      <div className={classes.siteContainer}>
        <PageTitle title="Cancellation / Refund Policy" />

        <div className={classes.contentPage}>
          <Typography variant="h1">Cancellation / Refund Policy</Typography>

          <div className={classes.contentPageInner}>
            <p>All sales are final and no refund will be issued.</p>

            <Typography variant="h1">QUESTIONS</Typography>

            <p>
              If you have any questions concerning our return policy, please
              contact us at:info@kadigaa.com
            </p>
          </div>
        </div>
      </div>

      <LoginFooter />
    </>
  );
}

export default withRouter(CancellationRefundPolicyPage);
