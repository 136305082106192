import React from "react";

// styles
import useStyles from "./styles";
import { Link } from "react-router-dom";
import { Home, ArrowForwardIos } from '@material-ui/icons';

// components
import { Typography } from "@material-ui/core";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      {/* <Typography className={classes.typo} variant="h1" size="sm"> {props.title}   </Typography> */}

          <Link to="/app/dashboard">
            <Home style={{ color: 'white' }} />
          </Link>  

          <ArrowForwardIos  style={{ color: 'white', width: '0.6em', margin: '0px 4px' }} />

          <Typography className={classes.text} variant="h6"  style={{ color: 'white' }}>
            {props.title}
          </Typography>
          {props.button && props.button}
    </div>
  );
}
