import React from 'react';

import { withRouter } from "react-router-dom";

import useStyles from "../../themes/style.js";


import { Typography } from "@material-ui/core";

import PageTitle from "../../components/PageTitle/LoginPageTitle.js";
import LoginHeader from "../../components/Login/LoginHeader.js";
import LoginFooter from "../../components/Login/LoginFooter.js";
 
  
function AboutUsPage() {  

    const classes = useStyles();  


    return (
        <>
            <LoginHeader />

                <div className={classes.siteContainer}>

                    <PageTitle title="About Us" />

                    <div className={classes.contentPage}>

                        <Typography variant="h1">
                            About Us
                        </Typography>    

                        <div className={classes.contentPageInner}>
                            <p>
                                Our vision is to enable small to medium companies to grow business smarter. Kadigaa is a strategic solution that helps businesses 
                                meet their customers where they are - on the messaging platform with incredible market penetration - WhatsApp. Its big presence 
                                means many customers and prospects are accessible and ready to engage in real-time.
                            </p>    
                            <p>
                                We help companies have personalized conversations with customers at scale with easy-to-use customer engagement software that's 
                                built on WhatsApp Business API. For example, our customers often automate sending personalized notifications via WhatsApp from 
                                their systems through Kadigaa.
                            </p>    
                            <p>
                                SMEs also support their customers through seamless WhatsApp communications management using Kadigaa's Team Inbox, smart routing, 
                                automated responses, data tagging, and analytics features.
                            </p>    
                            <p>
                                Many of these interactions may be automated through no or low-code workflow builders and chatbots. Automation continues to be 
                                a huge driver for our ambition of providing an end-to-end solution for WhatsApp. Allowing companies to have more engaging 
                                conversations with their customers in a smart way is efficient, and means they get more time to do what they 
                                love - focusing on business growth.
                            </p>    
                        </div>


                    </div>


                </div>

            <LoginFooter />


        </>    
    );
}


export default withRouter(AboutUsPage);