import React, { useState, useEffect, useReducer, useRef } from "react";
import MUIDataTable from "mui-datatables";
import {
  Grid,
  Button,
  List,
  ListItem,
  Box,
  SwipeableDrawer,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TablePagination,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  DialogTitle,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import PageTitle from "../../components/PageTitle/PageTitle";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import { toast } from "react-toastify";
//import AddCircleIcon from "@material-ui/icons/AddCircle";
import ViewIcon from "@material-ui/icons/Visibility";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import useStyles from "../../themes/style.js";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Notification from "../../components/Notification/Notification";

import {
  campaignSchema,
  campaignwithioutSchema,
} from "../../components/Yup/Yup";
import {
  addCampaign,
  viewCampaign,
  viewAllCampaign,
  rerunCampaign,
  viewCampaignMessageStatus,
  removeGroupCampaignNumber,
  editScheduledCampaignTime,
} from "../../services/campaign.api";
import { groupList } from "../../services/group.api";
import {
  viewAuthentication,
  uploadFile,
  getUserInstance,
} from "../../services/instance.api";
import { FcGallery, FcVideoFile, FcDocument } from "react-icons/fc";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import DoneIcon from "@material-ui/icons/Done";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";

import SendIcon from "@material-ui/icons/Send";
import {
  viewApprovedTemplates,
  viewTemplate,
} from "../../services/template.api";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

import { Autocomplete } from "@material-ui/lab";
import { useFormik } from "formik";

import "react-toastify/dist/ReactToastify.css";

export default function CampaignGroup(props) {
  const classes = useStyles();
  var moment = require("moment-timezone");

  const mobileQuery = useMediaQuery("(max-width:600px)");
  const messageRef = useRef();
  const [imageLoad, setImageLoad] = useState(null);
  const [fileData] = React.useState({
    image: [],
    audio: [],
    video: [],
    document: [],
    imageUrl: "",
  });
  const [campaignData, setCampaignData] = useState([]);

  const [groupData, setGroupData] = useState([]);

  const [campaignView, setCampaignView] = useState({
    data: {},
    table: [],
  });

  var token = localStorage.getItem("token");

  const [page, setPage] = useState(0);
  const [currerntPage, setCurrerntPage] = useState(1);
  const [count, setCount] = useState(0);

  const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  const [dataList, setDataList] = useState("ADD");

  const [loader, setLoader] = useState(false);
  const [templateData, setTemplateData] = React.useState([]);
  const [instanceData, setInstanceData] = React.useState([]);

  const [instanceValue, setInstanceValue] = React.useState(null);
  const [Id, setId] = React.useState();

  const [templateHeader, setTemplateHeader] = React.useState();

  const user = JSON.parse(localStorage.getItem("user"));

  function handleNotificationCall(type, message) {
    return toast(
      <Notification
        {...{
          type: type,
          message: message,
          variant: "contained",
          color: type,
        }}
        className={classes.notificationComponent}
      />,
      {
        type: type,
        position: toast.POSITION.TOP_RIGHT,
        progressClassName: classes.progress,
        className: classes.notification,
      },
    );
  }

  const [resendOpen, setResendOpen] = React.useState(false);

  const handleResendOpen = () => {
    setResendOpen(true);
  };

  const handleResendClose = () => {
    setResendOpen(false);
  };

  const [instanceId, setInstanceId] = React.useState();

  const [removeOpen, setRemoveOpen] = React.useState(false);
  const [removeBulkOpen, setRemoveBulkOpen] = React.useState(false);

  const handleRemoveOpen = () => {
    setRemoveOpen(true);
  };

  const handleRemoveClose = () => {
    setRemoveOpen(false);
  };

  const handleBulkRemoveOpen = () => {
    setRemoveBulkOpen(true);
  };

  const handleBulkRemoveClose = () => {
    setRemoveBulkOpen(false);
  };

  var selectId = [];

  const handleRowSelectionChange = (allRowsSelected) => {
    const id = allRowsSelected.map((rowIndex) => ({
      campaign_id: campaignView?.table[rowIndex.dataIndex].campaign_id,
      group_id: campaignView?.table[rowIndex.dataIndex].group_id,
      contact_id: campaignView?.table[rowIndex.dataIndex].contact_id,
      message_id: campaignView?.table[rowIndex.dataIndex].message_id,
    }));

    selectId = id;
  };

  const handleClickDelete = (allRowsSelected) => {
    const id = {
      campaign_id: allRowsSelected.campaign_id,
      group_id: allRowsSelected.group_id,
      contact_id: allRowsSelected.contact_id,
      message_id: allRowsSelected.message_id,
    };

    selectId = id;
    setselectedId([selectId]);
  };

  const [selectedId, setselectedId] = React.useState(selectId);

  const HeaderElements = () => <>{count}</>;

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    setPage(newPage);
    setCurrerntPage(newPage + 1);

    viewCampaignMessageStatus({
      page: newPage + 1,
      id: campaignView?.data?.id,
      token: token,
    })
      .then((result) => {
        if (result.data.status === true) {
          setCampaignView({
            ...campaignView,
            table: result.data.data,
          });

          setCount(result.data.count);
        }
        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    viewAuthentication({ userId: user?.userId, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setInstanceId(JSON.parse(JSON.stringify(response?.data?.data)));

          viewApprovedTemplates({
            instance_id: response?.data?.data?.id,
            token: token,
          })
            .then((result) => {
              if (result.data.status === true) {
                setTemplateData(result.data.data);
              }

              setLoader(false);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoader(true);

    getUserInstance({ instance_id: instanceId?.id, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setInstanceData(response.data.data);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      viewAllCampaign({ token: token })
        .then((response) => {
          if (response.data.status === true) {
            setCampaignData(response.data.data);
            setCount(response.data.count);
          }

          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reducerValue]);

  useEffect(() => {
    setLoader(true);

    const fetchData = async () => {
      groupList({ token: token })
        .then((response) => {
          if (response.data.status === true) {
            setGroupData(response.data.data);
          }

          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCampaignAdd = useFormik({
    initialValues: {
      instance: "",
      campaign_name: "",
      group_id: "",
      template_id: "",
      vars: "",
      header: "",
      headerType: "",
      type: "",
      schedule: "",
    },
    validationSchema:
      templateHeader === "IMAGE" ||
      templateHeader === "VIDEO" ||
      templateHeader === "DOCUMENT"
        ? campaignSchema
        : campaignwithioutSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      addCampaign({
        instance_id: instanceData[onCampaignAdd.values.instance]?.id,
        campaign_name: values.campaign_name,
        group_id: groupData[onCampaignAdd.values.group_id]?.id,
        template_id: templateData[values.template_id]?.id,
        vars: values.vars,
        message: messageRef.current?.innerText,
        headerType: values.headerType,
        header: values.header,
        type: values.type,
        schedule:
          values.schedule !== undefined
            ? moment(values.schedule).tz("UTC").format("YYYY-MM-DD HH:mm")
            : "",
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
            resetForm();
            setMessageData({});
            onCampaignAdd.setValues({
              instance: null,
              template: null,
              group_id: "",
              campaign_name: "",
              template_id: "",
              mobile_number: "",
              template_name: "",
              language_code: "",
              header: "",
              parameters: "",
              schedule: "",
              headerType: "",
              type: "",
              // "footer":  "",
              // "button":"",
            });
            setState({ ...state, right: false });
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  const onCampaignRescheduled = useFormik({
    initialValues: {
      schedule: "",
    },
    onSubmit: (values, { setSubmitting, resetForm }) => {
      setLoader(true);

      editScheduledCampaignTime({
        id: campaignView.data?.id,
        schedule:
          values.schedule !== undefined
            ? moment(values.schedule).tz("UTC").format("YYYY-MM-DD HH:mm")
            : "",
        token: token,
      })
        .then((response) => {
          if (response.data.status === true) {
            handleNotificationCall("success", response.data.message);
            forceUpdate();
          } else {
            handleNotificationCall("error", response.data.message);
          }
          setSubmitting(false);
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  });

  function handleSendTemplateImage(event, name) {
    var FormData = require("form-data");
    var data = new FormData();
    if (name === "image" && event.target.files[0].size < 5000000) {
      //setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);

      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          onCampaignAdd.setValues({
            instance: onCampaignAdd.values.instance,
            group_id: onCampaignAdd.values.group_id,
            campaign_name: onCampaignAdd.values.campaign_name,
            template_id: onCampaignAdd.values.template_id,
            template: templateData.indexOf(onCampaignAdd.values.template),
            mobile_number: onCampaignAdd.values.mobile_number,
            template_name: onCampaignAdd.values.template_name,
            language_code: onCampaignAdd.values.language_code,
            header: response.data?.file_url,
            headerType: onCampaignAdd.values.headerType,
            parameters: onCampaignAdd.values.parameters,
            schedule: onCampaignAdd.values.schedule,
            type: onCampaignAdd.values.type,
            // "footer": onCampaignAdd.values.footer,
            // "button": onCampaignAdd.values.button,
          });
        }
        // setLoader(false);
        setImageLoad(false);
      });
    } else if (name === "audio" && event.target.files[0].size < 16000000) {
      //setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          onCampaignAdd.setValues({
            instance: onCampaignAdd.values.instance,
            group_id: onCampaignAdd.values.group_id,
            campaign_name: onCampaignAdd.values.campaign_name,
            template_id: onCampaignAdd.values.template_id,
            template: templateData.indexOf(onCampaignAdd.values.template),
            mobile_number: onCampaignAdd.values.mobile_number,
            template_name: onCampaignAdd.values.template_name,
            language_code: onCampaignAdd.values.language_code,
            header: response.data?.file_url,
            headerType: onCampaignAdd.values.headerType,
            parameters: onCampaignAdd.values.parameters,
            schedule: onCampaignAdd.values.schedule,
            type: onCampaignAdd.values.type,
            //  "footer": onCampaignAdd.values.footer,
            // "button": onCampaignAdd.values.button,
          });
        }
        // setLoader(false);
        setImageLoad(false);
      });
    } else if (name === "video" && event.target.files[0].size < 16000000) {
      // setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          onCampaignAdd.setValues({
            instance: onCampaignAdd.values.instance,
            group_id: onCampaignAdd.values.group_id,
            campaign_name: onCampaignAdd.values.campaign_name,
            template_id: onCampaignAdd.values.template_id,
            template: templateData.indexOf(onCampaignAdd.values.template),
            mobile_number: onCampaignAdd.values.mobile_number,
            template_name: onCampaignAdd.values.template_name,
            language_code: onCampaignAdd.values.language_code,
            header: response.data?.file_url,
            headerType: onCampaignAdd.values.headerType,
            parameters: onCampaignAdd.values.parameters,
            footer: onCampaignAdd.values.footer,
            type: onCampaignAdd.values.type,
            // "button": onCampaignAdd.values.button,
          });
        }
        // setLoader(false);
        setImageLoad(false);
      });
    } else if (name === "document" && event.target.files[0].size < 100000000) {
      // setLoader(true);
      setImageLoad(true);

      data.append("file", event.target.files[0]);
      uploadFile(data, { token: token }).then((response) => {
        if (response.data.status === true) {
          onCampaignAdd.setValues({
            instance: onCampaignAdd.values.instance,
            group_id: onCampaignAdd.values.group_id,
            campaign_name: onCampaignAdd.values.campaign_name,
            template_id: onCampaignAdd.values.template_id,
            template: templateData.indexOf(onCampaignAdd.values.template),
            mobile_number: onCampaignAdd.values.mobile_number,
            template_name: onCampaignAdd.values.template_name,
            language_code: onCampaignAdd.values.language_code,
            header: response.data?.file_url,
            headerType: onCampaignAdd.values.headerType,
            parameters: onCampaignAdd.values.parameters,
            schedule: onCampaignAdd.values.schedule,
            type: onCampaignAdd.values.type,
            // "footer": onCampaignAdd.values.footer,
            // "button": onCampaignAdd.values.button,
          });
        }
        //  setLoader(false);
        setImageLoad(false);
      });
    } else {
      handleNotificationCall("error", "Please upload a smaller file size");
    }
  }

  function removeGroupCampaignNo(data) {
    setLoader(true);
    removeGroupCampaignNumber(data, { token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleNotificationCall("success", response.data.message);

          handleBulkRemoveClose();
          handleRemoveClose();

          viewCampaignMessageStatus({
            page: 1,
            id: campaignView.data?.id,
            token: token,
          }).then((result) => {
            if (result) {
              setCampaignView({
                ...campaignView,
                data: campaignView.data,
                table: result.data.data,
              });

              setCount(result.data.count);
            }
          });
        } else {
          handleNotificationCall("error", response.data.message);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [messageData, setMessageData] = React.useState({
    headerField: false,
    bodyField: false,
    footerField: false,
    // "buttonField": false,
    message: "",
  });

  function messageText(e) {
    var loop = e.target.value.split(",");

    var message = messageData.message;

    for (let i = 0; i < loop.length; i++) {
      const regex = new RegExp(`<b id="${i + 1}">.*?</b>`, "g");
      const newText = '<b id="' + [i + 1] + '">' + loop[i] + "</b>";
      message = message.replace(regex, newText);
      setMessageData({
        ...messageData,
        message: message.replace(regex, newText),
      });
    }
  }

  function selectTemplate(value) {
    setLoader(true);
    if (value?.id) {
      viewTemplate({ id: value.id, instance_id: instanceId?.id, token: token })
        .then((response) => {
          if (response.data.status === true) {
            const regex = /\{\{.*?\}\}/g;

            var HEADER = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "HEADER",
            )[0]?.format;
            var BODY = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;
            // var FOOTER = JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text;

            setTemplateHeader(HEADER);
            onCampaignAdd.setValues({
              instance: onCampaignAdd.values.instance,
              template: templateData.indexOf(onCampaignAdd.values.template),
              group_id: onCampaignAdd.values.group_id,
              campaign_name: onCampaignAdd.values.campaign_name,
              template_id: templateData.indexOf(value),
              mobile_number: onCampaignAdd.values.mobile_number,
              template_name: response.data.data.name,
              language_code: response.data.data.language,
              header:
                response.data.data.fileUrl === ""
                  ? ""
                  : response.data.data.fileUrl,
              headerType: HEADER !== undefined ? HEADER : "",
              vars:
                BODY !== undefined ? (BODY.match(regex) || []).join(", ") : "",
              schedule: onCampaignAdd.values.schedule,
              type: onCampaignAdd.values.type,
              //  "footer":  FOOTER !==undefined? FOOTER: "",
              // "button": (JSON.parse(response.data.data.components).filter(type => type?.type === "BUTTONS")[0]?.text.match(regex) || []).join(', '),
            });

            var loop = (
              JSON.parse(response.data.data.components)
                .filter((type) => type?.type === "BODY")[0]
                ?.text.match(regex) || []
            )
              .join(",")
              .split(",");

            var message = JSON.parse(response.data.data.components).filter(
              (type) => type?.type === "BODY",
            )[0]?.text;

            for (let i = 0; i < loop.length; i++) {
              const match = `{{${i + 1}}}`;
              message = message.replace(
                match,
                `<b id="${i + 1}">{{${i + 1}}}</b>`,
              );

              setMessageData({
                ...messageData,
                headerField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "HEADER",
                  )[0]?.format !== undefined
                    ? false
                    : true,
                bodyField:
                  JSON.parse(response.data.data.components).filter(
                    (type) => type?.type === "BODY",
                  )[0]?.text !== undefined
                    ? false
                    : true,
                //  "footerField": JSON.parse(response.data.data.components).filter(type => type?.type === "FOOTER")[0]?.text !==undefined ? false : true,
                message: message,
              });
            }
          }
          setLoader(false);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setLoader(false);
      onCampaignAdd.setValues({
        instance: null,
        template: null,
        group_id: onCampaignAdd.values.group_id,
        campaign_name: onCampaignAdd.values.campaign_name,
        template_id: onCampaignAdd.values.template_id,
        mobile_number: onCampaignAdd.values.mobile_number,
        template_name: onCampaignAdd.values.template_name,
        language_code: onCampaignAdd.values.language_code,
        header: onCampaignAdd.values.header,
        headerType: onCampaignAdd.values.headerType,
        parameters: onCampaignAdd.values.parameters,
        schedule: onCampaignAdd.values.schedule,
        type: onCampaignAdd.values.type,
        //"footer": onCampaignAdd.values.footer,
        // "button": onCampaignAdd.values.button,
      });
    }
  }

  function handleShow(values, name) {
    setLoader(true);
    if (name === "EDIT") {
      setDataList("EDIT");
    } else {
      setDataList("VIEW");
    }

    setPage(0);
    setCurrerntPage(1);

    viewCampaign({ id: values, token: token })
      .then((response) => {
        if (response.data.status === true) {
          setCampaignView({
            ...campaignView,
            data: response.data.data,
            table: [],
          });

          onCampaignRescheduled.setValues({
            schedule: response.data.data.schedule,
          });

          viewCampaignMessageStatus({ page: 1, id: values, token: token })
            .then((result) => {
              if (result.data.status === true) {
                setCampaignView({
                  ...campaignView,
                  data: response.data.data,
                  table: result.data.data,
                });
                setCount(result.data.count);
              }
            })
            .catch(function (error) {
              console.log(error);
            });

          setState({ ...state, right: true });
          setLoader(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function resendCampaign(values) {
    setLoader(true);
    rerunCampaign({ campaign_id: values, token: token })
      .then((response) => {
        if (response.data.status === true) {
          handleResendClose();
          handleNotificationCall("success", response.data.message);
        } else {
          handleNotificationCall("error", response.data.message);
        }

        setLoader(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    setState({ ...state, [anchor]: open });
  };

  const SelectElements = () => (
    <Grid className={classes.gap10}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        startIcon={<DeleteForeverIcon />}
        onClick={() => {
          setselectedId(selectId);
          handleBulkRemoveOpen();
        }}
      >
        Remove Contact
      </Button>
    </Grid>
  );

  const list = (anchor) =>
    dataList === "ADD" ? (
      <Box sx={{ width: "100%" }} role="presentation">
        <List>
          <Card>
            <CardHeader>
              <Grid
                container
                direction="row"
                spacing={1}
                className={classes.drawerHeader}
              >
                <Typography variant="h5"> Add Campaign</Typography>

                <Grid className={classes.drawerClose}>
                  <CloseIcon
                    className={classes.closeBtn}
                    size="14px"
                    onClick={toggleDrawer(anchor, false)}
                  />
                </Grid>
              </Grid>
            </CardHeader>

            <form onSubmit={onCampaignAdd.handleSubmit}>
              <CardContent className={classes.drawerContent}>
                <Grid container direction="row" spacing={2}>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="instance">
                      {" "}
                      Select Instance{" "}
                    </InputLabel>
                    <FormControl className={classes.margin}>
                      <Autocomplete
                        underlineShow={false}
                        options={instanceData}
                        getOptionLabel={(option) =>
                          option.name !== undefined ? `${option.name} ` : ""
                        }
                        value={instanceValue}
                        onChange={(e, value) => {
                          setInstanceValue(value);

                          onCampaignAdd.setValues({
                            instance: instanceData.indexOf(value),
                            template: templateData.indexOf(
                              onCampaignAdd.values.template,
                            ),
                            group_id: onCampaignAdd.values.group_id,
                            campaign_name: onCampaignAdd.values.campaign_name,
                            template_id: onCampaignAdd.values.template_id,
                            mobile_number: onCampaignAdd.values.mobile_number,
                            template_name: onCampaignAdd.values.template_name,
                            language_code: onCampaignAdd.values.language_code,
                            header: onCampaignAdd.values.header,
                            headerType: onCampaignAdd.values.headerType,
                            vars: onCampaignAdd.values.vars,
                            schedule: onCampaignAdd.values.schedule,
                            type: onCampaignAdd.values.type,
                            // "footer": onCampaignAdd.values.footer,
                            // "button": onCampaignAdd.values.button,
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="instance"
                            fullWidth
                            //classes={{ root: classes.customTextField }}
                            placeholder="Select Instance"
                            InputLabelProps={{ shrink: true }}
                            type="text"
                            error={
                              onCampaignAdd.touched.instance &&
                              Boolean(onCampaignAdd.errors.instance)
                            }
                            helperText={
                              onCampaignAdd.touched.instance &&
                              onCampaignAdd.errors.instance
                            }
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="campaign_name">
                      {" "}
                      Campaign Name{" "}
                    </InputLabel>

                    <FormControl className={classes.margin}>
                      <TextField
                        //classes={{ root: classes.customTextField }}
                        InputProps={{ disableUnderline: true }}
                        //size="small"
                        placeholder="Enter campaign name"
                        type="text"
                        name="campaign_name"
                        value={onCampaignAdd.values.campaign_name}
                        onChange={onCampaignAdd.handleChange}
                        error={
                          onCampaignAdd.touched.campaign_name &&
                          Boolean(onCampaignAdd.errors.campaign_name)
                        }
                        helperText={
                          onCampaignAdd.touched.campaign_name &&
                          onCampaignAdd.errors.campaign_name
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="group_id">
                      {" "}
                      Group Name{" "}
                    </InputLabel>
                    <Autocomplete
                      underlineShow={false}
                      options={groupData}
                      getOptionLabel={(option) => option.name}
                      value={groupData[onCampaignAdd.values.group_id]}
                      onChange={(e, value) => {
                        onCampaignAdd.setValues({
                          instance: onCampaignAdd.values.instance,
                          template: templateData.indexOf(
                            onCampaignAdd.values.template,
                          ),
                          group_id: groupData.indexOf(value),
                          campaign_name: onCampaignAdd.values.campaign_name,
                          template_id: onCampaignAdd.values.template_id,
                          mobile_number: onCampaignAdd.values.mobile_number,
                          template_name: onCampaignAdd.values.template_name,
                          language_code: onCampaignAdd.values.language_code,
                          header: onCampaignAdd.values.header,
                          headerType: onCampaignAdd.values.headerType,
                          vars: onCampaignAdd.values.vars,
                          schedule: onCampaignAdd.values.schedule,
                          //"footer":  onCampaignAdd.values.footer,
                          type: onCampaignAdd.values.type,
                          // "button": onCampaignAdd.values.button,
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          //classes={{ root: classes.customTextField }}
                          {...params}
                          name="group_id"
                          InputLabelProps={{ shrink: true }}
                          type="text"
                          placeholder="Group Name"
                          error={
                            onCampaignAdd.touched.group_id &&
                            Boolean(onCampaignAdd.errors.group_id)
                          }
                          helperText={
                            onCampaignAdd.touched.group_id &&
                            onCampaignAdd.errors.group_id
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="template_id">
                      {" "}
                      Select Template{" "}
                    </InputLabel>
                    <Autocomplete
                      underlineShow={false}
                      options={templateData}
                      getOptionLabel={(option) => `${option.name} `}
                      value={templateData[onCampaignAdd.values.template_id]}
                      onChange={(e, value) => {
                        onCampaignAdd.setValues({
                          instance: onCampaignAdd.values.instance,
                          template: templateData.indexOf(
                            onCampaignAdd.values.template,
                          ),
                          group_id: onCampaignAdd.values.group_id,
                          campaign_name: onCampaignAdd.values.campaign_name,
                          template_id: templateData.indexOf(value),
                          mobile_number: onCampaignAdd.values.mobile_number,
                          template_name: onCampaignAdd.values.template_name,
                          language_code: onCampaignAdd.values.language_code,
                          header: onCampaignAdd.values.header,
                          headerType: onCampaignAdd.values.headerType,
                          vars: onCampaignAdd.values.vars,
                          type: onCampaignAdd.values.type,
                          schedule: onCampaignAdd.values.schedule,
                          // "footer":  onCampaignAdd.values.footer,
                          // "button": onCampaignAdd.values.button,
                        });
                        selectTemplate(value);
                      }}
                      renderInput={(params) => (
                        <TextField
                          //classes={{ root: classes.customTextField }}
                          {...params}
                          InputLabelProps={{ shrink: true }}
                          name="template_id"
                          placeholder="Select Template"
                          type="text"
                          error={
                            onCampaignAdd.touched.template_id &&
                            Boolean(onCampaignAdd.errors.template_id)
                          }
                          helperText={
                            onCampaignAdd.touched.template_id &&
                            onCampaignAdd.errors.template_id
                          }
                        />
                      )}
                    />
                  </Grid>


                      <TextField
                        name="template_name"
                        placeholder="Enter name"
                        fullWidth
                        //margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className={classes.hiddenTextField}
                        value={onCampaignAdd.values.template_name}
                        onChange={onCampaignAdd.handleChange}

                      />




                      <TextField
                        name="language_code"
                        placeholder="Enter language"
                        fullWidth
                        // margin="normal" 
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                          disableUnderline: true,
                        }}
                        className={classes.hiddenTextField}
                        value={onCampaignAdd.values.language_code}
                        onChange={onCampaignAdd.handleChange}

                      />

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="template">
                      {" "}
                      Header{" "}
                    </InputLabel>
                    <FormControl className={classes.margin}>
                      <TextField
                        name="header"
                        placeholder="Enter header"
                        fullWidth
                        //margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: true,
                        }}
                        //classes={{ root: classes.customTextField }}
                        value={onCampaignAdd.values.header}
                        onChange={onCampaignAdd.handleChange}
                        error={
                          onCampaignAdd.touched.header &&
                          Boolean(onCampaignAdd.errors.header)
                        }
                        helperText={
                          onCampaignAdd.touched.header &&
                          onCampaignAdd.errors.header
                        }
                      />
                    </FormControl>

                    {onCampaignAdd.values.headerType === "IMAGE" ? (
                      <>
                        <Grid item xs={12}>
                          <FormControl className={classes.margin}>
                            <div style={{ display: "flex", gap: 10 }}>
                              <TextField
                                id="image"
                                type="file"
                                style={{ display: "none" }}
                                name="file_path"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => {
                                  handleSendTemplateImage(e, "image");
                                }}
                                inputProps={{ accept: "image/jpeg, image/png" }}
                              />

                              <label htmlFor="image">
                                <Button
                                  variant="contained"
                                  color={"primary"}
                                  className={classes.button}
                                  startIcon={<FcGallery />}
                                  aria-label="upload image"
                                  component="span"
                                >
                                  Upload image
                                </Button>

                                {fileData?.image?.name ? (
                                  <Typography>
                                    {fileData?.image?.name}{" "}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </label>
                              {imageLoad === false ? (
                                <CheckCircleIcon
                                  style={{ fontSize: 30, color: "green" }}
                                />
                              ) : imageLoad === true ? (
                                <CircularProgress
                                  color="inherit"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </>
                    ) : onCampaignAdd.values.headerType === "VIDEO" ? (
                      <>
                        <Grid item xs={12}>
                          <FormControl className={classes.margin}>
                            <div style={{ display: "flex", gap: 10 }}>
                              <TextField
                                id="video"
                                type="file"
                                style={{ display: "none" }}
                                name="file_path"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => {
                                  handleSendTemplateImage(e, "video");
                                }}
                                inputProps={{ accept: "video/mp4, video/3gp" }}
                              />

                              <label htmlFor="image">
                                <Button
                                  variant="contained"
                                  color={"primary"}
                                  className={classes.button}
                                  startIcon={<FcVideoFile />}
                                  aria-label="upload video"
                                  component="span"
                                >
                                  Upload video
                                </Button>

                                {fileData?.video?.name ? (
                                  <Typography>
                                    {fileData?.video?.name}{" "}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </label>
                              {imageLoad === false ? (
                                <CheckCircleIcon
                                  style={{ fontSize: 30, color: "green" }}
                                />
                              ) : imageLoad === true ? (
                                <CircularProgress
                                  color="inherit"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </>
                    ) : onCampaignAdd.values.headerType === "DOCUMENT" ? (
                      <>
                        <Grid item xs={12}>
                          <FormControl className={classes.margin}>
                            <div style={{ display: "flex", gap: 10 }}>
                              <TextField
                                id="document"
                                type="file"
                                style={{ display: "none" }}
                                name="file_path"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(e) => {
                                  handleSendTemplateImage(e, "document");
                                }}
                                inputProps={{
                                  accept:
                                    "text/plain, application/pdf, application/vnd.ms-powerpoint, application/msword, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                                }}
                              />

                              <label htmlFor="image">
                                <Button
                                  variant="contained"
                                  color={"primary"}
                                  className={classes.button}
                                  startIcon={<FcDocument />}
                                  aria-label="upload document"
                                  component="span"
                                >
                                  Upload document
                                </Button>

                                {fileData?.document?.name ? (
                                  <Typography>
                                    {fileData?.document?.name}{" "}
                                  </Typography>
                                ) : (
                                  ""
                                )}
                              </label>
                              {imageLoad === false ? (
                                <CheckCircleIcon
                                  style={{ fontSize: 30, color: "green" }}
                                />
                              ) : imageLoad === true ? (
                                <CircularProgress
                                  color="inherit"
                                  style={{ color: "blue" }}
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink htmlFor="vars">
                      {" "}
                      Body{" "}
                    </InputLabel>
                    <FormControl className={classes.margin}>
                      <TextField
                        name="vars"
                        placeholder="Enter body"
                        fullWidth
                        // margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          readOnly: messageData.bodyField,
                        }}
                        //classes={{ root: classes.customTextField }}
                        value={onCampaignAdd.values.vars}
                        onChange={onCampaignAdd.handleChange}
                        onBlur={(e) => {
                          messageText(e);
                        }}
                        error={
                          onCampaignAdd.touched.vars &&
                          Boolean(onCampaignAdd.errors.vars)
                        }
                        helperText={
                          onCampaignAdd.touched.vars &&
                          onCampaignAdd.errors.vars
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <InputLabel shrink id="type">
                      {" "}
                      Type{" "}
                    </InputLabel>
                    <FormControl className={classes.formControl}>
                      <Select
                        labelId="type"
                        id="type"
                        value={onCampaignAdd.values.type}
                        onChange={(e, value) => {
                          onCampaignAdd.setValues({
                            instance: onCampaignAdd.values.instance,
                            template: templateData.indexOf(
                              onCampaignAdd.values.template,
                            ),
                            group_id: onCampaignAdd.values.group_id,
                            campaign_name: onCampaignAdd.values.campaign_name,
                            template_id: onCampaignAdd.values.template_id,
                            mobile_number: onCampaignAdd.values.mobile_number,
                            template_name: onCampaignAdd.values.template_name,
                            language_code: onCampaignAdd.values.language_code,
                            header: onCampaignAdd.values.header,
                            headerType: onCampaignAdd.values.headerType,
                            vars: onCampaignAdd.values.vars,
                            type: e.target.value,
                          });
                        }}
                        // classes={{ root: classes.customSelectField }}
                      >
                        <MenuItem value={"Immediate"}>Immediate</MenuItem>
                        <MenuItem value={"Scheduled"}>Schedule</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {onCampaignAdd.values.type === "Scheduled" ? (
                      <>
                        <InputLabel shrink htmlFor="schedule">
                          {" "}
                          Scheduled{" "}
                        </InputLabel>
                        <FormControl className={classes.margin}>
                          <TextField
                            // classes={{ root: classes.customTextField }}
                            name="schedule"
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                              min: moment()
                                .tz(moment.tz.guess())
                                .format("YYYY-MM-DDTHH:mm"),
                            }}
                            type="datetime-local"
                            value={onCampaignAdd.values.schedule}
                            onChange={onCampaignAdd.handleChange}
                            error={
                              onCampaignAdd.touched.schedule &&
                              Boolean(onCampaignAdd.errors.schedule)
                            }
                            helperText={
                              onCampaignAdd.touched.schedule &&
                              onCampaignAdd.errors.schedule
                            }
                            required
                          />
                        </FormControl>
                      </>
                    ) : (
                      ""
                    )}
                  </Grid>

                  {/* <Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
  label="Footer"
  placeholder="Enter footer"
  fullWidth
  margin="normal"
  name="footer"
 InputLabelProps={{
   shrink: true,
 }}
 InputProps={{
  readOnly: messageData.footerField,
}}

 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.footer}
  onChange={onCampaignAdd.handleChange}
   
/> 

</FormControl>
</Grid> */}

                  {/* <Grid item xs={12} sm={6} md={6} lg={6}> 

<FormControl className={classes.margin}>
<TextField 
  label="Button"
  placeholder="Enter button"
  fullWidth
  margin="normal"
  name="button"
 InputLabelProps={{
   shrink: true,
 }}
 InputProps={{
  readOnly: messageData.buttonField,
}}

 
 classes={{ root: classes.customTextField }}
 value={onCampaignAdd.values.button}
  onChange={onCampaignAdd.handleChange}
   
/> 

</FormControl>
</Grid> */}

                  <Grid item xs={12}>
                    <Grid className={classes.p24x8}>
                      <Typography variant="h6" className={classes.center}>
                        {" "}
                        {messageData.message ? "Preview" : ""}{" "}
                      </Typography>{" "}
                      <br />
                      <Typography variant="text">
                        {" "}
                        <div
                          ref={messageRef}
                          dangerouslySetInnerHTML={{
                            __html: messageData.message,
                          }}
                        />{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    type="submit"
                    disabled={onCampaignAdd.isSubmitting}
                  >
                    Add
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </form>
          </Card>
        </List>
      </Box>
    ) : (
      <>
        <Box sx={{ width: "100%" }} role="presentation">
          <List>
            <Card>
              <CardHeader>
                <Grid
                  container
                  direction="row"
                  spacing={1}
                  className={classes.drawerHeader}
                >
                  <Typography variant="h5"> View Campaign</Typography>

                  <Grid className={classes.drawerClose}>
                    <CloseIcon
                      className={classes.closeBtn}
                      size="14px"
                      onClick={toggleDrawer(anchor, false)}
                    />
                  </Grid>
                </Grid>
              </CardHeader>

              <CardContent className={classes.drawerContent}>
                <Grid spacing={2} className={classes.viewGroupInfo}>
                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6">
                        {" "}
                        Instance Name:{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.instance_name}{" "}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6">
                        {" "}
                        Campaign Name:{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.campaign_name}{" "}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6"> Group Name: </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.groupName}{" "}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6">
                        {" "}
                        Template Name:{" "}
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.template_name}{" "}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6"> Type: </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.type === "Scheduled"
                        ? "Schedule"
                        : campaignView.data?.type}{" "}
                    </Grid>
                  </Grid>

                  {campaignView.data?.type === "Scheduled" ? (
                    <>
                      <Grid container direction="row">
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          {" "}
                          <Typography variant="h6"> Schedule: </Typography>{" "}
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={6}
                          lg={6}
                          style={{ display: "flex" }}
                        >
                          {campaignView.data?.status !== "Scheduled" ? (
                            moment(
                              moment(campaignView.data?.scheduled).format(
                                "YYYY-MM-DD[T]HH:mm:ss[Z]",
                              ),
                            )
                              .tz(moment.tz.guess())
                              .format("DD-MM-YYYY HH:mm A")
                          ) : (
                            <>
                              <form
                                onSubmit={onCampaignRescheduled.handleSubmit}
                                className={classes.form}
                              >
                                <TextField
                                  classes={{ root: classes.customTextField }}
                                  name="schedule"
                                  InputLabelProps={{ shrink: true }}
                                  inputProps={{
                                    min: moment()
                                      .tz(moment.tz.guess())
                                      .format("YYYY-MM-DD"),
                                  }}
                                  type="datetime-local"
                                  defaultValue={moment(
                                    moment(campaignView.data?.scheduled).format(
                                      "YYYY-MM-DD[T]HH:mm:ss[Z]",
                                    ),
                                  )
                                    .tz(moment.tz.guess())
                                    .format("YYYY-MM-DDTHH:mm")}
                                  onChange={onCampaignRescheduled.handleChange}
                                  error={
                                    onCampaignRescheduled.touched.schedule &&
                                    Boolean(
                                      onCampaignRescheduled.errors.schedule,
                                    )
                                  }
                                  helperText={
                                    onCampaignRescheduled.touched.schedule &&
                                    onCampaignRescheduled.errors.schedule
                                  }
                                  required
                                />

                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                  type="submit"
                                  className={classes.marginTop}
                                  disabled={onCampaignRescheduled.isSubmitting}
                                >
                                  Save
                                </Button>
                              </form>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </>
                  ) : (
                    ""
                  )}

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6"> Status: </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {campaignView.data?.status}{" "}
                    </Grid>
                  </Grid>

                  <Grid container direction="row">
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      <Typography variant="h6"> Posted Date: </Typography>{" "}
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      {" "}
                      {moment(campaignView.data?.created)
                        .tz(moment.tz.guess())
                        .format("DD-MM-YYYY")}{" "}
                    </Grid>
                  </Grid>
                </Grid>

                <br />

                <Grid item xs={12}>
                  <MUIDataTable
                    options={{
                      onRowSelectionChange: (
                        currentRowsSelected,
                        allRowsSelected,
                      ) => handleRowSelectionChange(allRowsSelected),

                      pagination: false,
                      search: true,
                      searchOpen: true,
                      download: false,
                      print: false,
                      viewColumns: false,
                      filter: false,
                      filterType: "dropdown",
                      responsive:
                        mobileQuery === true ? "vertical" : "standard",
                      customToolbarSelect: () => <SelectElements />,
                      //customToolbar: () => <HeaderElements />,
                      textLabels: {
                        body: {
                          noMatch: "Oops! Matching record could not be found",
                        },
                      },
                    }}
                    columns={[
                      {
                        name: "S.No",
                      },

                      {
                        name: "Name",
                      },

                      {
                        name: "Mobile",
                      },

                      {
                        name: "Status",
                      },
                      {
                        name: "Email",
                      },
                    ]}
                    data={campaignView?.table?.map((item, index) => {
                      return [
                        currerntPage !== 0
                          ? 10 * currerntPage - 10 + index + 1
                          : index + 1,
                        item.name,
                        item.to_number,

                        item.status === "failed" ? (
                          <Grid container className={classes.space}>
                            <Grid
                              item
                              xs
                              className={classes.toolAlign}
                              style={{ alignItems: "center" }}
                            >
                              <Button
                                color="secondary"
                                className={classes.pointerEventsNone}
                                style={{ width: 100 }}
                              >
                                {" "}
                                <CloseIcon
                                  color="secondary"
                                  style={{ fontSize: 14, marginRight: 5 }}
                                />{" "}
                                Failed{" "}
                              </Button>
                              <Tooltip
                                title="Remove Contact"
                                placement="bottom"
                                aria-label="remove"
                              >
                                <DeleteForeverIcon
                                  className={classes.toolIconDelete}
                                  onClick={(e) => {
                                    handleClickDelete(item);
                                    handleRemoveOpen();
                                  }}
                                />
                              </Tooltip>{" "}
                            </Grid>
                          </Grid>
                        ) : item.status === "delivered" ? (
                          <Button
                            color="primary"
                            className={classes.pointerEventsNone}
                            style={{ width: 100 }}
                          >
                            {" "}
                            <DoneAllIcon
                              color="disabled"
                              className={classes.fontMargin}
                            />{" "}
                            Delivered
                          </Button>
                        ) : item.status === "read" ? (
                          <Button
                            style={{ color: "#33ab9f", width: 100 }}
                            className={classes.pointerEventsNone}
                          >
                            <DoneAllIcon
                              color="primary"
                              className={classes.fontMargin}
                            />{" "}
                            Read
                          </Button>
                        ) : (
                          <Button
                            style={{ color: "#35baf6", width: 100 }}
                            className={classes.pointerEventsNone}
                          >
                            <DoneIcon
                              color="disabled"
                              className={classes.fontMargin}
                            />{" "}
                            Sent
                          </Button>
                        ),
                        item.email,
                      ];
                    })}
                  />
                  <Grid container spacing={2} className={classes.pagination}>
                    <TablePagination
                      rowsPerPageOptions={[10]}
                      component="div"
                      count={count}
                      rowsPerPage={10}
                      page={page}
                      onChangePage={handleChangePage}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid
                  container
                  direction="row"
                  spacing={2}
                  className={classes.drawerFooter}
                >
                  <Button
                    variant="contained"
                    size="small"
                    color="secondary"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Close
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </List>
        </Box>
      </>
    );

  return (
    <>
      <PageTitle title="Group Messages" />

      <Grid container className={classes.pageHeader}>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.pageHeaderLeft}
        >
          <Typography variant="h3">Group Messages</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          className={classes.pageHeaderRight}
        >
          <List className={classes.pageHeaderList}>
            <ListItem>
              <Typography variant="h3">
                <HeaderElements />
              </Typography>
              <Typography variant="h6"> Group Messages</Typography>
            </ListItem>
            <ListItem>
              <Button
                variant="contained"
                size="small"
                color="primary"
                //startIcon={<AddCircleIcon />}
                //className={classes.margin}
                onClick={(e) => {
                  setDataList("ADD");
                  setInstanceValue(null);
                  setState({ ...state, right: true });
                  onCampaignAdd.setValues({
                    instance: null,
                    template: null,
                    group_id: "",
                    campaign_name: "",
                    template_id: "",
                    mobile_number: "",
                    template_name: "",
                    language_code: "",
                    header: "",
                    parameters: "",
                    type: "Immediate",
                    //"footer":  "",
                    headerType: "",
                    //  "button": "",
                  });
                }}
              >
                Add New Campaign
              </Button>
            </ListItem>
          </List>

          <SwipeableDrawer
            anchor="right"
            open={state["right"]}
            onClose={toggleDrawer("right", false)}
            onOpen={toggleDrawer("right", true)}
            classes={{ paper: classes.drawer }}
            disableSwipeToOpen={false}
          >
            {list("right")}
          </SwipeableDrawer>
        </Grid>
      </Grid>

      <div className={classes.contentInner}>
        <div className={classes.contentInnerInner}>
          <Grid container>
            <Grid item xs={12}>
              <MUIDataTable
                options={{
                  pagination: true,
                  sort: false,
                  selectableRows: "none",
                  search: true,
                  searchOpen: true,
                  viewColumns: false,
                  filter: false,
                  print: false,
                  download: false,
                  responsive: mobileQuery === true ? "vertical" : "standard",

                  textLabels: {
                    body: {
                      noMatch: "Oops! Matching record could not be found",
                    },
                  },
                }}
                columns={[
                  {
                    name: "S.No",
                  },

                  {
                    name: "Instance Name",
                  },
                  {
                    name: "Campaign Name",
                  },

                  {
                    name: "Group Name",
                  },

                  {
                    name: "Template Name",
                  },
                  {
                    name: "Status",
                  },
                  {
                    name: "Posted Date",
                  },
                  {
                    name: "Actions",
                  },
                ]}
                data={campaignData.map((item, index) => {
                  return [
                    index + 1,
                    item.instance_name,
                    item.campaign_name,
                    item.groupName,
                    item.template_name,
                    <>
                      {item.status} <br />{" "}
                      {item.status === "Scheduled"
                        ? moment(
                            moment(item.scheduled).format(
                              "YYYY-MM-DD[T]HH:mm:ss[Z]",
                            ),
                          )
                            .tz(moment.tz.guess())
                            .format("DD-MM-YYYY HH:mm A")
                        : ""}
                    </>,
                    moment(item.created)
                      .tz(moment.tz.guess())
                      .format("DD-MM-YYYY"),

                    <>
                      <Grid container className={classes.space}>
                        <Grid item xs className={classes.toolAlign}>
                          {item.status === "Campaigned" ? (
                            <Tooltip
                              title="Resend Campaign"
                              placement="bottom"
                              aria-label="view"
                            >
                              <SendIcon
                                className={`${classes.toolIcon} ${classes.sendIcon}`}
                                onClick={(e) => {
                                  handleResendOpen();
                                  setId(item.id);
                                }}
                              />
                            </Tooltip>
                          ) : (
                            ""
                          )}

                          <Tooltip
                            title="View Campaign"
                            placement="bottom"
                            aria-label="view"
                          >
                            <ViewIcon
                              className={`${classes.toolIcon} ${classes.viewIcon}`}
                              onClick={(e) => {
                                handleShow(item.id, "VIEW");
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </>,
                  ];
                })}
              />
            </Grid>
          </Grid>
        </div>
      </div>

      <Dialog
        onClose={handleResendClose}
        aria-labelledby="dialog-title"
        open={resendOpen}
        width="md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
          <Typography variant="h4">
            Are you sure you want to resend this campaign?
          </Typography>
          <span className={classes.dialogCloseBtn}>
            <CloseIcon size="14px" onClick={(e) => [handleResendClose()]} />
          </span>
        </DialogTitle>

        <DialogContent>
          <DialogContentText></DialogContentText>

          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                resendCampaign(Id);
              }}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleResendClose}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleRemoveClose}
        aria-labelledby="dialog-title"
        open={removeOpen}
        width="md"
        PaperProps={{ style: { width: "100%" } }}
      >
        {/* <DialogTitle >Are you sure you want to remove this contact? </DialogTitle>  */}
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Grid className={classes.center + " " + classes.dialogTitle}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={classes.dialogHeader}>
                  <Typography variant="subtitle1">
                    {" "}
                    Are you sure you want to remove this contact?{" "}
                  </Typography>

                  <CloseIcon
                    className={classes.closeBtn}
                    size="14px"
                    onClick={(e) => [handleRemoveClose()]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                removeGroupCampaignNo(selectedId);
              }}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleRemoveClose}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={handleBulkRemoveClose}
        aria-labelledby="dialog-title"
        open={removeBulkOpen}
        width="md"
        PaperProps={{ style: { width: "100%" } }}
      >
        <DialogContent style={{ padding: "0px" }}>
          <DialogContentText>
            <Grid className={classes.center + " " + classes.dialogTitle}>
              <Grid container direction="row" spacing={2}>
                <Grid item xs={12} className={classes.dialogHeader}>
                  <Typography variant="subtitle1">
                    {" "}
                    Are you sure you want to remove this Contact?{" "}
                  </Typography>

                  <CloseIcon
                    className={classes.closeBtn}
                    size="14px"
                    onClick={(e) => [handleBulkRemoveClose()]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={(e) => {
                removeGroupCampaignNo(selectedId);
              }}
            >
              {" "}
              Yes{" "}
            </Button>
            <Button
              variant="contained"
              size="small"
              color="secondary"
              onClick={handleBulkRemoveClose}
            >
              No
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Backdrop className={classes.backdrop} open={loader}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
