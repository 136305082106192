import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  loginInner: {
    display: "flex",
    height: "100%",
    minHeight: "100vh",
    width: "1100px",
    margin: "0 auto",
    padding: "80px 0",
    alignItems: "center",
  },
  loginLeft: {
    width: "60%",
  },
  loginRight: {
    width: "40%",
  },
  loginForm: {
    
  },

  loginHead: {
    textAlign: "center",
    "& h3": {
      padding: "25px 0"
    }
  },

  forgetButton:{
    textAlign: "right",
    padding: "10px 0 0",
    "& p": {
      cursor: "pointer",
      "&:hover" : {
        color: "#ed1a3b"
      }
    }
  },

  submitButton:{
    margin: "25px 0",
  },

  loginAccount:{
    display: "flex",
    justifyContent: "space-between",
  },


  regForm: {
    width: 450,
    textAlign: "-webkit-center",
    border: "1px solid #aeaa",
    padding: "20px",
    boxShadow: "0 3px 9px 0 rgba(0,0,0,0.3)",
    borderRadius: "35px", 
    
  },
  tab: {
    fontWeight: 400,
    fontSize: 18,
  },
  greeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(4),
  },
  subGreeting: {
    fontWeight: 500,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
  googleButton: {
    marginTop: theme.spacing(6),
    boxShadow: theme.customShadows.widget,
    backgroundColor: "white",
    width: "100%",
    textTransform: "none",
  },
  googleButtonCreating: {
    marginTop: 0,
  },
  googleIcon: {
    width: 30,
    marginRight: theme.spacing(2),
  },
  formDividerContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "center",
  },
  formDividerWord: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  formDivider: {
    flexGrow: 1,
    height: 1,
    backgroundColor: theme.palette.text.hint + "40",
  },
  errorMessage: {
    textAlign: "center",
  },
  textFieldUnderline: {
    "&:before": {
      borderBottomColor: theme.palette.primary.light,
    },
    "&:after": {
      borderBottomColor: theme.palette.primary.main,
    },
    "&:hover:before": {
      borderBottomColor: `${theme.palette.primary.light} !important`,
    },
  },
  textField: {
    borderBottomColor: theme.palette.background.light, 
  },
  
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  copyright: {
    marginTop: theme.spacing(4),
    whiteSpace: "nowrap",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      bottom: theme.spacing(2),
    },
  },

  fullImg:{
    height: "75%"
  },

  title:{
    fontSize: 24,
    fontWeight: 600,
    //color: "#064be2",
    color:"#2196f3",
    marginBottom: 5,
    
  },
  subtitle:{
    marginBottom: 42,
    fontSize: "17px",
    fontWeight: 300,
  },
  center:{
    alignSelf: "center"
  },
  
  backdrop: {
    zIndex: 1500,
    color: '#fff',
  },
  large:{
    width: "80px",
    height: "80px", 
  },
  verifyMessage: {
    textAlign: "center",
  },
  loginText:{
      // margin: "15px 0",
      color: "#6E6E6E",
      textTransform: "uppercase",
      fontSize: "2.1rem",
      fontWeight: 700,
      padding: " 20px 0px",
  },
 
}));
