import React from "react";
import { AppBar, Toolbar } from "@material-ui/core";

import { withRouter } from "react-router-dom";
import { Link, NavLink } from "react-router-dom";

import logo from "../../images/logo.png";

import useStyles from "../../themes/style.js";

function HomePage() {
  const classes = useStyles();

  return (
    <>
      <AppBar position="sticky" className={classes.siteHeader}>
        <div className={classes.siteContainer}>
          <Toolbar className={classes.toolBar}>
            <Link to="/home">
              <img src={logo} alt="kadigaa" />
            </Link>

            <div className={classes.otherLinks}>
              <NavLink to="/aboutus" activeClassName="is-active">
                About Us
              </NavLink>
              <NavLink to="/pricingdetails" activeClassName="is-active">
                Pricing
              </NavLink>
              <NavLink to="/contactus" activeClassName="is-active">
                Contact Us
              </NavLink>
              <NavLink to="/chatbot" activeClassName="is-active">
                ChatBot
              </NavLink>
            </div>

            <div className={classes.navlinks}>
              <Link
                to="/login"
                className={`${classes.link} ${classes.loginLink}`}
              >
                Login
              </Link>
              <Link
                to="/register"
                className={classes.link + " " + classes.registerLink}
              >
                Register
              </Link>
            </div>
          </Toolbar>
        </div>
      </AppBar>
    </>
  );
}

export default withRouter(HomePage);
